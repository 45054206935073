export default {
  window_title: '{APP_NAME}',
  'message.response.request_invalid': 'Your request is invalid!',
  'message.not_auth_to_trade':
    'You are not authorized to trade this pair. Please contact admin for further information.',
  btc_campaign_tit: 'BTC TRADING BONUS <br /> Campaign & Self <br /> Trading GO',
  btc_campaign_time: '24 March ~ 26 March',
  btc_campaign_btctxt: 'Total 10 BTC',
  invite_recommend_id: 'My recommendation ID',
  invite_recommend_scale: 'Commission ratio',
  invite_recommend_link: 'Referral link',
  invite_recommend_share: 'Share it',
  invite_recommend_person: 'Recommended friend',
  invite_recommend_money: 'Estimated commission earned',
  invite_recommend_record: 'Estimated commission earned',
  invite_recommend_email: 'Mailbox',
  invite_recommend_time: 'Time',
  invite_recommend_none: 'You have no data',
  invite_recommend_history: 'Recent rebates',
  invite_rule: 'Activity Details',
  'entry.msg.create_team.success': 'Created entry team successfully!',
  'entry.msg.cancel.success': 'Your request have been canceled!',
  'register.entry': 'Register Entry',
  'entry.number_of_users': 'Number of Users in Entry',
  'participate.entry': 'Do you want to participate Entry?',
  'participate.entry.note1':
    '- During the entry or contest time, users can withdraw money from margin balance to cancel from the entry.',
  'participate.entry.note2':
    '- During the entry time, the user losses resulting in lower than the minimum margin requirement will automatically cancel from the entry (if the margin is added, the user can enter again).',
  'participate.entry.note3':
    '- During the contest time, if the available balance is lower than the minimum margin requirement, it will not be canceled from the entry.',
  'participate.entry.note4':
    '- If the user holds a position before entering the contest time, it will not be canceled from the entry. When the position is completely closed (100%), the ranking of user will start being calculated.',
  'participate.entry.note5':
    '- At the end of the contest time, the user who does not make a transaction will be canceled from the entry.',
  'i18n.lang': 'en',
  'kyc.id_number_unique': 'The National ID has already been taken.',
  'system.title': 'Repairs in Progress',
  'system.des':
    'The server is temporarily unable to service your request due to maintenance downtime or capacity problems. We will be back soon.',
  'window.suffix_title': 'Exchange',
  'about.about_us': 'About Us',
  'about.advantage': 'Our Advantages',
  'about.coin': 'Multiple-Coin Support',
  'about.coin_reason': 'BTC, ETH, LTC, ...',
  'about.device': 'All Devices Covered',
  'about.device_reason': 'Support and FAQs available in multiple languages',
  'about.envi': 'Multiple-Language Support',
  'about.envi_reason': 'Web, Android, iOS, Mobile Web',
  'about.liqui': 'High Liquidity',
  'about.liqui_reason': 'Abundant resources and partners',
  'about.perform': 'High Performance',
  'about.perform_reason': 'Capable of processing 1,400,000 orders per second',
  'about.product': 'Proven Products',
  'about.product_reason':
    'The underlying {APP_NAME} platform has been deployed on 30+ exchanges already. It supports all devices and multiple languages, offering a seamless user experience.',
  'about.resources': 'Industry Resources',
  'about.resources_reason':
    'We have solid relationships with industry leaders, simply look at the list of investors and advisors who are willing to stand behind our platform.',
  'about.safe': 'Safety Stability',
  'about.safe_reason': 'Multi-tier & multi-cluster system architecture',
  'about.team_strong': 'Strong Team',
  'about.team_strong_reason':
    'Our team have experience in both wall-street and crypto finance. Our team also have a track record of successful startups under our belt.',
  'about.tech': 'Superior Technology',
  'about.tech_reason':
    'Our certified matching engine is capable of processing 1,400,000 orders per second, making {APP_NAME} one of the fastest exchange in the market today.',
  'about.tit':
    'Launched in 2018 {APP_NAME} is a  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, qttuis nostrud exercitation ullamco labor ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. ',
  'account.address_manager': 'Address Management',
  'account.create_api': 'Create API',
  'account.address_manager_content':
    'Address Management allows you to save and write memos for each of your withdrawal addresses. The optional Whitelist function helps protect your funds by only allowing withdrawals to whitelisted addresses.',
  'account.wallet_fee': 'Fee Wallet',
  'account.wallet_fee_description':
    "You can chosen which 4IRE's wallet to pay fee in every transaction.",
  'account.api_description':
    'Creating an API private key provides access to markets and real-time trading services on NVX Exchange via a third-party site or application.',
  'account.api_view_documentation': 'View API documentation',
  'account.api_create': 'Create API',
  'account.api.api': 'API',
  'account.api.title_page': 'Create API Key',
  'account.api.des_title_page':
    'You must apply the IP Access Restriction filter in order to enable withdrawals.',
  'account.api.key': 'API Key',
  'account.api.input_placeholder': 'Enter API key label',
  'account.api.secret_key': 'Secret Key',
  'account.api.scan_address': 'Scan address',
  'account.api.option': 'Option',
  'account.api.permissions.read': 'Read Info',
  'account.api.permissions.withdrawal': 'Enable Withdrawals',
  'account.api.permissions.trading': 'Enable Trading',
  'account.api.copy': 'Copied successfully!',
  'account.api.edit': 'Edit',
  'account.api.cancel': 'Cancel',
  'account.api.save': 'Save',
  'account.api.delete': 'Delete',
  'account.api.delete_api': 'Delete API',
  'account.api.delete_api_message_withname':
    'Are you sure to permanently delete the API key {name}',
  'account.api.delete_api_message_noname': 'Are you sure to permanently delete this API?',
  'account.api.create_btn': 'Create New Key',
  'account.api.title': 'List API Key',
  'account.api.delete_all': 'Delete all api',
  'account.api.save_success': 'Save successfully.',
  'account.api.save_fail': 'Can not save this API Key.',
  'account.api.delete_fail': 'Can not delete this API.',
  'account.api.delete_success': 'Delete successfully.',
  'account.api.create_success': 'Create new API successfully.',
  'account.api.create_fail': 'Can not create this API Key.',
  'account.api.create_new_at_first': 'To get started with the API, create a new key.',
  'account.api.ip_access_res': 'IP Access Restriction:',
  'account.api.unrestricted': 'Unrestricted (Less Secure) ',
  'account.api.not_recommended':
    'Any IP address can use this API key to access your account. This is not recommended.',
  'account.api.restrict_access': 'Restrict access to trusted IPs only (Recommended)',
  'account.api.current_ip': 'If entering multiple IPs, separate using commas.',
  'account.api.confirm': 'Confirm',
  'account.api.duplicate_value': 'The new label must differ from your previous label.',
  'account.bank': '{0}',
  'account.btn_general': 'General',
  'account.btn_verifying': 'Verifying',
  'account.btn_pending': 'Pending',
  'account.btn_rejected': 'Rejected',
  'account.btn_unverified': 'Unverified',
  'account.btn_uver_bank': 'Unverified',
  'account.btn_verified': 'Verified',
  'account.btn_verify_bank': 'Verify',
  'account.button_show': 'View more',
  'account.change': 'Change',
  'account.daily_higher_limit': 'Higher Limit',
  'account.daily_limit_withdraw': '24h Withdrawal Limit',
  'account.date': 'Date',
  'account.email_desc':
    'If you disable, you will lose notification for Login, Withdrawal, Deposit.',
  'account.device': 'Device',
  'account.device_management': 'Device Management',
  'account.devices_allowed': 'These devices are currently allowed to access your account.',
  'account.disable': 'Disable',
  'account.email_disable_confirm': 'Do you want to disable receive notification?',
  'account.discount': 'Using 4IRE to pay for fees ',
  'emails.confirmation_reset_password.subject': '【NVX】Reset Password',
  'account.discount_percent': '({percent} discount)',
  'account.enable': 'Enable',
  'account.error_bank': '* Used for withdrawal and security modifications',
  'account.google_auth': 'Google Authentication',
  'account.lost_google_authenticator': 'Lost Your Google Authenticator?',
  'account.google_auth_content': 'Used for withdrawals and security modifications',
  'account.identity.choose_file': 'Choose File',
  'account.identity.country': 'Country/Territory',
  'account.identity.errors.character_limit':
    'This field contains only {min} to {max} include numbers or characters.',
  'account.identity.errors.exist': 'Identity card already exists',
  'account.identity.errors.format_number': 'This field does not contain special characters.',
  'account.identity.errors.maxsize': 'This file should be maximum 4MB. Please choose another one',
  'account.identity.errors.required': 'This field should not be empty',
  'account.identity.errors.fullName.required': 'This field should not be empty',
  'account.identity.errors.passportID.required': 'This field should not be empty',
  'account.identity.errors.id_front.required': 'This field should not be empty',
  'account.identity.errors.id_back.required': 'This field should not be empty',
  'account.identity.errors.id_selfie.required': 'This field should not be empty',
  'account.identity.errors.video_selfie.required': 'This field should not be empty',
  'account.identity.errors.under_17': 'Must be at least 17 years old',
  'account.identity.example': 'Example',
  'account.identity.female': 'Female',
  'account.identity.fullname': 'Full name',
  'account.identity.header_identity': 'Personal',
  'account.identity.header_verification': 'Verification Documents',
  'account.identity.identityID': 'Valid Identity Card',
  'account.identity.identity_back': 'Identity Card Back Side Data Page',
  'account.identity.identity_back_wna': 'KITAS/KITAP Back Side Data Page',
  'account.identity.identity_cover': 'Identity Card Front Side Cover',
  'account.identity.identity_cover_wna': 'KITAS/KITAP Front Side Cover',
  'account.identity.identity_front': 'Identity Card Front Side',
  'account.identity.identity_image_back':
    'Please make sure that the photo is complete and clearly visible, in .PNG, .JPG, .JPEG, .HEIC or .HEIF format. National ID card must be in valid period.',
  'account.identity.identity_valid_card': 'Valid Identity Card',
  'account.identity.image_identity':
    'Please make sure that the photo is complete and clearly visible, in .PNG, .JPG, .JPEG, .HEIC or .HEIF format. National ID card must be in valid period.',
  'account.identity.image_passport':
    "Images must be submitted in .PNG, .JPG or .JPEG format. Please ensure that the subject of the photo is complete and clearly visible.If you do not have a passport, please upload a photo of the front of your Driver's License or National ID document.",
  'account.identity.image_passport_data':
    'The handwritten shall include name, application date, Web domain name, and personal responsibility. All behaviors on this website is operated by myself, and I am aware of relevant risks and willing to take all legal consequences.',
  'account.identity.male': 'Male',
  'account.identity.passport': 'NIK Number',
  'account.identity.passport_wna': 'KITAS/KITAP Number',
  'account.identity.dob': 'Date of Birth',
  'account.identity.dob_placeholder': 'Choose Date',
  'account.identity.province': 'Province',
  'account.identity.city': 'City/Regency',
  'account.identity.district': 'Sub-district',
  'account.identity.ward': 'Urban village',
  'account.identity.address': 'Contact Address',
  'account.identity.address_placeholder': 'Street name, House Number, Rt / Rw',
  'account.identity.postal_code': 'Postal code',
  'account.identity.npwp_number': 'NPWP',
  'account.identity.occupation': 'Occupation',
  'account.identity.average_annual_income': 'Average Annual Income',
  'account.identity.source_of_funds': 'Source Of Funds',
  'account.identity.account_creation_purpose': 'Account Creation Purpose',
  'account.identity.take_a_video_and_note': 'Take a Video And Note',
  'account.identity.not_support_video': 'Your browser does not support the video tag.',
  'account.identity.retake': 'Retake',
  'account.identity.retake_video': 'Re-take Video',
  'account.identity.liveness_checking': 'Liveness Checking',
  'account.identity.take_selfie_photo': 'Take Selfie Photo',
  'account.identity.retake_selfie_photo': 'Re-take Selfie Photo',
  'account.identity.save': 'Save',
  'account.identity.video_available': 'Video stream not available.',
  'account.identity.video_note':
    'Please submit a video with the following instruction;<ul><li>Turn your head towards the left</li><li>Turn your head towards the right</li><li>Look up</li><li>Look down</li></ul>',
  'account.identity.selfie': 'Selfie With Photo ID And Note',
  'account.identity.sex': 'Gender',
  'account.identity.submit': 'Submit',
  'account.identity.success': 'Created successfully!',
  'account.identity.update_success': 'Updated successfully!',
  'account.identity.update_digisign_success':
    'Your documents have been submitted to Digisign successfully!',
  'account.identity.sure_back':
    'Please make sure that the photo is complete and clearly visible, in .PNG, .JPG, .JPEG, .HEIC or .HEIF format. National ID card must be in valid period.',
  'account.identity.sure_front':
    "If you do not have a National ID, please upload a photo of the front of your Driver's License or National ID document.",
  'account.identity.text_condition1':
    'The uploaded images should clearly show the face and text information.',
  'account.identity.text_condition2': 'The uploaded video should clearly show the face.',
  'account.identity.text_date': "Note with today's date",
  'account.identity.text_face': 'Face clearly visible',
  'account.identity.text_image': 'Photo ID clearly visible',
  'account.identity.text_selfie':
    "Please provide a photo of you holding your Identity Card front side. In the same picture, make a reference to {APP_NAME} and today's date displayed. Make sure your face is clearly visible and that all National ID details are clearly readable.",
  'account.identity.text_write': 'Note with word "{APP_NAME}"',
  'account.identity.verification_other':
    'This verification option applies to users with ID from all countries and territories outside mainland Japan. You must use your real identity during this verification process. Your personal information will be securely protected. The following forms of government-issued ID can be used:',
  'account.identity.verification_other1': '<span>1.</span>National ID',
  'account.identity.verification_other2': "<span>2.</span>Driver's license",
  'account.identity.verification_other3': '<span>3.</span>Government-issued ID Card',
  'account.invalid_device':
    'It appears that you may be signing in from a device we are unfamiliar with or one that you have not used for a long period of time. For your security, a confirmation email has been sent to your email address.',
  'account.ip': 'IP',
  'account.ip_address': 'IP Address',
  'account.actions': 'Actions',
  'account.last_login': 'Last login',
  'account.last_login_time': 'Last login time',
  'account.line.title': 'Line',
  'account.line.desc':
    'Enable your account to receive notification for Login, Withdrawal, Deposit.',
  'account.location': 'Location',
  'account.login': 'Login Password',
  'account.lv': 'Lv',
  'account.modal.agreement_otp.view_secret_key': 'View secret key',
  'account.modal.agreement_otp.policy_content_text1': '1.',
  'account.modal.agreement_otp.policy_content_text2':
    'Email Subject: Request stop Google Authenticator',
  'account.modal.agreement_otp.policy_content_text3': '2. Email body',
  'account.modal.agreement_otp.policy_content_text4': '- Real name.',
  'account.modal.agreement_otp.policy_content_text5': '- Your mobile phone number.',
  'account.modal.agreement_otp.policy_content_text6': '- E-mail when you join {APP_NAME}.',
  'account.modal.agreement_otp.policy_content_text7': '3. Attachments',
  'account.modal.agreement_otp.policy_content_text8': '- Identity card front side.',
  'account.modal.agreement_otp.policy_content_text9': '- Identity card back side.',
  'account.modal.agreement_otp.policy_content_text10': '- Selfie with identity card and a note.',
  'account.modal.agreement_otp.policy_content_text11':
    'Please make sure that the photo is complete and clearly visible.',
  'account.modal.agreement_otp.policy_content_text12':
    'Please provide a photo of you holding your Identity Card front side.',
  'account.modal.agreement_otp.policy_content_text13':
    'In the same picture, make a reference to {APP_NAME} Exchange and today’s date displayed.',
  'account.modal.agreement_otp.policy_content_text14':
    'Make sure your face is clearly visible and that all National ID details are clearly readable.',
  'account.modal.agreement_otp.policy_content':
    "If you also lost your recovery code and can not stop using Google Authenticator, please send us your request email to <span class='primary-link'>{contact_email}</span>.",
  'account.modal.agreement_otp.title': 'Lost Your Recovery Code Too?',
  'account.modal.cancel_2fa.guide':
    '<p class="font-18">To stop using 2 factor authentication (OTP), you have to enter the 6 digits verification number from the Google Authenticator app.</p><p class="font-18">If you lost your phone or uninstalled the app, you can alternatively enter the 16 character recovery code in the input below to disable it.</p>',
  'account.modal.cancel_2fa.input_blank': 'You need to enter Google Authentication OTP code',
  'account.modal.cancel_2fa.otp': 'OTP',
  'account.modal.cancel_2fa.otp_code': 'Authorization number',
  'account.modal.cancel_2fa.otp_policy': 'Lost Your Recovery Code Too?',
  'account.modal.cancel_2fa.otp_restore': 'Recovery code',
  'account.modal.cancel_2fa.stop_use_otp': 'Stop Using OTP',
  'account.modal.cancel_2fa.success': 'Stop using OTP success!',
  'account.modal.cancel_2fa.title': 'Disable Google Authentication',
  'account.modal.change_phone.title': 'Change Phone Number',
  'account.modal.change_phone.success': 'Your phone number has successfully changed.',
  'account.modal.confirm': '<span class="font-18">Confirm</span>',
  'account.modal.confirm_trusted_device': 'Do you want to delete the selected trusted device?',
  'account.modal.date_time_pick.text1': 'Date Range:',
  'account.modal.date_time_pick.text2': 'Last 24 hours',
  'account.modal.date_time_pick.text3': 'Yesterday',
  'account.modal.date_time_pick.text4': 'Last 2 weeks',
  'account.modal.date_time_pick.text5': 'Month to date',
  'account.modal.date_time_pick.text6': 'Past month',
  'account.modal.date_time_pick.text7': 'Past 3 months',
  'account.modal.date_time_pick.text8': 'Custom (max range 3 months)',
  'account.modal.date_time_pick.text9': 'Export',
  'account.modal.date_time_pick.title': 'Export Commission History',
  'account.modal.lost_otp.guide':
    'To stop using Google Authenticator, you have to enter your recovery code.',
  'account.modal.lost_otp.otp': 'OTP',
  'account.modal.lost_otp.otp_code': 'Authorization number',
  'account.modal.lost_otp.otp_policy': 'Lost your recovery code too?',
  'account.modal.lost_otp.otp_restore': 'Recovery code',
  'account.modal.lost_otp.stop_use_otp': 'Stop Using OTP',
  'account.modal.lost_otp.title': 'Stop Using OTP',
  'account.ni_bank': 'NI Bank Account',
  'account.no_commission_history': 'You have no data',
  'account.no_referral_history': 'You have no data',
  'account.no_latest_commission': 'You have no data',
  'account.otp.authentication_failed': 'Authentication failed.',
  'account.otp.back': 'Back Step',
  'account.otp.backup_text1':
    'Resetting your Google Authentication requires opening a support ticket and takes at least 7 days to process.',
  'account.otp.backup_text2':
    'Please save this Key on paper. This Key will allow you to recover your Google Authentication in case of phone loss.',
  'account.otp.backup_title': 'Step 3. Save Backup Recovery Code',
  'account.otp.enable_des': 'Enable Google Authentication',
  'account.otp.enable_step1': '1. Download App',
  'account.otp.enable_step2': '2. Scan QR Code',
  'account.otp.enable_step3': '3. Backup Recovery Code',
  'account.otp.enable_step4': '4. Enable Google Authentication',
  'account.otp.enable_text1': 'I have installed the app',
  'account.otp.enable_text2': 'I have scanned the QR code',
  'account.otp.enable_text3': 'I have already written down the 16-Digit Key',
  'account.otp.enable_title': 'Step 4. Enable Google Authentication',
  'account.otp.install_text': 'Download and install the Google Authentication app.',
  'account.otp.sub-install_text': 'Get the app',
  'account.otp.install_title': 'Step 1. Download App',
  'account.otp.next': 'Next step',
  'account.otp.qr_text':
    'If you are unable to scan the QR code, please enter this code manually into the app.',
  'account.otp.qt_title': 'Step 2. Scan QR Code',
  'account.otp.success': 'Activate OTP successfully.',
  'account.otp.txt_pass': 'Login Password',
  'account.otp.should_enable_first': 'Please enable Google Authentication.',
  'account.phone_number_in_use':
    'This phone number has already associated with a {APP_NAME} account.',
  'account.recent_activity': 'Recent Activity',
  'account.referral_code': 'Your referral code:',
  'account.fee_tier_program': 'Fee Tier Program',
  'account.fee_tier_program_desc':
    'Get lower Trading Fee by Trading more to increase your Average Daily Volume',
  'account.fee_tier_program_disable1': 'The Fee Tier Program starts after: {time}',
  'account.fee_tier_program_disable2': 'The Fee Tier Program is not available now!',
  'account.referral_notification':
    'Get up to {percent}% commission when referring friends to {APP_NAME} Exchange. Share this link: ',
  'account.referral_program': 'Referral Program',
  'referral.next_program':
    'If the number of your direct referrals is higher than or equal to other users, you will receive MAX {rate}% commission as below:',
  'account.submit': 'Submit Verification Documents',
  'account.view_doc': 'View the verification document',
  'account.change_doc': 'Change the verification document',
  'account.telegram.title': 'Telegram',
  'account.telegram.desc':
    'Enable your account to receive notification for Login, Withdrawal, Deposit.',
  'account.change_verify_kyc': 'Change Verification Documents',
  'account.title_device': 'These devices are currently allowed to access your account',
  'account.tooltip1':
    'When the function is turned on, your account will only be able to withdraw to whitelisted withdrawal addresses.',
  'account.tooltip2':
    'When the function is turned off, your account is able to withdraw to any withdrawal address.',
  'account.turn_on_mode_whitelist':
    'After turning off this function, you will be able to withdraw to any withdrawal address. Are you sure you want to turn it off?',
  'account.turn_off_mode_whitelist':
    'After turning on this function, you will only be able to withdraw to whitelisted withdrawal address. Are you sure you want to turn it on?',
  'account.white_list': 'Whitelist',
  'account.white_list_off': 'Whitelist Off',
  'account.white_list_on': 'Whitelist On',
  'account.withdrawal_address': 'Withdrawal Address Management',
  'account.inactive': 'Your account is disabled.',
  'account.user': 'User',
  'account.verify_email': 'Verify Email',
  'account.google_authenticator': 'Google Authenticator',
  'account.kyc': 'KYC',
  'account.add_info': 'Additional Information',
  'account.remove': 'Remove',
  'address.add_address': 'Withdrawal Address Management',
  'address.add_success': 'Created successfully!',
  'address.add_whitelist': 'Add to Whitelist',
  'address.address': 'Address',
  'address.all': 'All',
  'address.cancel_success': 'Canceled successfully!',
  'address.coin': 'Coin',
  'address.coin_name': 'Coin Name',
  'address.confirm_remove_address': 'Do you want to delete it?',
  'address.date': 'Date',
  'address.delete': 'Delete',
  'address.deleted_success': 'Deleted successfully!',
  'address.errors.address_required': 'The address field is required.',
  'address.errors.blockchain_address': 'The withdrawal address is not valid.',
  'address.errors.coin_required': 'The coin field is required.',
  'address.errors.exist_withdraw_address': 'This address already exists!',
  'address.errors.label_required': 'The label field is required.',
  'address.errors.max_length': 'The {field} must not exceed {amount} characters.',
  'address.errors.min_length': 'The {field} must not less than {amount} characters.',
  'address.errors.tag_required': 'The destination tag field is required.',
  'address.label': 'Label',
  'address.label_hint': 'The label must not exceed 20 characters',
  'address.memo': 'Memo/Tag/...',
  'address.none': 'None',
  'address.not_whitelisted': 'Not Whitelisted',
  'address.only_dis': 'Only display whitelisted addresses',
  'address.remove_whitelist': 'Remove from Whitelist',
  'address.select_coin': 'Select coin',
  'address.submit': 'Submit',
  'address.tag': 'Tag',
  'address.tooltip1':
    'When the function is turned on, your account will only be able to withdraw to whitelisted withdrawal addresses.',
  'address.tooltip2':
    'When the function is turned off, your account is able to withdraw to any withdrawal address.',
  'address.turn_off_mode_whitelist':
    'After turning off this function, you will be able to withdraw to any withdrawal address. Are you sure you want to turn it off?',
  'address.turn_on_mode_whitelist':
    'After turning on this function, you will only be able to withdraw to whitelisted withdrawal address. Are you sure you want to turn it on?',
  'address.turn_on_whitelist': 'Please turn on withdrawal whitelist function first.',
  'address.update_white_list': 'Please turn on withdrawal whitelist function first.',
  'address.updated_success': 'Updated successfully!',
  'address.white_list_off': 'Whitelist Off',
  'address.white_list_on': 'Whitelist On',
  'address.whitelisted': 'Whitelisted',
  'address.withdrawal_address': 'Withdrawal Address Management',
  'address.table.empty_msg': 'You have no data',
  'admin.deposit_approved': 'Deposit success {amountUSD}\n{time}\n\n{APP_NAME}',
  'admin.deposit_rejected': 'Deposit failed {amountUSD}\n{time}\nInformation mismatch\n{APP_NAME}',
  'admin.new_deposit': 'New deposit {amountUSD}\n{time}\n\n{APP_NAME}',
  'admin.new_withdrawal': 'New withdrawal {amountUSD}\n{time}\n\n{APP_NAME}',
  'admin.withdraw_approved':
    'Withdrawal success {amountUSD} {bankName} bank {bankAccount}\n{time}\n\n{APP_NAME}',
  'admin.withdraw_status_pending': 'Pending',
  'admin.withdraw_status_success': 'Success',
  'auth.confirmation_email_subject': '【{APP_NAME}】Activate your account on {APP_NAME}',
  'auth.email_confirm_success': 'Your email has been confirmed.',
  'auth.email_confirmation_code_invalid': 'Activation code is invalid.',
  'auth.failed': 'These credentials do not match our records.',
  'auth.failed_login': 'The user credentials were incorrect.',
  'auth.forgot_password': 'Forgot password?',
  'auth.otp_code_invalid': 'Google Authentication code is invalid.',
  'auth.otp_code_required': 'The Google Authentication code field is required.',
  'auth.otp_not_used': 'Please wait for next verification code to generate.',
  'auth.send_mail_failed': 'Failed to send email, please try again.',
  'auth.throttle': 'Too many login attempts. Please try again in {seconds} seconds.',
  'bank_setting.bank_setting': 'Bank Setting',
  'bank_setting.bank_name': 'Bank name',
  'bank_setting.bank_branch': 'Bank branch',
  'bank_setting.account_name': 'Account name',
  'bank_setting.account_no': 'Account no',
  'bank_setting.edit': 'EDIT',
  'bank_setting.return': 'RETURN',
  'bank_setting.save': 'SAVE',
  'bank_setting.error.lose_bank_branch': 'The bank branch field is required.',
  'bank_setting.error.lose_bank_name': ' The bank name field is required.',
  'bank_setting.error.lose_account_name': 'The account name field is required.',
  'bank_setting.error.lose_account_no': 'The account no field is required.',
  'bank_setting.error.lose_amount': 'The amount field is required.',
  'bank_setting.update_success_msg': 'Save sucessfully!',
  'change_password_form.confirm':
    'For security purposes, no withdrawals are permitted for 24 hours after modification of security methods.',
  'change_password_form.confirm_new_password': 'Confirm Password',
  'change_password_form.confirm_new_password_change': 'confirm password',
  'change_password_form.error_confirm': 'The confirm password confirmation does not match.',
  'change_password_form.error_duplicated_password':
    'The new password must differ from your previous password.',
  'change_password_form.error_message': 'Your password is not changed!!!',
  'change_password_form.new_password': 'New Password',
  'change_password_form.old_password': 'Old Password',
  'change_password_form.new_password_change': 'new password',
  'change_password_form.old_password_change': 'old password',
  'change_password_form.require_confirm': 'The confirm password field is required.',
  'change_password_form.require_new': 'The new password field is required.',
  'change_password_form.require_old': 'The old password field is required.',
  'change_password_form.submit_button': 'Submit',
  change_password_cancel: 'Cancel',
  'change_password_form.submit_button_processing': 'Processing...',
  'change_password_form.successful_message': 'Your password has been changed successfully!',
  'change_password_form.title': 'Change Password',
  'change_password_form.save': 'Save',
  'client_download.desktop': 'Desktop',
  'client_download.destop_content':
    'Our desktop clients are tailor-made to provide a smooth and efficient trading experience.',
  'client_download.more_way': 'More ways to get {APP_NAME}',
  'client_download.provide_tool': "We're provided some tools you need for trading on the go",
  'client_download.sp_download': 'Smartphone apps',
  'client_download.track': 'Track and manage trades wherever you are!',
  'client_connection.fail': 'Connection fail!',
  'common.validate.email': 'email',
  'common.select': 'Select',
  'common.refresh': 'Refresh',
  'common.reset': 'Reset',
  'common.geetest.fail': 'Please input captcha.',
  'common.geetest.loading': 'Loading Captcha.',
  'common.action.cancel': 'Cancel',
  'common.action.invoice': 'Invoice',
  'common.action.pay_now': 'Pay Now',
  'common.action.limit_close_position': 'Limit Close Position',
  'common.action.market_close_position': 'Market Close Position',
  'common.action.no': 'No',
  'common.action.ok': 'OK',
  'common.action.yes': 'Yes',
  'common.announcements': 'Announcements',
  'common.begin.trading': 'Start Trading Now!',
  'common.confirm': 'Confirm',
  'common.create_account_amanpuri': 'Create an account with the Exchange',
  'common.datatable.data_empty': 'You have no data',
  'common.datatable.data_empty.investment': 'You have no data',
  'common.datatable.data_empty.request_history': 'You have no data',
  'common.datatable.data_empty.out': 'You have no data.',
  'common.datatable.data_empty.assigned_history': 'You have no data',
  'common.datatable.data_empty.current_interval': 'You have no data',
  'common.datatable.data_empty.commissions_history': 'You have no data',
  'common.datatable.data_empty.commissions_setting': 'You have no data',
  'common.datatable.no_data': 'You have no data',
  'common.datatable.empty_data': 'You have no data',
  'common.datatable.empty_data.stop': 'You have no data',
  'common.datatable.empty_data.fill': 'You have no data',
  'common.datatable.empty_data.positions': 'You have no data',
  'common.exchange.now': 'Exchange Now',
  'common.error.required': 'The {field} field is required.',
  'common.trading_markets': 'Trading Markets',
  'common.max': 'Max',
  'common.max_only': 'Max',
  'common.market_info': 'Market Information',
  'common.mobile_app': 'Mobile app',
  'common.mobile_app.desc': 'Smartphone apps. Track and manage trades wherever you are!',
  'common.mobile_app.title': 'Make and track your transactions on the go!',
  'common.no_select': 'None',
  'common.order_id': 'Order ID',
  'common.order_status.canceled': 'Cancelled',
  'common.order_status.new': 'New',
  'common.order_status.pending': 'Pending',
  'common.order_status.filled': 'Filled',
  'common.order_status.partial_filled': 'Partial Filled',
  'common.placeholders.email': 'Email',
  'common.validate.email': 'email',
  'common.placeholders.login_password': 'Login Password',
  'common.placeholders.otp': 'Google Authentication Code',
  'common.placeholders.password': 'Password',
  'common.placeholders.repeat_password': 'Repeat Password',
  'common.placeholders.referral': 'Referral ID (optional)',
  'common.placeholders.confirm_password': 'Confirm Password',
  'common.validate.confirm_password': 'confirm password',
  'common.processing': 'Processing...',
  'common.sales_point': 'Sales Point',
  'common.no_file_chosen': 'No file chosen',
  'common.sales_point.desc':
    "Transfer from one wallet to another within minutes*.Easy-to-use service with 130+ digital assets and live 24/7 support. It's that simple.",
  'common.sales_point.title': 'The fastest way to exchange crypto',
  'common.slogan_create_account': 'Bitcoin trading safe and secure NVX',
  'common.spot_exchange': 'Spot Exchange',
  'common.spot_exchange.desc':
    "Transfer from one wallet to another within minutes*.Easy-to-use service with 130+ digital assets and live 24/7 support. It's that simple.",
  'common.spot_exchange.title': 'is the most advanced digital assets exchange',
  'common.trade_type.buy': 'Buy',
  'common.trade_type.sell': 'Sell',
  'common.transaction_status.cancel': 'Canceled',
  'common.transaction_status.error': 'Error',
  'common.transaction_status.pending': 'Pending',
  'common.transaction_status.rejected': 'Rejected',
  'common.transaction_status.success': 'Success',
  'common.transaction_status.submitted': 'Submitted',
  'common.transaction_status.canceled': 'Canceled',
  'common.transaction_status.failed': 'Failed',
  'common.transaction_message.not_enough_liquidity':
    'Not Enough liquidity to withdraw. PLease contact customer support.',
  'common.transaction_message.otc_inactive':
    'OTC Inactive',

  'common_action.cancel': 'Cancel',
  'common_action.confirm': 'Confirm',
  'common_action.ok': 'Ok',
  'common.action.apply': 'Apply',
  'common.date.warning': 'The date range is invalid. The end date is smaller than the start date!',
  'common.new': 'New',
  'common.fetching_deposit_address': 'Fetching your deposit address, please wait.',
  'common.creating_deposit_address':
    'Creating your deposit address for the first time, please wait.',
  'contact.contact_us': 'Contact Us',
  'contact.got_question': 'Got a Question?',
  'contact.non_account_related':
    'For non-account-related communications, join our online communities:',
  'contact.please': 'Still can’t find what you’re looking for? Please',
  'contact.please_check':
    'Please first check the official {APP_NAME} FAQ to see if your question has already been answered.',
  'contact.submit_request': 'submit a request',
  'contact.view_suport': 'View Support Documentation',
  'currency.ada.fullname': 'Cardano',
  'currency.ada.shortname': 'ADA',
  'currency.amal.fullname': 'NVX',
  'currency.amal.shortname': '4IRE',
  'currency.mgc.fullname': 'Mango Coin',
  'currency.mgc.shortname': 'MGC',
  'currency.bch.fullname': 'Bitcoin Cash',
  'currency.bch.shortname': 'BCH',
  'currency.btc.fullname': 'Bitcoin',
  'currency.btc.shortname': 'BTC',
  'currency.eos.fullname': 'EOS',
  'currency.eos.shortname': 'EOS',
  'currency.eth.fullname': 'Ethereum',
  'currency.eth.shortname': 'ETH',
  'currency.ltc.fullname': 'LiteCoin',
  'currency.ltc.shortname': 'LTC',
  'currency.usdt.fullname': 'USDT',
  'currency.usdt.shortname': 'USDT',
  'currency.tomo.fullname': 'TomoChain',
  'currency.tomo.shortname': 'TOMO',
  'currency.tusd.fullname': 'TrueUSD',
  'currency.tusd.shortname': 'TUSD',
  'currency.usd.fullname': 'United States Dollar',
  'currency.usd.shortname': 'USD',
  'currency.xlm.fullname': 'Stellar',
  'currency.xlm.shortname': 'XLM',
  'currency.xrp.fullname': 'Ripple',
  'currency.xrp.shortname': 'XRP',
  'emails.account': 'Account',
  'amal.amal_main_balance': 'Main Balance',
  'amal.lock_by_user': 'Dividend Balance',
  'amal.amal_spot_balance': 'Spot Balance',
  'amal.amal_margin_balance': 'Margin Balance',
  'emails.account_info': 'bank: {bank}, account number: {accountNumber}, account holder: {name}',
  'emails.amount': 'amount:',
  'emails.cannot_click_link':
    'If you can not click on the link, kindly copy and paste the address into the address bar. Thank you',
  'emails.confirm_email':
    'Click on the link below to activate your account. By verifying your email, we strive to provide customers with a more convenient and safer digital assets exchange service.',
  'emails.confirm_kyc': 'Your KYC was',
  'emails.date': 'Time: ',
  'emails.deposit_alerts.text1': 'We received the loading order',
  'emails.deposit_alerts.text2': 'from you.',
  'emails.deposit_alerts.text3': 'This amount has been added to your balance at {APP_NAME}.',
  'emails.deposit_alerts.title': '【{APP_NAME}】Refill request has been made',
  'emails.deposit_approved_text1': '{email}, your deposit request has been approved.',
  'emails.deposit_approved_title': '【{APP_NAME}】 Deposit Completed',
  'emails.deposit_rejected_text1': '{email}, your deposit request has failed.',
  'emails.deposit_rejected_text2':
    'Please check the deposit rules, your account information, deposit code and apply again.',
  'emails.deposit_rejected_title': '【{APP_NAME}】 Deposit Failed',
  'emails.deposit_fiat_alerts.account_name': 'Account name',
  'emails.deposit_fiat_alerts.account_no': 'Account no',
  'emails.deposit_fiat_alerts.amount': 'Amount',
  'emails.deposit_fiat_alerts.bank_branch': 'Bank branch',
  'emails.deposit_fiat_alerts.bank_name': 'Bank name',
  'emails.deposit_fiat_alerts.code': 'Code',
  'emails.deposit_fiat_alerts.text1':
    "The system has just received a request to check the user's recharge transaction",
  'emails.deposit_fiat_alerts.text2': 'Transaction information',
  'emails.deposit_fiat_alerts.title': 'New recharge request',
  'emails.hello': 'Hello,',
  'emails.kyc_rejected': 'rejected',
  'emails.kyc_tilte': '【{APP_NAME}】Confirm KYC on',
  'emails.kyc_verified': 'verified',
  'emails.new_deposit_amount': 'Amount: ',
  'emails.new_deposit_text1': 'We have received your deposit request.',
  'emails.new_deposit_title': 'New Deposit Request',
  'emails.new_device.text1':
    'You recently attempted to sign into your {APP_NAME} account from a new device. As a security measure, we require additional confirmation before allowing access to your {APP_NAME} account',
  'emails.new_device.text2':
    'If this was legitimate activity, you can authorize your new device below',
  'emails.new_login.text1':
    'The system has detected that your account is logged in from an unused IP address',
  'emails.new_login.text2':
    'If this activity is not your own operation, please disable that device and contact us immediately',
  'emails.new_withdrawal_text1': 'We have received your withdrawal request.',
  'emails.new_withdrawal_title': 'New Withdrawal Request',
  'emails.no_reply': 'This is an automated message. Please do not reply.',
  'emails.on': 'on',
  'emails.say_hello': 'Hello {email}?',
  'emails.team': 'Team {APP_NAME}',
  'emails.team_address': '(CO) {APP_NAME} - street xxx',
  'emails.team_inc': '© {APP_NAME} Inc. All rights reserved.',
  'emails.team_inform': 'will inform you',
  'emails.team_service_center':
    'Service center / email. <a href="mailto: {email} " target="_blank"> {email} </a> / tel. {phone}',
  'emails.team_slogan': 'The best digital assets trading',
  'emails.thankyou': 'Thanks,',
  'emails.warning': 'If this activity is not your own operation, please contact us immediately.',
  'emails.warning_title': '{APP_NAME} Security Warning',
  'emails.welcome': 'Welcome to {APP_NAME}',
  'emails.withdraw_alerts.text1': 'Request withdrawal',
  'emails.withdraw_alerts.text2': 'from the {APP_NAME} account has been successfully implemented.',
  'emails.withdraw_alerts.text3': 'You can track its confirmation status here',
  'emails.withdraw_alerts.title': '【{APP_NAME}】Request for withdrawal successfully!',
  'emails.withdraw_errors_alerts.text1': 'Your transaction request withdrawal ',
  'emails.withdraw_errors_alerts.text2':
    ' has just failed. Do not worry, your case is being processed. Please wait for feedback from us.',
  'emails.withdraw_errors_alerts.title': '【{APP_NAME}】Withdrawal error',
  'emails.withdraw_fiat_alerts.account_name': 'Account name',
  'emails.withdraw_fiat_alerts.account_no': 'ccount no',
  'emails.withdraw_fiat_alerts.amount': 'Amount',
  'emails.withdraw_fiat_alerts.bank_branch': 'Bank branch',
  'emails.withdraw_fiat_alerts.bank_name': 'Bank name',
  'emails.withdraw_fiat_alerts.text1':
    "The system has just received a request for a user's withdrawal",
  'emails.withdraw_fiat_alerts.text2': 'Transaction information',
  'emails.withdraw_fiat_alerts.title': 'Request a new withdrawal',
  'emails.withdrawal_approved_text1': '{email}, your withdrawal request has been processed.',
  'emails.withdrawal_approved_title': '【{APP_NAME}】 Withdrawal Completed',
  'exchange.basic.chart.btn_add_chart': 'Add Chart',
  'exchange.basic.chart.buy_order': 'Buy orders',
  'exchange.basic.chart.candle': 'Candlesticks',
  'exchange.basic.chart.depth': 'Depth',
  'exchange.basic.chart.msg_close_chart': 'Do you want to close this chart ?',
  'exchange.basic.chart.price': 'Price',
  'exchange.basic.chart.sell_order': 'Sell orders',
  'exchange.basic.label_hour': 'Hour',
  'exchange.basic.label_minute': 'Minute',
  'exchange.basic.time.hour': '{number} hour',
  'exchange.basic.time.hours': '{number} hours',
  'exchange.basic.time.minute': '{number} minute',
  'exchange.basic.time.minutes': '{number} minutes',
  'exchange.basic.time.one_day': '1 day',
  'exchange.basic.time.one_month': '1 month',
  'exchange.basic.time.one_week': '1 week',
  'exchange.basic.time.day': '1D',
  'exchange.basic.time.week': '1W',
  'exchange.basic.time.month': '1M',
  'exchange.basic.time.three_month': '3M',
  'exchange.basic.trading_page.change_24h': '24h Change',
  'exchange.basic.trading_page.confirm': 'Confirm',
  'exchange.basic.trading_page.evaluation_amount': 'Evaluation',
  'exchange.basic.trading_page.high': 'High',
  'exchange.basic.trading_page.high_24h': '24h High',
  'exchange.basic.trading_page.holding_balance': 'Balance',
  'exchange.basic.trading_page.current_price': 'Current Price',
  'exchange.basic.trading_page.last_price': 'Last Price',
  'exchange.basic.trading_page.low': 'Low',
  'exchange.basic.trading_page.low_24h': '24h Low',
  'exchange.basic.trading_page.total_purchase_amount': 'Purchased',
  'exchange.basic.trading_page.volume': 'Volume',
  'exchange.basic.trading_page.volume_24h': '24h Volume',
  'favourite.market_price.coin': 'Name',
  'favourite.market_price.current_price': 'Last Price',
  'favourite.market_price.favorites': 'Favorites',
  'favourite.market_price.percent_day_before': '± %',
  'favourite.market_price.transaction_amount': 'Volume',
  'favourite.market_price.volume': 'Vol',
  'fee_guide.actual_purcharse': 'Actual Received Amount',
  'fee_guide.actual_sell': 'Actual Sold Amount',
  'fee_guide.commission_rate': 'Commission rate by transaction volume',
  'fee_guide.commission_tip1':
    '{APP_NAME} applies a different fee according to the transaction volume as above. Fee ratings are subject to change. ',
  'fee_guide.commission_tip2': 'Volume ranking is done once a day at {time} every day.',
  'fee_guide.commission_tip3': 'Maker / Taker fees are based on Fee Program Tier',
  'fee_guide.buyer_example': 'Buyer Example:',
  'fee_guide.seller_example': 'Seller Example:',
  'fee_guide.fee': 'Fee',
  'fee_guide.fomula_actual_pucharse':
    '= Filled Amount (1 BTC) - 1 BTC x Transaction Fee Rate (0.15%) = 0.9985 BTC',
  'fee_guide.for_example': 'For example:',
  'fee_guide.formula_actual_sell': '= Filled Amount (1 BTC)',
  'fee_guide.formula_fee': '= Filled Amount (1 BTC) x Transaction Fee Rate (0.15%) = 0.0015 BTC',
  'fee_guide.formula_sell_fee':
    '= Filled Amount (1 BTC) x Price (5,000,000 USD) x Transaction Fee Rate (0.15%) = 7,500 USD',
  'fee_guide.formula_subtotal': '= Quantity (1 BTC) x Price (5,000,000 USD) = 5,000,000 USD',
  'fee_guide.formula_transaction_value':
    '= Quantity (1 BTC) x Price (USD 5,000,000) - Fees (USD 7,500) = 4,992,500 USD',
  'fee_guide.last_30_days': 'Last 30 days',
  'fee_guide.less_than': 'Less than',
  'fee_guide.level_name': 'Level:',
  'fee_guide.level_title': 'Your current level is',
  'fee_guide.rating': 'Level',
  'fee_guide.subtotal': 'Total Payment USD (Transaction Value)',
  'fee_guide.text13': 'Maker / Taker fees are based on ',
  'fee_guide.text14':
    'In case of a buy order, the quantity obtained by subtracting the quantity corresponding to the commission rate from the filled quantity will be the actual quantity.',
  'fee_guide.text15':
    'In case of a sell order, the amount of the sale price minus the commission rate is the actual selling price.',
  'fee_guide.text16':
    'If a buy BTC order is filled, the commission rate is 0.15%, the price is USD 5,000,000 and the quantity is 1 BTC',
  'fee_guide.text20':
    'If a sell BTC order is completely filled with Buyer Example order with same commission',
  'fee_guide.text24': 'You can check the actual fee as well as filled amount in Trade History',
  'fee_guide.title': 'Fee Instruction',
  'fee_guide.transaction_fee': 'Transaction fee calculation method ',
  'fee_guide.transaction_value': 'Total Received USD (Transaction Value)',
  'funds.action.deposit': 'deposit',
  'funds.action.placeholder_input_coin': 'Please input keyword of the coin',
  'funds.action.withdraw': 'withdraw',
  'funds.balances.approx': 'Approx. 4IRE Value',
  'funds.balances.amount_is_positive': 'The minimum amount must be greater than 0.',
  'funds.balances.amount_over_0': 'The minimum amount must be greater than 0.',
  'funds.balances.amount_can_not_greater_than_available_balance': 'Exchange Balance is not enough.',
  'funds.balances.amount_can_not_greater_than_available_dividend_balance':
    'Available dividend balance is not enough.',
  'funds.balances.amount_can_not_greater_than_available_main_balance':
    'Wallet Balance is not enough.',
  'funds.balances.amount_can_not_greater_than_exchange_available_balance':
    'Main balance is not enough. ',
  'funds.balances.amount_can_not_greater_than_exchange_available_balance_without_orderbook':
    'Wallet Balance is not enough.',
  'funds.balances.assign': 'Assign',
  'funds.balances.avaliable_balance': 'Wallet Balance',
  'funds.balances.available_airdrop_balance': 'Available Dividend Balance',
  'funds.balances.balance': 'Balances',
  'funds.balances.convert_small_balance_success': 'Convert Balance Success',
  'funds.balances.curency': 'Currency',
  'funds.balances.exchange_available_balance': 'Tradable',
  'funds.balances.tradable_balance': 'Tradable Balance',
  'funds.balances.airdrop_balance': 'Dividend balance',
  'funds.balances.estimated_airdrop_value': 'Holding Ratio:',
  'funds.balances.unlock_date': 'Unlock date',
  'funds.balances.lock_balance': 'Lock balance',
  'funds.balances.no_data': 'You have no data',
  'funds.balances.btc_value': 'USDT Value',
  'funds.balances.convert_to_amal': 'CONVERT TO 4IRE',
  'funds.balances.convert_sm_balance': 'Convert Small Balances to 4IRE',
  'funds.balances.coin': 'Coin',
  'funds.balances.deposit': 'Deposit',
  'funds.balances.default_balance': '0.00000000',
  'funds.balances.estimated_value': 'Estimated Value',
  'funds.balances.from': 'From',
  'funds.balances.hide': 'Hide',
  'funds.balances.hide_small_assets': 'Hide 0 Balances',
  'funds.balances.interval': 'Interval',
  'funds.balances.in_order': 'In Order',
  'funds.balances.in_use': 'In Use',
  'funds.balances.locked_balance': 'Locked Balance',
  'funds.balances.locked_balance_desc':
    'Locked balance is the number of your assets that are locked due to your ongoing positions such as: pending withdrawals and exchange balance.',
  'funds.balances.in_transaction': 'In Transactions',
  'funds.balances.in_transaction_desc':
    "Funds might be temporarily unavailable under 'In Transactions' due to ongoing trades in your order book. They'll be available once the trades are settled.",
  'funds.balances.pending_withdrawal': 'Pending Withdrawals',
  'funds.balances.pending_withdrawal_desc':
    "Funds might be temporarily unavailable under 'Pending Withdrawals' while our system securely processes your request. They'll be available once it's complete.",
  'funds.balances.exchange_balance': 'Exchange Balance',
  'funds.balances.total_locked_balance': 'Total Locked Balance',
  'funds.balances.show_details': 'Show Details',
  'funds.balances.hide_details': 'Hide Details',
  'funds.balances.infomation': 'Information',
  'funds.balances.main_account': 'Main Account',
  'funds.balances.min_amount_is_invalid': 'The amount must be at least 0.00000001',
  'funds.balances.name': 'Name',
  'funds.balances.show_all': 'Show All',
  'funds.balances.small_balance': 'Small Balances',
  'funds.balances.small_balance_notice': 'Balances valued less than {amount} BTC',
  'funds.balances.hide_small_balance': 'Hide 0 Balances',
  'funds.balances.P_L': 'P/L',
  'funds.balances.revoke': 'Revoke',
  'funds.balances.available_amount': 'Available Amount',
  'funds.balances.my_investment': 'My Investment',
  'funds.balances.revokeable_amount': 'Revokeable Amount',
  'funds.balances.to': 'To',
  'funds.balances.tranfer': 'Transfer',
  'funds.balances.transfer': 'Transfer',
  'funds.balances.transfer_balance_fails': 'Transfer balance failed!',
  'funds.balances.airdrop_has_disable':
    'Dividend program was ended. You cannot transfer money to dividend wallet.',
  'funds.balances.transfer_balance_success': 'Transfer balance successfully!',
  'funds.balances.total_balance': 'Total Balance',
  'funds.balances.total_airdrop_balance': 'Total Dividend Balance',
  'funds.balances.withdrawal': 'Withdrawal',
  'funds.balances.suspend': 'Suspend',
  'funds.balances.trade': 'Trade',
  'funds.balances.you_have_selected_coin':
    "You have selected <span class='coin-count'>{coin}</span> coins",
  'funds.balances.withdrawal_limit_24': '24 Withdrawal Limit',
  'funds.convert_to_amal': 'Convert to 4IRE',
  'funds.deposit.available_balance': 'Wallet Balance',
  'funds.deposit.choice': 'Choice',
  'funds.deposit.coppy_address': 'Copy Address',
  'funds.deposit.copy_error': 'Copy error',
  'funds.deposit.copy_success': 'Copied successfully!',
  'funds.deposit.copy_tag': 'Copy Tag',
  'funds.deposit.deposit_address': '{coin} Deposit Address',
  'funds.deposit.deposit_tag': '{coin} Deposit Tag',
  'funds.deposit.history': 'History',
  'funds.deposit.important.text1':
    'This is ERC20 {name} deposit address, DO NOT deposit OMNI {name} tokens to this address.',
  'funds.deposit.important.text2':
    'Send only {name} to this deposit address. Sending any other currency to this address may result in the loss of your deposit.',
  'funds.deposit.in_order': 'In Order',
  'funds.deposit.not_arrive': 'Deposit Did Not Arrive',
  'funds.deposit.no_data': 'You have no data',
  'funds.depositUSD.no_data': 'You have no data',
  'funds.deposit.show_qr': 'Show QR Code',
  'funds.deposit.tips.text1': 'Please note',
  'funds.deposit.tips.text2':
    '{name} will be deposited immediately after {count} network confirmation.',
  'funds.deposit.tips.text4':
    '{name} will be deposited immediately after {count} network confirmations.',
  'funds.deposit.tips.text3':
    "After making a deposit, you can track its progress on the <a class='link-history' href='/funds/history?type=deposit' target='_blank' style=\"font-size: inherit;\">history</a> page.",
  'funds.deposit.tips.text5': 'Network: {coinNetwork}',
  'funds.deposit.title_page': 'Deposit',
  'funds.deposit.tooltip':
    'If your deposit does not arrive for a long time, it may be caused by congested blockchain or incomplete transaction.  <a class=\'link-primary\' href="#">More Reference</a>',
  'funds.deposit.total_balance': 'Total Balance',
  'funds.deposit.view_all': 'View All',
  'funds.deposit.what': 'What’s {name}?',
  'funds.deposit_fiat.cancel_deposit_usd': 'Cancel',
  'funds.deposit_fiat.deposit_amount': 'Deposit Amount',
  'funds.deposit_fiat.deposit_note':
    'Please make a transfer of money according to the information below. We will verify and transfer this amount to your account. Thank you!',
  'funds.deposit_fiat.errors.min_amount': 'The minimum deposit amount is {min_amount}',
  'funds.deposit_fiat.errors.max_amount': 'The amount must be less than or equal to {max_amount}.',
  'funds.deposit_fiat.errors.required': 'The deposit amount field is required.',
  'funds.deposit_fiat.guide.text1': '1. Please send the same amount as the deposit amount.',
  'funds.deposit_fiat.guide.text2':
    'If the deposit amount is different from the actual deposit amount, the deposit will not be processed.',
  'funds.deposit_fiat.guide.text3': "2. Be sure to enter 'Deposit code'.",
  'funds.deposit_fiat.guide.text4':
    'When depositing money, please enter the deposit code as the transfer content. Please make sure you only use the deposit code as transfer content and nothing else. The deposit process can be delayed when you do not enter the deposit code.',
  'funds.deposit_fiat.guide.text7': '2. Deposits will be processed within a maximum of 10 minutes.',
  'funds.deposit_fiat.guide.text8':
    'Under normal circumstances, the average time takes about 3 to 5 minutes to process the deposit.',
  'funds.deposit_fiat.guide.text9': 'We will notify you once the deposit has been processed.',
  'funds.deposit_fiat.guide.text10':
    '3. Please contact our customer support in case of late payment.',
  'funds.deposit_fiat.guide.text11':
    'If the required deposit amount and the transfered amount are different, or you forget to enter the deposit code, please submit a request in our support center.',
  'funds.deposit_fiat.guide.text12':
    '* The withdrawal will be limited during the check-in time of the banking system.',
  'funds.deposit_fiat.notes': 'Notes',
  'funds.deposit_fiat.other_deposit': 'Make another transaction',
  'funds.deposit_fiat.pending_transaction.account_name': 'Account name',
  'funds.deposit_fiat.pending_transaction.account_no': 'Account no',
  'funds.deposit_fiat.pending_transaction.amount': 'Amount',
  'funds.deposit_fiat.pending_transaction.bank_branch': 'Bank branch',
  'funds.deposit_fiat.pending_transaction.bank_name': 'Bank name',
  'funds.deposit_fiat.pending_transaction.code': 'Code',
  'funds.deposit_fiat.submit_deposit_usd': 'Submit',
  'funds.deposit_fiat.success_message': 'Created deposit request successfully!',
  'funds.deposit_fiat.fail_message': 'Transaction failed!',
  'funds.deposit_fiat.card_expired_message': 'The credit card has expired',
  'funds.deposit_fiat.card_not_support_message':
    'The credit card is not supported for online payment',
  'funds.deposit_fiat.something_wrong': 'Something went wrong!',
  'funds.deposit_fiat.transaction_history_table.account': 'Account',
  'funds.deposit_fiat.transaction_history_table.amount': 'Amount',
  'funds.deposit_fiat.transaction_history_table.received_amount': 'Received amount',
  'funds.deposit_fiat.transaction_history_table.bank': 'Bank',
  'funds.deposit_fiat.transaction_history_table.code': 'Code',
  'funds.deposit_fiat.transaction_history_table.status': 'Status',
  'funds.deposit_fiat.transaction_history_table.time': 'Time',
  'funds.deposit_fiat.transaction_history_table.text1': 'Are you sure to cancel this deposit?',
  'funds.deposit_fiat.copy_success_message': 'Copied VA number',
  'funds.deposit_fiat.maximum_deposit': 'The maximum deposit amount is {amount}',
  'funds.deposit_fiat.minimum_deposit': 'The minimum deposit amount is {amount}',
  'funds.deposit_fiat.maximum_deposit_with_credit_card':
    'The maximum deposit amount of credit card payment method is 999,999,999. Please use other methods',
  'funds.history.address': 'Address',
  'funds.history.amount': 'Amount',
  'funds.history.tax': 'Tax',
  'funds.history.fee': 'Fee',
  'funds.history.recevied_amount': 'Received Amount',
  'funds.history.coin': 'Coin',
  'funds.history.date': 'Date',
  'funds.history.time': 'Time',
  'funds.history.deposit': 'Deposit',
  'funds.history.export_complete_deposit_history': 'Export Complete Deposit History',
  'funds.history.export_complete_withdraw_history': 'Export Complete Withdrawal History',
  'funds.history.from': 'From',
  'funds.history.history': 'History',
  'funds.history.infomation': 'Information',
  'funds.history.status': 'Status',
  'funds.history.tag': 'Tag',
  'funds.history.to': 'To',
  'funds.history.transaction_history': 'Transaction History',
  'funds.history.txid': 'TxID',
  'funds.history.txhash': 'TxHash',
  'funds.history.withdrawal': 'Withdrawal',
  'funds.history.unique_code': 'Unique Code',
  'funds.history.number_of_confirmations': 'Number Of Confirmations',
  'funds.history.confirmed': '(Confirmed)',
  'funds.select_coin': 'Select coin/token to {action}',
  'funds.inputted_amount': 'Inputted amount',
  'funds.fee': 'Fee',
  'funds.history.to_address': 'To Address',
  'funds.history.from_address': 'From Address',
  'funds.you_will_get': 'You will get',
  'funds.select_coin': 'Select coin/token to {action}',
  'funds.withdraw_fiat.account_info': 'Account Information',
  'funds.withdraw_fiat.account_name': 'Account name',
  'funds.withdraw_fiat.account_no': 'Account no',
  'funds.withdraw_fiat.amount': 'amount IDR',
  'funds.withdraw_fiat.available': 'Available',
  'funds.withdraw_fiat.bank_branch': 'Bank branch',
  'funds.withdraw_fiat.bank_info': 'Bank Information',
  'funds.withdraw_fiat.bank_name': 'Bank name',
  'funds.withdrawals.select_coin': 'Select coin',
  'funds.withdrawals.add_new_address': 'Please input your new withdrawal address below',
  'funds.withdrawals.add_whitelist_address':
    'You have no whitelist address. Please manage your addresses on {APP_NAME} PC website.',
  'funds.withdrawals.amount': 'amount',
  'funds.withdrawals.available_balance': 'Wallet Balance',
  'funds.withdrawals.available_balance_to_withdraw': 'Available Balance to Withdraw',
  'funds.withdrawals.bank_accounts': 'Bank Accounts',
  'funds.withdrawals.choose_bank_placeholder': 'Choose bank account',
  'funds.withdrawals.minimum_withdrawal': '- Minimum Withdrawal: ',
  'funds.withdrawals.choice': 'Choice',
  'funds.withdrawals.no_data': 'You have no data',
  'funds.withdrawals.no_fiat_data': 'You have no data',
  'funds.withdrawals.confirm_otp': 'Confirm OTP',
  'funds.withdrawals.confirm_withdraw': 'Confirm withdraw',
  'funds.withdrawals.did_not_receive_the_mail': 'Did Not Receive The Mail',
  'funds.withdrawals.empty_whitelist.address_management': 'Address Management',
  'funds.withdrawals.empty_whitelist.text': 'You have no whitelist address.',
  'funds.withdrawals.error_message.address_not_exist_in_whitelist':
    'This address is not in the whitelist addresses.',
  'funds.withdrawals.error_message.amount_withdraw_is_positive':
    'The withdrawal amount must be a negative number.',
  'funds.withdrawals.error_message.minimum_withdraw': 'Withdraw failed. Please try again!',
  'funds.withdrawals.error_message.not_enough_balance':
    'The money in the account is not enough to make the transaction.',
  'funds.withdrawals.error_message.over_daily_limit': 'You have withdrawn the limit of the day.',
  Over_daily_limit: 'You have withdrawn the limit of the day.',
  'funds.withdrawals.error_message.over_limit': 'You have withdrawn the limit.',
  'funds.withdrawals.error_message.over_one_time_limit': 'You have overdrawn once',
  'funds.withdrawals.error_message.withdraw_is_blocking': 'Withdrawal is blocking',
  'funds.withdrawals.errors.account_no_length': 'The account no is not valid.',
  'funds.withdrawals.errors.blockchain_address': 'The withdrawal address is not valid.',
  'funds.withdrawals.errors.blockchain_address_exists': 'The address already exists.',
  'funds.withdrawals.errors.blockchain_address_sub_address_exists':
    'The withdrawal address or tag/memo/... has already been existed.',
  'funds.withdrawals.errors.exist_withdraw_address':
    'This address (and memo/tag/...) already exists!',
  'funds.withdrawals.errors.max_balance': 'Your account balance is not enough!',
  'funds.withdrawals.errors.otp': 'OTP not verified. Please enable Google Authentication!',
  'funds.withdrawals.errors.kyc': 'Please complete the KYC process to enable this function',
  'funds.withdrawals.errors.max_once_amount':
    'The amount you withdraw is more than [limit/daily limit]',
  'funds.withdrawals.errors.min_amount': 'The withdrawal amount must be greater than {min_amount}.',
  'funds.withdrawals.errors.not_allowed_withdraw':
    "Your security level doesn't be allowed to withdraw.",
  'funds.withdrawals.errors.otp_length_digits':
    'The Google Authentication Code length must be 6 digits.',
  'funds.withdrawals.errors.otp_length_6_digits':
    'The Google Authentication Code must be 6 digits.',
  'funds.withdrawals.errors.otp_required': 'The Google Authentication Code field is required.',
  'funds.withdrawals.errors.required': 'This field is required.',
  'funds.withdrawals.history': 'History',
  'funds.withdrawals.important.text1': 'Important',
  'funds.withdrawals.important.text2': 'Minimum Withdrawal',
  'funds.withdrawals.important.text3':
    'Do not withdraw directly to a crowfund or ICO. We will not credit your account with tokens from that sale.',
  'funds.withdrawals.in_order': 'In Use',
  'funds.withdrawals.input_address_error': 'Invalid address',
  'funds.withdrawals.loading': 'Processing...',
  'funds.withdrawals.submit': 'Submit',
  'funds.withdrawals.success_message': 'Created transaction successfully!',
  'funds.withdrawals.resend-sms-success_message':
    'OTP has been re-sent. Please check your mobile phone',
  'funds.withdrawals.confirm_withdrawal': 'You have confirmed the withdrawal transaction.',
  'funds.withdrawals.tips.text1': 'Please note',
  'funds.withdrawals.tips.text2':
    'Once you have submitted your withdrawal request, we will send a confirmation email. Please then click on the confirmation link in your email.',
  'funds.withdrawals.tips.text3':
    'After making a withdrawal, you can track its progress on the <a class="link-history" href=\'/funds/history?type=withdraw\' target=\'_blank\' style="font-size: inherit;">history</a> page.',
  'funds.withdrawals.title_page': 'Withdrawals',
  'funds.withdrawals.unique_code': 'Unique Code',
  'funds.withdrawals.tooltip':
    'If you cannot find the email, please check your spam or other folders.',
  'funds.withdrawals.total_balance': 'Total Balance',
  'funds.withdrawals.transaction_fee': 'Transaction Fee',
  'funds.withdrawals.use_a_new_address': 'Use a new address',
  'funds.withdrawals.view_all': 'View All',
  'funds.withdrawals.what': 'What’s {name}?',
  'funds.withdrawals.withdrawal_address': 'Withdrawal Address',
  'funds.withdrawals.withdrawal_limit_24': '24h withdrawal limit',
  'funds.withdrawals.withdrawal_title_address': 'Address',
  'funds.withdrawals.withdrawal_placeholder_address': 'Input your address',
  'funds.withdrawals.withdrawal_placeholder_available': 'Available',
  'funds.withdrawals.withdrawal_placeholder_loading': 'Loading...',
  'funds.withdrawals.withdrawal_please_wait': 'Please Wait...',
  'funds.withdrawals.withdrawal_title_label': 'Label',
  'funds.withdrawals.withdrawal_placeholder_label': 'Input your label',
  'funds.withdrawals.xrp_tag': 'Withdrawal Tag',
  'funds.withdrawals.no_memo': 'NoMemo',
  'funds.withdrawals.you_will_get': 'You Will Get',
  'funds.withdrawals.otp.notify.title':
    'We have sent an OTP to {phone_no}. It will be expired in 1 minute. Please kindly input it to complete your {token} withdrawal.',
  'funds.withdrawals.otp.error': 'This field can not be empty',
  'funds.withdrawals.otp.verify.title': 'OTP Verification',
  'funds.withdrawals.otp.resend.title': 'Resend OTP',
  'funds.withdrawals.otp.resend.titleWa': 'Resend WhatsApp message',
  'funds.withdrawals.otp.resend.titleSms': 'Didn`t get the OTP? you can try to ',
  'funds.withdrawals.otp.resend.buttonSms': 'resend OTP via SMS',
  'funds.withdrawals.otp.verify.submit': 'Verify',
  'funds.withdrawals.form_add_new_address': 'Add New Address',
  'funds.withdrawals.select_address': 'Select Address',
  'funds.withdrawals.no_address_selected': 'No address selected',
  'funds.withdrawals.enter_manually': 'Enter manually',
  'funds.withdrawals.change_address': 'Change Address',
  'funds.withdrawals.add_address': 'Add Address',
  'funds.withdrawals.error_address': 'Please select your withdrawal address',
  'funds.withdrawals.title_discard_new_address': 'Are you sure you want to discard?',
  'funds.withdrawals.text_discard_new_address': 'Inserted label and address will not be saved',
  'funds.withdrawals.added_new_address': 'Added new address',
  'funds.withdrawals.back_discard_new_address': 'No, go back',
  'funds.withdrawals.yes_discard_new_address': 'Yes, discard',
  'funds.withdrawals.select_saved_address': 'Select saved address',
  'funds.disable_coin_msg': 'Withdrawal/Deposit is currently disabled for this coin',
  'landing.banner.desc':
    "Up to 100x leverage. Trading without expiry dates. Industry-leading security. Welcome to Bitcoin's most advanced trading platform.",
  'landing.swiper.amal.title': '4IRE NET HOLDING COMPETITION!',
  'landing.swiper.amal.time': '21:00（GMT+9）30 Dec  2019 〜21：00（GMT+9）31 Jan 2020',
  'landing.banner.title.span': 'Smart Bitcoin -',
  'landing.banner.title.text': 'Crypto Currency Exchange',
  'landing.change': '24h Change',
  'landing.chart.change': 'Change',
  'landing.chart.last_price': 'Last Price',
  'landing.clients_download': 'Download',
  'landing.clients_text1': 'More ways to get {APP_NAME}',
  'landing.clients_text2': "We've provided some tools you need for trading on the go",
  'landing.coin': 'Coin',
  'landing.coin_markets': '{coin} Markets',
  'landing.favorites': 'Favorite',
  'landing.high': '24h High',
  'landing.last_price': 'Last Price',
  'landing.current_price': 'Current Price',
  'landing.low': '24h Low',
  'landing.pair': 'Pair',
  'landing.price': 'Price',
  'landing.register': 'Register now',
  'landing.gotrade': 'Go to Trade',
  'landing.slogan': 'Exchange the World',
  'landing.volume': '24h Volume',
  'landing.current_rate': 'Current <br/> Rate',
  'login.active_device_failed': 'Your authorization link is invalid or expired!',
  'login.active_device_success':
    'You have successfully authorized a new device. Please try to log in with it again!<p>Device: {platform} {operating_system}<br/>IP address: {latest_ip_address}</p>',
  'login.confirm_failed': 'Your Confirmation Link is Invalid or Expired!',
  'login.confirm_success': 'Your email has been confirmed!',
  'login.confirm_email_failed': "We can't find a user with that e-mail address.",
  'login.dont_has_account': 'Don’t have an account?',
  'login.forgot': 'Forgot password?',
  'login.sub_title': 'Please check you are visiting',
  'login.title': 'Log In',
  'login.title_otp': 'Google Authentication',
  'login.resend_confirm_mail_success':
    "Resent Confirm Email To <strong class='email-user' title='{email}'>{email}</strong>",
  'messages.user_cannot_withdraw_fiat': 'Please complete the KYC process to enable this function',
  'messages.error.error': 'Error',
  'messages.primary': 'Primary',
  'messages.success': 'Success',
  'messages.info': 'Info',
  'messages.warning': 'Warning',
  'menu.about': 'About',
  'menu.send': 'Send',
  'menu.account': 'Account',
  'menu.address': 'Address Management',
  'menu.api': 'API',
  'menu.apply_to_list': 'Apply to List',
  'menu.balances': 'Balances',
  'menu.change_password': 'Change Password',
  'menu.contact': 'Contact',
  'menu.deposit_usd': 'Deposit IDR',
  'menu.deposits': 'Deposits',
  'menu.estimated_value': 'Estimated Value',
  'menu.exchange': 'Exchange',
  'menu.fees': 'Exchange fees',
  'menu.funds': 'Funds',
  'menu.investments': 'Investments',
  'menu.request': 'Request',
  'menu.investors': 'Investors',
  'menu.history': 'History',
  'menu.history_title': 'History',
  'menu.info': 'Info',
  'menu.join_us': 'Join Us',
  'menu.lang.en': 'English',
  'menu.lang.id': 'Indonesian',
  'menu.lang.zh': 'Chinese',
  'menu.lang.ja': '日本人',
  'menu.lang.acr.en': 'EN',
  'menu.lang.acr.zh': 'ZH',
  'menu.login': 'Login',
  'menu.logout': 'Logout',
  'menu.get_started': 'Get Started',
  'menu.news': 'News',
  'menu.finance': 'Finance',
  'menu.instant_convert': 'Instant Convert',
  'menu.convert_history': 'Convert History',
  'menu.slippery_note': 'Slippery Note',
  'menu.one_day': '1 Day',
  'menu.one_month': '1 Month',
  'menu.one_week': '1 Week',
  'menu.open_orders': 'Open Orders',
  'menu.or': 'or',
  'menu.order_history': 'Order History',
  'menu.privacy': 'Privacy Policy',
  'menu.information_security': 'Information Security Policy',
  'menu.register': 'Register',
  'menu.signup': 'Sign Up',
  'menu.signin': 'Sign in',
  'menu.homepage': 'Home Page',
  'menu.sales_point': 'Sales Point',
  'menu.support': 'Support',
  'menu.support_withdrawal': 'Withdraw guide',
  'menu.spot_exchange': 'Spot Exchange',
  'menu.terms': 'Terms Of Use',
  'menu.three_months': '3 Months',
  'menu.trade_history': 'Trade History',
  'menu.transaction_history': 'Transaction History',
  'menu.demo': 'Demo',
  'menu.trade': 'Trade',
  'menu.wallet': 'Wallet',
  'menu.withdraw_usd': 'Withdrawal IDR',
  'menu.withdrawals': 'Withdrawals',
  'menu.select_languge': 'Select Language',
  'messages.error.balance_insufficient': 'Insufficient balance.',
  'messages.error.balance_insufficient1':
    'Insufficient balance. Please transfer money to Exchange balance.',
  'messages.error.buy.empty_base_price': 'Please input buy stop price.',
  'messages.error.buy.empty_limit': 'Please input buy limit price.',
  'messages.error.buy.empty_price': 'Please input buy price.',
  'messages.error.buy.empty_quantity': 'Please input buy amount.',
  'messages.error.empty_total': 'Total is required.',
  'messages.error.minimum_base_price': 'Stop price must be at least {value}.',
  'messages.error.minimum_limit': 'Limit price must be at least {value}.',
  'messages.error.minimum_price': 'Price must be at least {value}.',
  'messages.error.minimum_quantity': 'Amount must be at least {value}.',
  'messages.error.minimum_total': 'Minimum transaction amount is at least {value}.',
  'messages.error.sell.empty_base_price': 'Please input sell stop price.',
  'messages.error.sell.empty_limit': 'Please input sell limit price.',
  'messages.error.sell.empty_price': 'Please input sell price.',
  'messages.error.sell.empty_quantity': 'Please input sell amount.',
  'messages.insufficient_balance': 'Insufficient balance.',
  'messages.send_contact_success': 'Send email is successful',
  'messages.send_contact_wrong_data': 'Wrong data',
  'messages.sesstion_terminated':
    'Your current session is terminated because someone logged into this account from another device or browser.',
  'messages.successful_login': '{APP_NAME} login {email}\nLogin from {device}\n{time}',
  'messages.unauthorize': 'Unauthorized',
  'messages.your_otp_code': 'Your authentication code is {otp}',
  'order.open_order.no_data': 'You have no data',
  'order.open_order.no_data.all': 'There is no order.',
  'messages.order_cannot_cancel': 'Can not cancel because your order has been matched',
  'messages.bank_account_invalid': 'Bank account is invalid',
  'order.open_order.no_data.limit': 'There is no limit order.',
  'order.open_order.no_data.stop_limit': 'There is no stop limit order.',
  'order.open_order.no_data.market': 'There is no market order.',
  'order.open_order.no_data.stop_market': 'There is no stop market order.',
  'order.open_order.action': 'Action',
  'order.open_order.amount': 'Amount',
  'order.open_order.cancel': 'Cancel',
  'order.open_order.cancel_all': 'Cancel All',
  'order.open_order.cancel_all_message': 'Do you want to cancel all orders?',
  'order.open_order.cancel_market_orders': 'Cancel Market Orders',
  'order.open_order.cancel_limit_orders': 'Cancel Limit Orders',
  'order.open_order.cancel_stop_market_orders': 'Cancel Stop Market Orders',
  'order.open_order.cancel_stop_limit_orders': 'Cancel Stop Limit Orders',
  'order.open_order.cancel_limit_price_message': 'Do you want to cancel all Limit Orders?',
  'order.open_order.cancel_market_price_message': 'Do you want to cancel all Market orders?',
  'order.open_order.cancel_one_message': 'Do you want to cancel this order?',
  'order.open_order.cancel_order_success': 'Your order has been canceled!',
  'order.open_order.match_order_success': 'Your order has been matched!',
  'order.open_order.cancel_orders_success': 'Your orders have been canceled!',
  'order.open_order.cancel_stop_limit_order': 'Cancel Stop Limit Order',
  'order.open_order.cancel_stop_limit_price_message':
    'Do you want to cancel all Stop Limit Orders?',
  'order.open_order.cancel_stop_market_order': 'Cancel Stop Market Order',
  'order.open_order.cancel_stop_market_price_message':
    'Do you want to cancel all Stop Market Orders?',
  'order.open_order.date': 'Date',
  'order.open_order.deposit': 'Deposit',
  'order.open_order.empty_open_order': "You don't have open order.",
  'order.open_order.filled': 'Filled',
  'order.open_order.hide_other_pairs': 'Hide Other Pairs',
  'order.open_order.hide_small_assets': 'Hide 0 Balances',
  'order.open_order.infomation': 'Information',
  'order.open_order.limit_order': 'Limit Order',
  'order.open_order.market': 'Market',
  'order.open_order.open_order': 'Open Order',
  'order.open_order.pair': 'Pair',
  'order.open_order.price': 'Price',
  'order.open_order.side': 'Side',
  'order.open_order.exec_type': 'Exec Type',
  'trade.exec_type_sell': 'Trading',
  'trade.exec_type_trading': 'Trading',
  'trade.exec_type_buy': 'Trading',
  'order.open_order.stop_limit_order': 'Stop_Limit Order',
  'order.open_order.total': 'Total',
  'order.open_order.trigger_conditions': 'Trigger Conditions',
  'order.open_order.type': 'Type',
  'order.open_order.withdrawal': 'Withdrawal',
  'order.open_order.withdrawal_limit_24': '24 Withdrawal Limit',
  'order.order_book.order_book': 'Order Book',
  'order.order_book.amount': 'Amount',
  'order.order_book.decimals': 'Decimals',
  'order.order_book.price': 'Price',
  'order.order_book.total': 'Total',
  'order.order_form.amount': 'Amount',
  'order.order_form.balance': '{param} Balance',
  'order.order_form.all': 'All',
  'order.order_form.buy': 'Buy',
  'order.order_form.limit': 'Limit',
  'order.order_form.market': 'Market',
  'order.order_form.stop_limit': 'Stop Limit',
  'order.order_form.stop_market': 'Stop Market',
  'order.order_form.limited_price': 'Limit',
  'order.order_form.login': 'Login',
  'order.order_form.or': 'or',
  'order.order_form.price': 'Price',
  'order.order_form.register': 'Register',
  'order.order_form.sell': 'Sell',
  'order.order_form.stop': 'Stop',
  'order.order_form.stop_limit_tooltip':
    'A Stop-Limit order is an order to buy or sell a coin with a given price once the price reaches a specified trigger price.',
  'order.order_form.stop_market_tooltip':
    'A Stop-Market order is an order to buy or sell a coin with a market price once the price reaches a specified trigger price.',
  'order.order_form.success': '{trade_type} order is created successfully!',
  'order.order_form.total': 'Total',
  'order.order_form.trade': 'to trade',
  'order.order_form.view_more': 'View more...',
  'circuit_breaker.blocking': 'Trading coin pair is blocking !',
  'circuit_breaker.unblocking': 'Trading coin pair is unblocking !',
  'circuit_breaker.remain_second_text':
    'Trading is blocking by Circuit Breaker. Unlock in {minutes} minutes.',
  'beta_tester.header': 'Participants for beta version tester',
  'beta_tester.content':
    "This version is a beta version. <br>Users who wish to use the beta version should check the following and check the checkbox to apply to the administrator.<br>* Since it is a beta version, some functions may be defective.  If you find a bug, please report it to the administrator immediately.<br><a href = 'mailto: kim@Demo.com'>kim@s0tatek.com</a>",
  'beta_tester.dont_show_again': "Don't show again",
  'beta_tester.ignore_tester': 'Ignore Tester',
  'beta_tester.agree_term': 'Agree our terms & conditions!',
  'beta_tester.ok': 'OK',
  'beta_tester.or': 'Or',
  'beta_tester.cancel': 'Cancel',
  'beta_tester.validate.agree_term': 'Please agree terms and conditions',
  'beta_tester.register_success': 'Register Beta Tester Success',
  'beta_tester.register_fail': 'Register Beta Tester Fail',
  'beta_tester.disable_by_beta_tester': 'Please become Beta Tester to trade',
  'beta_tester.wait_admin_verify.header': 'Wait Admin Confirm',
  'beta_tester.wait_admin_verify.content':
    'Please wait admin confirm your Register Beta Tester Request.',
  'order.order_history.action': 'Action',
  'order.order_history.no_data': 'You have no data',
  'order.order_history.all': 'All',
  'order.order_history.amount': 'Amount',
  'order.order_history.average': 'Average',
  'order.order_history.buy': 'Buy',
  'order.order_history.cancel': 'Cancel',
  'order.order_history.cancel_all': 'Cancel All',
  'order.order_history.coin': 'Coin',
  'order.order_history.date': 'Date',
  'order.order_history.from': 'From',
  'order.order_history.to': 'To',
  'order.order_history.deposit': 'Deposit',
  'order.order_history.empty_order_history': "You don't have order history.",
  'order.order_history.export_complete_order_history': 'Export Order History',
  'order.order_history.export_complete_order_history_tooltip':
    'Only Export Complete Order History on 6 months',
  'order.order_history.export_complete_trade_history': 'Export Trade History',
  'order.order_history.export_complete_trade_history_tooltip':
    'Only Export Complete Trade History on 6 months',
  'order.order_history.fee': 'Fee',
  'order.order_history.tax': 'Tax',
  'order.order_history.filled': 'Filled',
  'order.order_history.hide_all_canceled': 'Hide All Canceled',
  'order.order_history.infomation': 'Information',
  'order.order_history.limit_order': 'Limit Order',
  'order.order_history.market': 'Market',
  'order.order_history.order_history': 'Order History',
  'order.order_history.pair': 'Pair',
  'order.order_history.price': 'Price',
  'order.order_history.reset': 'Reset',
  'order.order_history.search': 'Search',
  'order.order_history.sell': 'Sell',
  'order.order_history.side': 'Side',
  'order.order_history.status': 'Status',
  'order.order_history.stop_limit_order': 'Stop_Limit Order',
  'order.order_history.total': 'Total',
  'order.order_history.trigger_conditions': 'Trigger Conditions',
  'order.order_history.type': 'Type',
  'order.order_history.withdrawal': 'Withdrawal',
  'order.order_history.withdrawal_limit_24': '24 Withdrawal Limit',
  'order.order_trade_form.order': 'Order',
  'order.order_trade_form.trades': 'Trades',
  'order.order_trade_form.price': 'Price',
  'order.order_trade_form.size': 'Size',
  'order.order_trade_form.time': 'Time',
  'order.recent_trades.market': 'Market',
  'order.recent_trades.recent_trades': 'Recent Trades',
  'order.recent_trades.yours': 'Yours',
  'order.trade_history.action': 'Action',
  'order.trade_history.no_data': 'You have no data',
  'order.trade_history.all': 'All',
  'order.trade_history.amount': 'Amount',
  'order.trade_history.average': 'Average',
  'order.trade_history.buy': 'Buy',
  'order.trade_history.cancel': 'Cancel',
  'order.trade_history.cancel_all': 'Cancel All',
  'order.trade_history.coin': 'Coin',
  'order.trade_history.date': 'Date',
  'order.trade_history.deposit': 'Deposit',
  'order.trade_history.export_complete_trade_history': 'Export Complete Trade History',
  'order.trade_history.fee': 'Fee',
  'order.trade_history.filled': 'Filled',
  'order.trade_history.hide_all_canceled': 'Hide All Canceled',
  'order.trade_history.infomation': 'Information',
  'order.trade_history.limit_order': 'Limit Order',
  'order.trade_history.market': 'Market',
  'order.trade_history.my_history': 'My order history',
  'order.trade_history.pair': 'Pair',
  'order.trade_history.price': 'Price',
  'order.trade_history.reset': 'Reset',
  'order.trade_history.search': 'Search',
  'order.trade_history.sell': 'Sell',
  'order.trade_history.side': 'Side',
  'order.trade_history.status': 'Status',
  'order.trade_history.stop_limit_order': 'Stop_Limit Order',
  'order.trade_history.total': 'Total',
  'order.trade_history.trade_history': 'Trade History',
  'order.trade_history.trigger_conditions': 'Trigger Conditions',
  'order.trade_history.type': 'Type',
  'order.trade_history.withdrawal': 'Withdrawal',
  'order.trade_history.withdrawal_limit_24': '24 Withdrawal Limit',
  'order_mobile.charts': 'Charts',
  'order_mobile.group': 'Group',
  'order_mobile.max_amount': 'Max Amount',
  'order_mobile.open_order': 'Open Order',
  'order_mobile.total': 'Total',
  'order_mobile.trade': 'Trade',
  'google_recaptcha.errors': 'The google recaptcha field is required.',
  'server.errors': 'Register again after 60 seconds',
  'server.logs': 'Too many requests in 60 seconds',
  'passwords.password': 'Passwords must be at least six characters and match the confirmation.',
  'passwords.reset': 'Your password has been reset, now you can login.',
  'passwords.sent': 'We have e-mailed your password reset link!',
  'passwords.token': 'This password reset token is invalid.',
  'passwords.user': "We can't find a user with that e-mail address.",
  'refferal.alert': 'You are not logged in, please login and share with your friends.',
  'refferal.alert_bank_account_content':
    'Please submit <span style="color: #f29600;">KYC</span> before verify NI Bank account. We’ll use your KYC information to verify bank account',
  'refferal.confirm_bank_account_content':
    'I have not had NI Bank account yet, please help me to creat one.',
  'refferal.confirm_bank_account_title':
    'Do you want to verify NI Bank account for USD withdrawals using KYC information?',
  'refferal.get_rewards': 'Get Rewards',
  'refferal.login': 'Login',
  'refferal.not_on_site': 'Not on {APP_NAME} yet? ',
  'refferal.register': 'Register',
  'refferal.copy_link': 'Copy Link',
  'refferal.text1': 'Register and generate referral links and QR codes ',
  'refferal.text10': 'Referral Friends',
  'refferal.text11': 'Date',
  'refferal.text12': 'Program Details',
  'refferal.text13': 'Effective as of {effectTime} (UTC)',
  'refferal.text14':
    'The commission you receive from the referral program will be depended on trading fee of referee. <br/>Currently, payment rate is Maximum {rate}% trading fee of referee. <br/>We are supporting multi-level referral.',
  'refferal.text15':
    'The commission fee will be sent instantly in real-time to your {APP_NAME} account as your referee completes each trade and will be paid to you in whatever token/digital assets the original fee was paid in.',
  'refferal.text16':
    'There is no limit to the number of friends you can refer, although we do reserve the right to adjust or change the referral program rules at any time.',
  'refferal.text18':
    'Each referee must be signed up through your Referral Link, QR Code or Referral ID.',
  'refferal.text19':
    '{APP_NAME} will check for duplicate or fake accounts and will not pay out referral bonuses on these accounts. Duplicate or shared finances will result in disqualification.',
  'refferal.text2': 'Invite Friends ',
  'refferal.text20': '* Important Notice',
  'refferal.text21':
    '{APP_NAME} reserves the right to change the terms of the referral program at any time due to changing market conditions, risk of fraud, or any other factors we deem relevant.',
  'refferal.text22': 'Commission',
  'refferal.text23': 'Estimated Commission Value Spot Exchange',
  'refferal.text24': 'Latest Commission History',
  'refferal.text25': 'Copied successfully!',
  'refferal.text26': 'Export',
  'refferal.text27': 'Commission Rate',
  'refferal.text3':
    'Invite your friends to register through the referral link or QR codes and get rewards once they complete a trade',
  'refferal.text4': 'Get Paid',
  'refferal.text5': 'Receive up to {percent}% commission in real-time',
  'refferal.text6': 'My Referral ID:',
  'refferal.text7': 'Your Commission Rate:',
  'refferal.text8': 'Referral Link:',
  'refferal.text9': 'Share:',
  'refferal.title': 'Referral Program',
  'refferal.title1': 'Get Your Link',
  'refferal.top': 'NO.',
  'referral.level1': 'Level 1',
  'referral.level2': 'Level 2',
  'referral.level3': 'Level 3',
  'referral.level4': 'Level 4',
  'referral.level5': 'Level 5',
  'referral.rate': 'Rate is ',
  'fee_tier.spot_ex': 'Spot Exchange',
  'fee_tier.title': 'Fee Tier Program',
  'fee_tier.guide.step_1_title': 'Trade',
  'fee_tier.guide.step_1_description': 'Make Spot Trades',
  'fee_tier.guide.step_2_title': 'Increase Average Daily Volume',
  'fee_tier.guide.step_2_description':
    'Each time an User Order is matched, increase Average Daily Volume (ADV) by the value of that Order',
  'fee_tier.guide.step_3_title': ' Increase Tier',
  'fee_tier.guide.step_3_description':
    'At the start of every period, system will update new User Tier for this period based on Average Daily Volume of previous program period',
  'fee_tier.guide.step_4_title': 'Apply Tier Trading Fee',
  'fee_tier.guide.step_4_description':
    'Apply new Trading Fee for this period based on calculated User Tier',
  'fee_tier.overview.current_volume_trading':
    "Current {type} Average Daily Volume <br/> For estimating Next Period's Tier",
  'fee_tier.overview.current_tier': 'Current {type} Tier <br/> (Based on Last Period ADV)',
  'fee_tier.overview.estimate_tier': 'Estimated {type} Tier <br/> For Next Period',
  'fee_tier.overview.amount_of_volume': 'ADV To Reach Next {type} Tier <br/> For Next Period',
  'fee_tier.overview.period_time_left': 'Program Period Time Left <br/> (Update everyday)',
  'fee_tier.overview.special_bonus':
    'Special List Bonus Fee Discount <br/> (Discount from your current Trade Fee)',
  'fee_tier.volume_history.title': 'Daily Volume History',
  'fee_tier.volume_history.date': 'Date',
  'fee_tier.volume_history.period': 'Period Day',
  'fee_tier.volume_history.volume': 'Daily Volume',
  'fee_tier.volume_history.exchange_type': 'Exchange Type',
  'fee_tier.volume_history.average_volume': 'ADV',
  'fee_tier.volume_history.estimates_tier': 'Estimated Tier',
  'fee_tier.volume_history.nodata': 'You have no data',
  'fee_tier.trade_history.title': 'Trade History',
  'fee_tier.trade_history.date': 'Date',
  'fee_tier.trade_history.symbol': 'Symbol',
  'fee_tier.trade_history.side': 'Side',
  'fee_tier.trade_history.quantity': 'Quantity',
  'fee_tier.trade_history.total': 'Total',
  'fee_tier.trade_history.fee': 'Fee',
  'fee_tier.trade_history.nodata': 'You have no data',
  'fee_tier.export': 'Export',
  'fee_tier.last_period_program_detail': 'Last Period Program Details',
  'fee_tier.last_period_program_detail.text_1':
    'Your trading fee for current Period (Highlighted Tier) will be based on your Average Daily Volume Tier of last Program Period as information below:',
  'fee_tier.current_period_program_detail': 'Current Period Program Details',
  'fee_tier.current_period_program_detail.text_1':
    'Your trading fee for next Period will be based on your Average Daily Volume Tier of Current Program Period (Tier estimation for next period is calculated everyday on Daily Volume History above)',
  'fee_tier.notice': '* Important Notice',
  'fee_tier.notice.text_1':
    'Exchange reserves the right to change the terms of the Fee Tier Program at any time due to changing market conditions, risk of fraud, or any other factors we deem relevant.',
  'fee_tier.detail.tier': 'Tier',
  'fee_tier.detail.required_adv': 'Required ADV',
  'fee_tier.detail.taker_fee': 'Taker Fee',
  'fee_tier.detail.maker_fee': 'Maker Fee',
  'fee_tier.detail.nodata': 'No data',
  'register.error_terms': 'The agree terms field is required.',
  'register.has_account': 'Already have an account?',
  'register.sub_title': 'Create Your Account',
  'register.success':
    '<span>Congratulations! </span><strong>{email}</strong><p>Please check your mailbox to verify your account</p>',
  'register.success_2':
    "<div><p style='line-height: 28px;color: #333;font-size: 14px;margin: 0;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;'>We sent a confirmation email to <span title={email}>{email}</span>.</div><div>Please follow the instructions to complete your registration.</p></div>",
  'register.success_3':
    'You have been successfully registered. To activate your account check your email and confirm your registration.',
  'register.terms_1': 'I agree to the',
  'register.terms_2': 'terms',
  'register.title': 'Register',
  'register.title_2': 'Resend',
  'register.title_3': "If you haven't received the email, do the following:",
  'register.paragraph_1': 'Make sure provided email address is correct.',
  'register.paragraph_2': 'Check spam or other folders.',
  'register.paragraph_3': 'Set email address whitelist.',
  'register.paragraph_4': 'Check the mail client works normally.',
  'register.sendConfirmEmail': 'Send confirmation email',
  'register.congratulation': 'Congratulation!',
  reset_passsword_complete: 'Password Reset Complete',
  'reset_password.resetted_password': 'Your password has been reset, now you can login.',
  'reset_password.send_mail_title': 'Send Password Reset Email',
  'reset_password.send_mail_title_2': 'A password reset email has been sent',
  'reset_password.sent_mail':
    '<strong style="line-height: 25px;color: #333;font-size: 16px;font-weight: 400">A password reset email has been sent to your registered email address.</strong><br>Please follow the instructions in the email and reset your password within 24 hours of receiving the email.',
  'reset_password.sent_mail_2':
    'Please access the URL and reset your password within 24 hours of receiving the email',
  'reset_password.sub_title_1': 'Please input your email',
  'reset_password.sub_title_2': 'Enter your new password',
  'reset_password.submit_btn': 'Submit',
  'reset_password.title': 'Reset Password',
  'reset_password.title_forgot': 'Forgot Password',
  'reset_password.back_to': 'Back to',
  'reset_password.forgot_content':
    'Please enter the email address you’d like your password reset information sent to',
  'reset_password.reset_content': 'Enter a new password below to change your password',
  policy: {
    title: 'Privacy Policy',
    last_revised: 'Last updated on: 21 August 2023',
    content_general:
      "Welcome to <a href='{APP_URL}'>{APP_HOST}</a>, the leading crypto exchange platform operating in Indonesia. We highly value your trust as a user, and we are committed to safeguarding your privacy and personal information. This Privacy Policy explains how we collect, use, and protect the information you provide to us when using our services.",
    title_1: '1. Collection of Personal Information',
    content_1:
      '<p> We may collect your personal information when you register, access, or use our services. The personal information we collect may include, but is not limited to: </p> <br /> <div>Full name</div> <div>Email address</div> <div>Phone number</div> <div>Physical address</div> <div> Identity verification information (such as ID card or passport) </div> <div>Bank account information</div> <div>Crypto transaction history</div>',
    title_2: '2. Use of Information',
    content_2:
      '<p>We use the information we collect to:</p> <br /> <p> Verify your identity in compliance with Indonesian legal requirements. </p> <p>Process transactions you initiate.</p> <p>Manage and maintain your account on NVX.co.id.</p> <p> Send service updates, promotions, and other important information. </p> <p>Provide effective customer support.</p> <p>Comply with legal obligations and regulations in force.</p>',
    title_3: '3. Security of Information',
    content_3:
      'We take necessary steps to protect your personal information from unauthorized access, use, or disclosure. We employ appropriate technical and organizational security measures to maintain the integrity and confidentiality of your information.',
    title_4: '4. Information Storage',
    content_4:
      'Your personal information will be stored for as long as necessary for the purposes outlined in this Privacy Policy, or as required by applicable law. Once the information is no longer needed, we will securely delete it according to our data retention policy.',
    title_5: '5. Third-Party Service Providers',
    content_5:
      'We may use third-party service providers to assist us in our business operations and services. Your information may be shared with these service providers only to the extent necessary for operational purposes and legal compliance.',
    title_6: '6. Changes to Privacy Policy',
    content_6:
      'We may update this Privacy Policy to reflect changes in our practices or applicable regulations. The latest version of the Privacy Policy will always be posted on our website, and we will notify you of significant changes through email or notifications on the website.',
    title_7: '7. Your Privacy Rights',
    content_7:
      'You have the right to access, correct, or delete your personal information that we hold. You also have the right to request that we cease using your personal information for direct marketing purposes. To access or update your personal information, please contact us using the contact details provided below.',
    title_8: '8. Contact Us',
    content_8:
      '<p> If you have questions, comments, or requests related to this Privacy Policy, please contact us at: </p> <br /> <p>Email Address: hello@nvx.co.id</p> <p> Physical Address: Equity Tower FL 42 Unit G, SCBD Jl. Jend. Sudirman Kav 52-53 Jakarta, Indonesia 12190 PT. Aset Kripto Internasional. </p> <p>All rights reserved.</p>',

    information_security: {
      title: 'Information Security Policy',
      company_commitment: 'PT Aset Kripto Internasional is committed to:',
      content_1: 'Implementing an information security management system based on ISO/IEC 27001:2022, as well as complying with applicable laws and other relevant requirements.',
      content_2: 'Continuously optimizing and improving the security of our information through enhanced information security management and a strong corporate culture to achieve customer satisfaction and loyalty.',
      content_3: 'Controlling information security risks to prevent incidents caused by information leaks in accordance with relevant laws and requirements by:',
      content_3a: 'Providing the necessary resources to ensure the information security system.',
      content_3b: 'Cultivating and fostering information security competence among all employees and partners.',
      content_4: 'Minimizing negative impacts, preventing information leaks, and continuously improving the performance of relevant information security indicators in compliance with applicable laws and information security requirements.',
      content_5: 'Ensuring the confidentiality, integrity, and availability of data within the company while considering applicable risks and opportunities to meet the needs or expectations of stakeholders.'
    },
  },
  'terms.term_end_note.title': '',
  'terms.term_end_note.content_1': '',
  'terms.term_end_note.content_2': '',
  'terms.term_end_note.content_3': '',
  'terms.term_end_note.content_4': '',
  'terms.title': 'Terms of Use',
  'terms.last_revised': '[Last revised: 09 Jan 2024]',
  'terms.introduce_1':
    "NVX is a future-oriented digital assets circulation trading community, which website <a href='{APP_URL}'>{APP_URL}</a> (hereinafter referred to as 'this Website' or 'the Website') is a platform dedicated to the transaction of digital assets and the provision of related services (hereinafter referred to as 'the Service'). For the convenience of wording in this Agreement, NVX and the Website are referred to as 'NVX' or other applicable forms of first person pronouns in this Agreement. All natural persons or other subjects who log onto this Website shall be users of this Website. For the convenience of wording in this Agreement, users and NVX are collectively referred to as“both parties”, and individually as “one party”. And all services provided by NVX are referred to as “the services”.",
  'terms.introduce_2':
    'Digital assets trading is highly risky and therefore not suitable for the vast majority of people. The user acknowledges and understands that investment in digital assets may result in partial or total loss of the user’s investment and therefore the user is advised to decide the amount of the user’s investment on the basis of the user’s loss-bearing capacity. Furthermore, aside from the above-mentioned risks, there may also be unpredictable risks. Therefore, the user is advised to carefully consider and use clear judgment to assess the user’s financial position and the abovementioned risks before making any decisions on buying and selling digital asset; any and all losses arising therefrom will be borne by the user and NVX shall not be held liable in any manner whatsoever.',
  'terms.term_1.title': '1. General Provisions',
  'terms.term_1.content_1_1':
    '1.1. Before using any service offered by this Website, the user shall read this Agreement carefully, and consult a professional lawyer if the user have any doubt or as may be otherwise necessary. If the user does not agree to the Terms and Conditions of this Agreement and/or any change made thereto from time to time and at any time, please immediately stop using the service provided by this Website or stop logging into this Website. Upon the user logging into this Website or using any service offered by this Website or engaging in any other similar activity, it shall be deemed as the user has understood and fully agreeing to all terms and conditions of this Agreement, including any and all changes.',
  'terms.term_1.content_1_2':
    "1.2. After filling in the relevant information in accordance with the requirements of this Website, and going through other relevant procedures, the user successfully register itself as a member of this Website (hereinafter referred to as 'Member'); in the process of registration, if the user click on the “I Agree” button it shall be deemed that the user has reached an agreement with NVX by way of electronic signature; or when the user uses this Website, the user click on the “I Agree” button or a similar button, or if the user uses the services offered by this Website in any of the ways allowed by this Website, it shall be deemed that the user fully understand, agree to and accept all the Terms and Conditions under this Agreement, and in this case, the absence of the user’s handwritten signature will not affect the legal binding force that this Agreement may have on the user.",
  'terms.term_1.content_1_3':
    '1.3. After the user become a member of this Website, the user will receive a member account and corresponding password, which shall be properly kept by the user as a member of this Website; Members shall be liable for all activities and events carried out through their accounts which NVX assumes no responsibility for these.',
  'terms.term_1.content_1_3_1':
    'The user cannot engage in trading digital assets by this Website and gain access to the services that are exclusively available to members in accordance with the rules and regulations of this Website, unless and until the user become a member of this Website; if the user is not a member of this Website, the user can only log into and browse the Website and have access to other services as are permitted by the rules and regulations of this Website.',
  'terms.term_1.content_1_4':
    '1.4. Upon the user registering as a member of this Website and using any of the services and functions offered by this Website, it shall be deemed that the user have read, understood this Agreement, and the provisions below.',
  'terms.term_1.content_1_4_1':
    '1.4.1. Accepted to be bound by all Terms and Conditions of this Agreement',
  'terms.term_1.content_1_4_2':
    '1.4.2. The user confirms that the user has attained the required by a different applicable law, and has sufficient capacity to accept these terms and conditions herein, to enter into a transaction, and to use this Website for digital assets transactions.',
  'terms.term_1.content_1_4_3':
    '1.4.3. The user undertakes that all the user’s digital assets involved in transactions hereunder are legally acquired and owned by the user.',
  'terms.term_1.content_1_4_4':
    '1.4.4. The user agree to undertake any and all liabilities for the user’s own transaction and non-transaction activities as well as any and all profits and losses therefrom and NVX assumes no responsibility for these.',
  'terms.term_1.content_1_4_5':
    '1.4.5. The user confirm that the information provided at the time of registration is true and accurate.',
  'terms.term_1.content_1_4_6':
    '1.4.6. The user agree to comply with any and all relevant laws, including the reporting of any transaction profits for tax purposes.',
  'terms.term_1.content_1_4_7':
    '1.4.7. This Agreement is only binding on the rights and obligations between the user and NVX, and does not involve any legal relations and legal disputes arising from and relating to the transaction of digital assets between the users of this Website, and between other websites and the user.',
  'terms.term_2.title': '2. Amendment of this Agreement',
  'terms.term_2.content_2_1':
    'NVX will reserve the right to amend this Agreement from time to time, and disclose such amendment by way of announcement on the Website without sending a separate notice to the user on the user’s rights. The date when the amendment is made will be indicated on the first page of the amended agreement. The amended agreement will take effect immediately upon announcement on the Website. The user shall browse this Website from time to time and follow information on the time and content of amendments, if any, made to this Agreement. If the user do not agree with the amendments, the user shall stop using the services offered by this Website immediately; if the user continue to use the services offered by this Website, it shall be deemed that the user accept and agree to be bound by the amended agreement.',
  'terms.term_3.title': '3. Registration',
  'terms.term_3.title_1': '3.1. Eligibility for Registration',
  'terms.term_3.content_3_1_1':
    'The user should confirm: the user is a natural person, corporation or other organization with the ability to sign this agreement by applicable law when completing the registration process or use the Service on the Website in any other permitted way. If the user clicks the “Agree” button for registration, the user agrees to the terms of this agreement and logs in, or an agent authorized by the user agrees to the terms of this agreement on behalf of the user, it is assumed that the user had registered on this website and used the service.',
  'terms.term_3.content_3_1_2':
    'If the user is not a natural person, legal person or organization with the abovementioned ability, the user and the user’s authorized agent shall bear all the consequences of that, and NVX reserves the right to cancel or permanently freeze the user’s account and to hold the user and the user’s authorized agent accountable.',
  'terms.term_3.title_2': '3.2. Purpose of Registration',
  'terms.term_3.content_3_2_1':
    'The user confirms and promises that the user does not register with this Website for the purpose of violating any of the applicable laws or regulations or undermining the order of digital assets transactions on this Website.',
  'terms.term_3.title_3': '3.3. Registration Process',
  'terms.term_3.content_3_3_1':
    '3.3.1. The user agree to provide a valid email address, a mobile phone number and other information in accordance with the requirements on the user registration page of this Website. The user can use the email address, mobile phone number or any other manner permitted by this Website to log in to this Website. Where it is necessary and in accordance with the requirements of applicable laws and regulations of relevant jurisdictions, the user shall provide its real name, identity card and other information required by applicable laws, regulations, the Terms of Privacy, and anti-money-laundering terms, and constantly update the user’s registration data so that they will be timely, detailed and accurate as is required. All of the original typed data will be referenced as registration information. The user shall be responsible for the authenticity, integrity and accuracy of such information and bear any direct or indirect loss and adverse consequences arising out of it.',
  'terms.term_3.content_3_3_2':
    '3.3.2. If any of the applicable laws, regulations, rules, orders and other regulatory documents of the sovereign country or region in which the user is based requires that mobile phone accounts must be based on real names, the user hereby confirm that the mobile phone number the user provide for registration purposes has gone through the real-name registration procedure. If the user cannot provide such a mobile phone number as is required, any direct or indirect losses and adverse consequences arising therefrom and affecting the user shall be borne by the user.',
  'terms.term_3.content_3_3_3':
    '3.3.3. After the user provides the required registration information in a legal, complete and valid manner and such information passes relevant verification, the user shall have the right to obtain an account and a password of this Website. Upon obtaining such account and password, the user’s registration shall be deemed as successful and the user can log into this Website as a member thereof.',
  'terms.term_3.content_3_3_4':
    '3.3.4. The user agree to receive emails and/or short messages sent by this Website related to the management and operation thereof.',
  'terms.term_4.title': '4. Service',
  'terms.term_4.content_4_0':
    'This Website only provides online transaction platform services for the user to engage in digital assets trading activities through this Website (including but not limited to the digital assets transactions etc.). This Website does not participate in the transaction of digital assets as a buyer or seller.',
  'terms.term_4.title_1': '4.1. Content of Services',
  'terms.term_4.content_4_1_1':
    '4.1.1. The user has the right to browse the real-time quotes and transaction information of digital assets products on this Website, to submit digital assets transaction instructions and to complete the digital assets transaction through this Website.',
  'terms.term_4.content_4_1_2':
    '4.1.2. The user have the right to participate in the website activities organized by this Website in accordance with the rules of activities posted on this Website.',
  'terms.term_4.content_4_1_3':
    '4.1.3. Other services that this Website promises to offer to the user.',
  'terms.term_4.title_2': '4.2. Service Rules',
  'terms.term_4.content_4_2_0':
    'The user undertakes to comply with the following service rules of this Website:',
  'terms.term_4.content_4_2_1':
    '4.2.1. The user shall comply with the provisions of applicable laws, regulations, rules, and policy requirements, and ensure the legality of the source of all digital assets in the user’s account, and shall refrain from engaging in any illegal activities or other activities that damages the rights and interests of this Website or any third party, such as sending or receiving information that is illegal, illicit or infringes on the rights and interests of any other person, sending or receiving pyramid scheme information or information or remarks causing other harms, unauthorized use or falsification of the email header information of this Website, inter alia.',
  'terms.term_4.content_4_2_2':
    '4.2.2. The user shall comply with applicable laws and regulations and properly use and keep its account in this Website and login password, password of the user’s financial transactions, and the mobile phone number bound with the user’s account that the user provide upon registration of the user’s account, as well as the security of the verification codes received via the user’s mobile phone. The user shall be solely responsible for any and all the user’s operations carried out using its account with this Website and login password, financial transaction password, verification codes sent to the user’s mobile phone, as well as all consequences of such operations. When the user finds that the user’s account with this Website, the user’s login password, financial transaction password, or mobile phone verification codes is used by any unauthorized third party, uncover any other problem relating to the security of the user’s account, the user shall inform this Website in a prompt and effective manner, and request this Website to temporarily suspend the services to the user’s account with this Website. This Website shall have the right to take action on the user’s request within a reasonable time; nonetheless, this Website does not bear any liability for the consequences that have arisen before such action is taken, including but not limited to any loss that the user may sustain. The user may not assign its account with this Website to any other person by way of donation, lending, leasing, transfer or otherwise without the consent of this Website.',
  'terms.term_4.content_4_2_3':
    '4.2.3. The user agree to take responsibility for all activities (including but not limited to information disclosure, information release, online click-approving or submission of various agreements on rules, online renewal of agreements or purchase service) using the user’s account and password with this Website.',
  'terms.term_4.content_4_2_4':
    '4.2.4. In the user’s digital assets transactions on this Website, the user may not maliciously interfere with the normal proceeding of the digital assets transaction or disrupt the transaction order; the user may not use any technical means or other means to interfere with the normal operation of this Website or interfere with the other users’ use of the services; the user may not maliciously defame the business goodwill of this Website on the ground of falsified fact.',
  'terms.term_4.content_4_2_5':
    '4.2.5. If any dispute arises between the user and any other user in connection with online transaction, the user may not resort to any means other than judicial or governmental means to request this Website to provide relevant information.',
  'terms.term_4.content_4_2_6':
    '4.2.6. All taxes payable as well as all fees relating to hardware, software and services that are incurred by the user in the course of using the services provided by this Website shall be solely borne by the user.',
  'terms.term_4.content_4_2_7':
    '4.2.7. The user shall abide by this Agreement and other terms of service and operating rules that this Website may release from time to time, and the user has the right to terminate the user’s use of the services provided by this Website at any time.',
  'terms.term_4.title_3': '4.3. Product Rules',
  'terms.term_4.content_4_3_1': '4.3.1. Rules for crypto to crypto trading products',
  'terms.term_4.content_4_3_1_0':
    'The user undertake that in the process in which the user log into this Website and engage in currency-currency transactions through this Website the user will properly comply with the following transaction rules.',
  'terms.term_4.content_4_3_1_1': '4.3.1.1. Browsing transaction information',
  'terms.term_4.content_4_3_1_1.introduce':
    'When the user browses the currency-currency transaction information on this Website, the user shall read all the content in the transaction information, including but not limited to the price, consignment, handling fee, buying or selling direction, and the user shall accept all the contents contained in the transaction information before the user may click on the button to proceed with the transaction.',
  'terms.term_4.content_4_3_1_2': '4.3.1.2. Submission of Commission',
  'terms.term_4.content_4_3_1_2.introduce':
    'After browsing and verifying the transaction information, the user may submit its transaction commissions. After the user submits the transaction commission, it shall be deemed that the user authorizes this Website to broker the user for the corresponding transactions, and this Website will automatically complete the matchmaking operation when there is a transaction proposal that meets the user’s price quotation, without prior notice to the user.',
  'terms.term_4.content_4_3_1_3': '4.3.1.3. Accessing transaction details',
  'terms.term_4.content_4_3_1_3.introduce':
    'The user can check the corresponding transaction records in the transaction statements by the Management Center, and confirm its own detailed transaction records.',
  'terms.term_4.content_4_3_1_4':
    '4.3.1.4. Revoking/modifying transaction commission. The user has the right to revoke or modify its transaction commission at any time before the transaction is concluded.',
  'terms.term_5.title': '5. Rights and Obigations of this Website',
  'terms.term_5.content_5_1':
    '5.1. If the user does not have the registration qualifications agreed on in this Agreement, this Website shall have the right to refuse to allow the user to register; if the user has already registered, this Website shall have the right to revoke the user’s member account, and this Website reserves the right to hold the user or the user’s authorized agent accountable. Furthermore, this Website reserves the right to decide whether to accept the user’s application for registration under any other circumstances.',
  'terms.term_5.content_5_2':
    '5.2. When this Website finds out that the user of an Account is not the initial registrant of that Account, it shall have the right to suspend or terminate the user’s access to that Account.',
  'terms.term_5.content_5_3':
    '5.3. Where by means of technical testing or manual sampling, among others, this Website reasonably suspects that the information the user provide is wrong, untrue, invalid or incomplete, this Website shall have the right to notify the user to correct or update the information, or suspend or terminate its supply of the services to the user.',
  'terms.term_5.content_5_4':
    '5.4. This Website shall have the right to correct any information displayed on this Website when it uncovers any obvious error in such information.',
  'terms.term_5.content_5_5':
    '5.5. This Website reserves the right to modify, suspend or terminate the Services offered by this Website, at any time, and the right to modify or suspend the Service without prior notice to the user; if this Website terminates one or more of the Services offered by this Website, such termination by this Website will take effect on the date of announcement of such termination on the Website.',
  'terms.term_5.content_5_6':
    '5.6. This Website shall take necessary technical means and management measures to ensure the normal operation of this Website, and shall provide a necessary and reliable trading environment and transaction services, and shall maintain the order of digital assets trading.',
  'terms.term_5.content_5_7':
    '5.7. This Website shall ensure the security of the user’s digital assets by strengthening technical input and enhancing security precautions, and is under the obligation to notify the user in advance of the foreseeable security risks in the user’s account.',
  'terms.term_5.content_5_8':
    '5.8. This Website shall have the right to, in accordance with the applicable laws, administrative regulations, rules, orders and other regulatory documents of the sovereign country or region where the user is based, request to the user for more information or data, and to take reasonable measures to meet the requirements of the local standards, and the user havs the obligation to provide proper assistance to such measures; this Website shall have the right to suspend or permanently terminate the user’s access to this Website as well as part or all of the services offered by this Website.',
  'terms.term_6.title': '6. Indemnity',
  'terms.term_6.content_6_1':
    '6.1. Under any circumstance, our liability for the user’s direct damage will not exceed the total cost incurred by the user’s three (3) months’ use of services offered by this Website.',
  'terms.term_6.content_6_2':
    "6.2. If the user breaches this Agreement or any applicable law or administrative regulation, the user shall pay to us at least US$ Two million in compensation and bear all the expenses in connection with such breach (including attorney's fees, among others). If such compensation cannot cover the actual loss, the user shall make up for the difference.",
  'terms.term_7.title': '7. The Right to Injunctive Relief',
  'terms.term_7.content_7_0':
    'Both the user and we acknowledge that common law remedies for breach of agreement or possible breach of contract may be insufficient to cover all the losses that we sustain; therefore, in the event of a breach of contract or a possible breach of contract, the non-breaching party shall have the right to seek injunctive relief as well as all other remedies that are permitted under common law or equity.',
  'terms.term_8.title': '8. Limitation and Exemption of Liability',
  'terms.term_8.content_8_1':
    '8.1. Users understand and agree that under no circumstance will NVX be held liable for any of the following events:',
  'terms.term_8.content_8_1_1': '8.1.1. Loss of income',
  'terms.term_8.content_8_1_2': '8.1.2. Loss of transaction profits or contractual losses',
  'terms.term_8.content_8_1_3': '8.1.3. Disruption of the business',
  'terms.term_8.content_8_1_4': '8.1.4. Loss of expected currency losses',
  'terms.term_8.content_8_1_5': '8.1.5. Loss of information',
  'terms.term_8.content_8_1_6': '8.1.6. Loss of opportunity, damage to goodwill or reputation',
  'terms.term_8.content_8_1_7': '8.1.7. Damage or loss of data',
  'terms.term_8.content_8_1_8': '8.1.8. Cost of purchasing alternative products or services',
  'terms.term_8.content_8_1_9':
    '8.1.9. Any indirect, special or incidental loss or damage arising from any infringement (including negligence), breach of contract or any other cause, regardless of whether or not such loss or damage may reasonably be foreseen by us, and regardless of whether or not we are notified in advance of the possibility of such loss or damage.',
  'terms.term_8.content_8_1_10': '8.1.10. Items 8.1.1 to 8.1.9 are independent of each other.',
  'terms.term_8.content_8_2':
    '8.2. The user understands and agrees that NVX shall not be held liable for any damages caused by any of the following events:',
  'terms.term_8.content_8_2_1':
    '8.2.1. Where we are properly justified in believing that the user’s specific transactions may involve any serious violation or breach of law or agreement',
  'terms.term_8.content_8_2_2':
    '8.2.2. Where we are properly justified in believing that the user conduct on this Website is suspected of being illegal or improper',
  'terms.term_8.content_8_2_3':
    '8.2.3. The expenses and losses arising from the purchase or acquisition of any data, information or transaction, etc. through the services offered by this Website',
  'terms.term_8.content_8_2_4':
    '8.2.4. The user’s misunderstanding of the Services offered by this Website',
  'terms.term_8.content_8_2_5':
    '8.2.5. Any other losses related to the services provided by this Website, which cannot be attributed to us.',
  'terms.term_8.content_8_3':
    '8.3. Where NVX fail to provide the Services or delay in providing such Services due to information network equipment maintenance, information network connectivity failures, errors in computer, communications or other systems, power failures, weather conditions, unexpected accidents, industrial actions, labor disputes, revolts, uprisings, riots, lack of productivity or production materials, fires, floods, storms, explosions, wars, failure on the part of banks or other partners, collapse of the digital assets market, actions by government, judicial or administrative authorities, other acts that are not within NVX’s control or beyond our inability to control, or due to causes on the part of third parties, NVX shall not assume any responsibility for such failure to provide service or delay in providing services, or for the resultant loss the user may sustain as a result of such failure or delay.',
  'terms.term_8.content_8_4':
    '8.4. NVX cannot guarantee that all the information, programs, texts, etc. contained in this Website are completely safe, free from the interference and destruction by any malicious programs such as viruses, Trojans, etc., therefore, the user’s log-in to this Website or use of any services offered by this Website, download of any program, information and data from this Website and the user’s use thereof are the user’s personal decisions and therefore the user shall bear the any and all risks and losses that may possibly arise.',
  'terms.term_8.content_8_5':
    '8.5. NVX does not make any warranties and commitments in connection with any of the information, products and business of any third party websites linked to this Website, as well as any other forms of content that do not belong to NVX; the user’s use any of the services, information, and products provided by a third party website is the user’s personal decision and therefore the user shall assume any and all the responsibilities arising therefrom.',
  'terms.term_8.content_8_6':
    '8.6. NVX does not make any explicit or implicit warranties regarding the user’s use of the Services offered by this Website, including but not limited to the applicability, freedom from error or omission, consistency, accuracy, reliability, and applicability to a specific purpose, of the services provided by this Website. Furthermore, NVX does not make any commitment or guarantee in connection with the validity, accuracy, correctness, reliability, quality, stability, integrity and timeliness of the technology and information covered by the services offered by this Website. Whether to log in this Website or use the services provided by this Website is the user’s personal decision and therefore the user shall bear all the risks and possible losses arising from such decision. NVX does not make any explicit or implicit warranties in connection with the market, value and price of digital asset; the user understands and acknowledge that the digital assets market is unstable, that the price and value of assets may fluctuate or collapse at any time, and that the transaction of digital assets is based on the user’s personal free will and decision and therefore the user shall assume any and all risks and losses that may possible arise therefrom.',
  'terms.term_8.content_8_7':
    "8.7. The guarantees and undertakings specified in this Agreement shall be the only guarantee and statements that NVX make in connection with the Services provided by us under this Agreement and through this Website, and shall supersede any and all the warranties and commitments arising in any other way and manner, whether in writing or in words, express or implied. All these guarantees and statements represent only NVX’s own commitments and undertakings and do not guarantee any third party's compliance with the guarantees and commitments contained in this Agreement.",
  'terms.term_8.content_8_8':
    '8.8. NVX does not waive any of the rights not mentioned in this Agreement and to the maximum extent permitted by the applicable law, to limit, exempt or offset our liability for damages.',
  'terms.term_8.content_8_9':
    '8.9. Upon the user’s registration of its account with this Website, it shall be deemed that the user approves any and all operations performed by NVX in accordance with the rules set forth in this Agreement, and any and all risks arising from such operations shall be assumed by the user.',
  'terms.term_9.title': '9. Termination of this Agreement',
  'terms.term_9.content_9_1':
    '9.1. This Website shall have the right to cancel the user’s account with this Website in accordance with this Agreement, and this Agreement shall be terminated on the date of the cancellation of the user’s account.',
  'terms.term_9.content_9_2':
    '9.2. This Website shall have the right to terminate all Service offered by this Website to the user in accordance with this Agreement, and this Agreement shall terminate on the date of termination of all services offered by this Website to the user.',
  'terms.term_9.content_9_3':
    '9.3. After the termination of this Agreement, the user do not have the right to require this Website to continue to provide the user with any service or perform any other obligation, including, but not limited to, requesting this Website to keep or disclose to the user any information in the user’s former original account, or to forward to the user or any third party any information therein that is not read or sent.',
  'terms.term_9.content_9_4':
    '9.4. The termination of this Agreement shall not prevent the observant party from demanding the breaching party to assume other liabilities.',
  'terms.term_10.title': '10. Intellectual Property',
  'terms.term_10.content_10_1':
    '10.1. All intellectual achievements included in this Website, including, but not limited to, website logos, databases, website design, text and graphics, software, photos, videos, music, sounds and any combinations of the aforementioned files, and the intellectual property rights of software compilation, associated source code and software (including small applications and scripts) shall be owned by this Website. You may not copy, modify, copy, transmit or use any of the foregoing materials or content for commercial purposes.',
  'terms.term_10.content_10_2':
    '10.2. All rights contained in the name of this Website (including but not limited to business goodwill and trademarks, logos) shall be owned by NVX.',
  'terms.term_10.content_10_3':
    '10.3. Upon accepting this Agreement, it shall be deemed that the user, on the basis of the user’s own free will, have transferred and assigned exclusively and free of charge to this Website all copyright of any form of information that the user publishes on this Website, including, but not limited to copyrights, distribution rights, lease rights, exhibition rights, performance rights, projection rights, broadcasting rights, information network dissemination rights, shooting rights, adaptation rights, translation rights, compilation rights and other transferable rights that copyright owners are entitled to, and this Website shall have the right to sue for any infringement on such copyright and obtain full compensation for such infringement. This Agreement shall apply to any content that is published by the user on this Website and is protected by copyright law, regardless of whether the content is generated before or after the signing of this Agreement.',
  'terms.term_10.content_10_4':
    '10.4. The user shall not illegally use or dispose of the intellectual property rights of this Website or any other person during the user’s use of the services offered by this Website. For any information that the user publishes on this Website, the user may not publish or authorize other websites (or media) to use such information in any manner whatsoever.',
  'terms.term_10.content_10_5':
    '10.5. The user’s log into this Website or use of any of the services offered by this Website shall not be seemed as our transfer of any intellectual property to the user.',
  'terms.term_11.title': '11. Others',
  'terms.term_11.content_11_1': '11.1. Severability',
  'terms.term_11.content_11_1_1':
    'If any provision of this Agreement is found unenforceable, invalid or illegal by any court of competent jurisdiction, validity of the remaining provisions of this Agreement shall not be affected.',
  'terms.term_11.content_11_2': '11.2. No Agency',
  'terms.term_11.content_11_2_1':
    'Nothing in this Agreement shall be deemed to have created, implied or otherwise treated NVX as the user’s agent, trustee or other representative, unless it is provided otherwise in this Agreement.',
  'terms.term_11.content_11_3': '11.3. Waiver',
  'terms.term_11.content_11_3_1':
    'NVX or the user’s waiver of the right to hold the other party liable for breaches of agreement or any other liability as is agreed upon in this Agreement shall not be construed or deemed as a waiver of the right to hold the other party for other breaches of contract; a failure to exercise any right or remedy shall not be construed in any way as a waiver of such right or remedy.',
  'terms.term_11.content_11_4': '11.4. Headings',
  'terms.term_11.content_11_4_1':
    'All headings herein are exclusively for the convenience of wording and are not intended to to expand or limit the content or scope of the terms and conditions of this Agreement.',
  'terms.term_12.title': '12. Prohibited activities',
  'terms.term_12.content_12_1':
    '12.1. Registered users should not attempt doing the following items when using this service:',
  'terms.term_12.content_12_1_1':
    '1. Acts that infringe on intellectual property rights and portrait rights of the Company or other users of this service or other third parties, including acts that cause such infringements directly or indirectly.',
  'terms.term_12.content_12_1_2':
    '2. Using the Company’s intellectual property rights and portrait rights including copyright without our prior consent for commercial use or reprinting to a third party.',
  'terms.term_12.content_12_1_3':
    '3. Acts that Infringing on, including acts that directly or indirectly cause such infringement, privacy rights, honors, other rights or interests of our company, our affiliated companies and other persons affiliated to our company, other users of our company’s service and other third parties.',
  'terms.term_12.content_12_1_4':
    '4. Acts s of fraud, opening and soliciting pyramiding, purchasing illegal goods and services, transferring income by crime or use of services based on it.',
  'terms.term_12.content_12_1_5': '5. Acts related to crime or contrary to public policy.',
  'terms.term_12.content_12_1_6': '6. Acts of sending information about dating.',
  'terms.term_12.content_12_1_7':
    '7. Solicitation act against other registered users such as advertisement distribution, except the Company allows.',
  'terms.term_12.content_12_1_8':
    '8. Acts that violate laws and internal rules of the industry group to which the Company or registered user belongs.',
  'terms.term_12.content_12_1_9':
    '9. Actions that fall under or attempt to double transfer virtual currency.',
  'terms.term_12.content_12_1_10':
    '10. Acts that transmit information including computer viruses and other harmful computer programs, or that destroy or interfere with the functions, systems, servers, networks and other functions managed by the Company, or that unnecessarily place excessive burdens.',
  'terms.term_12.content_12_1_11':
    '11. Acts of using errors, bugs, security holes, and other defects of the system, server, network etc. that is related to or managed by the Company.',
  'terms.term_12.content_12_1_12': '12. Acts of falsifying information available for this service.',
  'terms.term_12.content_12_1_13':
    '13. Acts of transmitting data exceeding a certain amount of data determined by the Company.',
  'terms.term_12.content_12_1_14':
    '14. Acts that may interfere with the operation of the Service by the Company.',
  'terms.term_12.content_12_1_15':
    '15. The act of deliberately stealing the assets of registered users, the Company or the affiliated companies through replay attacks, etc.',
  'terms.term_12.content_12_1_16':
    '16. Dissemination of things that differ from theories and facts, and acts that damaging our credibility by using counterfeiting or power, etc.',
  'terms.term_12.content_12_1_17': '17. The act of the same person creating multiple accounts.',
  'terms.term_12.content_12_1_18':
    '18. The act of multiple persons using one account or allowing a third party other than the registered user to use the account.',
  'terms.term_12.content_12_1_19':
    '19. An act of opening or attempting to open an account in the name of another person, including a hypothetical person, or an act of providing false information to the Company for all or part of the registration information related to the account.',
  'terms.term_12.content_12_1_20':
    '20. Acts of using information, etc., obtained through the use of the service and providing the same service as the service to third parties, including, but not limited to, virtual currency transaction, transferring or settlement of virtual currencies, and distribution of price information, etc., or providing similar services by themselves, or using them for commercial purposes such as providing services or goods to third parties, etc., or to have a subsidiary or other third party under their control to act the same, except for the case where prior written consent has been obtained from the Company.',
  'terms.term_12.content_12_1_21':
    '21. Acts that manipulate market prices on exchanges or acts that hinder fair price formation.',
  'terms.term_12.content_12_1_22':
    '22. An act of accepting money to a registered user account from a third party or paying out money to a third party other than the registered user, however, unless explicitly permitted beforehand by the Company. However, unless explicitly permitted by the Company in advance.',
  'terms.term_12.content_12_1_23': '23. Other acts that the Company deems inappropriate.',
  'terms.term_12.content_12_2':
    '12.2. If the Company determines that a registered user in the Service falls under any of the items in the preceding paragraph or is likely to fall under this service, the Company may take measures, without notify the registered user in advance, at the discretion of the Company such as Deletion of all or part of the information sent by the registered user, cancellation of registration of the registered user or suspension of use, or confiscation of virtual currency etc., held by the registered user: if exchanged to other currencies, equivalent amount calculated based on the exchange rate established by the Company will be confiscated. At that time, the Company shall not be obligated to return the documents received from registered users and shall not be liable for any damages caused to registered users by measures taken by the Company based on this section.',
  'terms.term_12.content_12_3':
    '12.3. If the user does any of the items in paragraph 1 above, the Company may charge registered users a penalty set by the Company.',
  'terms.term_13.title': '13. Entry into Force and Interpretation of the Agreement',
  'terms.term_13.content_13_1':
    '13.1. This Agreement shall enter into force when the user clicks through the registration page of this Website, complete the registration procedures, obtain the user’s account number and password of this Website, and shall be binding on the user and this Website.',
  'terms.term_13.content_13_2':
    '13.2. The ultimate power of interpretation of this Agreement shall be vested in this Website.',
  'terms.term_13.content_13_2_0_1': 'Important reminder:',
  'terms.term_13.content_13_2_0_2': 'NVX hereby reminds the user that:',
  'terms.term_13.content_13_2_1':
    '1. The digital assets themselves are not offered by any financial institution, corporation or this Website. The digital assets market is new and unconfirmed, and will not necessarily expand',
  'terms.term_13.content_13_2_2':
    '2.  Digital assets transactions are highly risky, due to the fact that they are traded throughout 24-hour a day without limits on the rise or fall in price',
  'terms.term_13.content_13_2_3':
    '3. Digital assets transactions may be suspended or prohibited at any time due to the enactment or modification of national laws, regulations and regulatory documents.',
  'terms.term_13.content_13_2_4':
    '4. NVX does not serve customers in the following countries/regions: USA, Cuba, Iran, North Korea, Crimea, Sudan, Syria, Bangladesh, Bolivia, Ecuador, and Kyrgyzstan.',
  'terms.term_14.title': '14. DIGISIGN Electronic Certificate and Electronic Signature',
  'terms.term_14.content_14_1':
    'You will use the Digisign Electronic Certificate and Electronic Signature to sign electronic documents with PT Aset Kripto Internasional. PT Aset Kripto Internasional works with PT Solusi Net Internusa (Digisign.id), the Indonesian Electronic Signature and Certification Authority Operator accredited by the Indonesian Ministry of Communication and Informatics.',
  'terms.term_14.content_14_2':
    'You agree to register as a Digisign.id platform user and have an electronic certificate issued by PT Solusi Net Internusa (Digisign.id) on your behalf for the purpose of using the Electronic Signature service on the Digisign.id platform to sign electronic documents.',
  'terms.term_14.content_14_3':
    'If your personal data is verified to be in accordance with the requirements, PT Solusi Net Internusa (Digisign.id), the Indonesian Certification Authority Operator accredited by the Indonesian Ministry of Communication and Informatics, will issue an electronic certificate as proof that your personal data has been verified and is in accordance with the data recorded in the system of the authority that issued the identity.',
  'terms.term_14.content_14_4':
    'Therefore, you guarantee the accuracy of the personal data you provide and agree to the processing of your personal data for the purpose of issuing an electronic certificate and other services from PT Solusi Net Internusa (Digisign.id) that are attached to the electronic certificate.',
  'terms.term_14.content_14_5':
    'You agree to release PT Solusi Net Internusa (Digisign.id) from liability for compensation, claims, or lawsuits for the results of the electronic certificate issued if the data provided is invalid or incorrect.',
  'terms.term_14.content_14_6':
    'You declare clearly and without any coercion, that you will grant power to PT Aset Kripto Internasional to forward your ID card data, selfie, phone number, and email address as registration data to PT Solusi Net Internusa (Digisign.id) to fulfill the provisions of the Legislation, namely Government Regulation Number 71 of 2019 concerning Electronic System and Transaction Providers, and Ministry of Information and Communication Regulation Number 11 of 2018.',
  'terms.term_14.content_14_7':
    'With this, you have read, understood, and agreed to be bound by the terms and conditions of the Electronic Certificate Operator service contained in the Electronic Certificate Ownership Agreement (Subscriber Agreement), PSrE Privacy Policy, and Digisign.id Certification Practice Statement which can be accessed through <a href="https://repository.digisign.id/">https://repository.digisign.id/</a>.',
  'service_center.send_contact_subject': 'Send contact to {APP_NAME}',
  'shared_components.google_authentication_modal.lost_code': 'Lost Your Google Authentication?',
  'shared_components.google_authentication_modal.otp_code_invalid':
    'Google Authentication code is invalid.',
  'shared_components.google_authentication_modal.submit_button': 'Submit',
  'shared_components.google_authentication_modal.title': 'Google Authentication',
  'masterdata.text': 'Masterdata is changed.</br>Please reload page again.',
  'unregistered_session.login': 'Login',
  'unregistered_session.text':
    '<span>Your current session is terminated.<br />Please log in again!</span>',
  invalid_bank_account: 'Bank account information is incorrect. Please try again',
  'validation.messages._default': 'The {0} value is not valid.',
  'validation.messages.after': 'The {0} must be after {1}.',
  'validation.messages.after2': 'The {0} must be after or equal to {1}.',
  'validation.messages.alpha_dash':
    'The {0} field may contain alpha-numeric characters as well as dashes and underscores.',
  'validation.messages.alpha_num': 'The {0} field may only contain alpha-numeric characters.',
  'validation.messages.alpha_spaces':
    'The {0} field may only contain alphabetic characters as well as spaces.',
  'validation.messages.alpha': 'The {0} field may only contain alphabetic characters.',
  'validation.messages.before': 'The {0} must be before {1}.',
  'validation.messages.before2': 'The {0} must be before or equal to {1}.',
  'validation.messages.between': 'The {0} field must be between {1} and {2}.',
  'validation.messages.confirmed': 'The {0} confirmation does not match.',
  'validation.messages.credit_card': 'The {0} field is invalid.',
  'validation.messages.date_between': 'The {0} must be between {1} and {2}.',
  'validation.messages.date_format': 'The {0} must be in the format {1}.',
  'validation.messages.decimal':
    'The {0} field must be numeric and may contain {1} decimal points.',
  'validation.messages.digits': 'The {0} field must be numeric and exactly contain {1} digits.',
  'validation.messages.dimensions': 'The {0} field must be {1} pixels by {2} pixels.',
  'validation.messages.email': 'The {0} field must be a valid email.',
  'validation.messages.ext': 'The {0} field must be a valid file.',
  'validation.messages.image': 'The {0} field must be an image.',
  'validation.messages.in': 'The {0} field must be a valid value.',
  'validation.messages.integer': 'The {0} field must be an integer.',
  'validation.messages.ip': 'The {0} field must be a valid ip address.',
  'validation.messages.length': 'The {0} length must be {1}.',
  'validation.messages.length2': 'The {0} length be between {1} and {2}.',
  'validation.messages.max': 'The {0} field may not be greater than {1} characters.',
  'validation.messages.max_value': 'The {0} field must be {1} or less.',
  'validation.messages.mimes': 'The {0} field must have a valid file type.',
  'validation.messages.min': 'The {0} field must be at least {1} characters.',
  'validation.messages.min_value': 'The {0} field must be {1} or more.',
  'validation.messages.not_in': 'The {0} field must be a valid value.',
  'validation.messages.numeric': 'The {0} field may only contain numeric characters.',
  'validation.messages.regex': 'The {0} field format is invalid.',
  'validation.messages.required': 'The {0} field is required.',
  'validation.messages.size': 'The {0} size must be less than {1}.',
  'validation.messages.url': 'The {0} field is not a valid URL.',
  'validation.accepted': 'The {attribute} must be accepted.',
  'validation.active_url': 'The {attribute} is not a valid URL.',
  'validation.after': 'The {attribute} must be a date after {date}.',
  'validation.after_or_equal': 'The {attribute} must be a date after or equal to {date}.',
  'validation.alpha': 'The {attribute} may only contain letters.',
  'validation.alpha_dash': 'The {attribute} may only contain letters, numbers, and dashes.',
  'validation.alpha_num': 'The {attribute} may only contain letters and numbers.',
  'validation.array': 'The {attribute} must be an array.',
  'validation.attributes.account_name': 'account name',
  'validation.attributes.account_number': 'account number',
  'validation.attributes.bank_id': 'bank',
  'validation.attributes.base_price': 'Stop Price',
  'validation.attributes.date_of_birth': 'birthday',
  'validation.attributes.new_password': 'new password',
  'validation.attributes.otp_recovery_code': 'authentication code',
  'validation.attributes.recovery_code': 'recovery code',
  'validation.attributes.password': 'password',
  'validation.attributes.quantity': 'Amount',
  'validation.attributes.wallet_address': 'wallet address',
  'validation.attributes.withdrawal_address': 'withdrawal address',
  'validation.attributes.authentication_code': 'authentication code',
  'validation.before': 'The {attribute} must be a date before {date}.',
  'validation.before_or_equal': 'The {attribute} must be a date before or equal to {date}.',
  'validation.between.array': 'The {attribute} must have between {min} and {max} items.',
  'validation.between.file': 'The {attribute} must be between {min} and {max} kilobytes.',
  'validation.between.numeric': 'The {attribute} must be between {min} and {max}.',
  'validation.between.string': 'The {attribute} must be between {min} and {max} characters.',
  'validation.boolean': 'The {attribute} field must be true or false.',
  'validation.confirmed': 'The confirmation {attribute} does not match.',
  'validation.correct_otp': 'The Google Authentication Code is incorrect.',
  'validation.custom.agree_term.required': 'You must read and agree with Term of Use.',
  'validation.custom.attribute-name.rule-name': 'custom-message',
  'validation.custom.base_price.min': 'Stop price must be at least {min}',
  'validation.custom.base_price.precision': 'Stop price must have the precision of {precision}',
  'validation.custom.id_back.image': 'File must be an image.',
  'validation.custom.id_back.mimes': 'File must be a file of type: {values}.',
  'validation.custom.id_front.image': 'File must be an image.',
  'validation.custom.id_front.mimes': 'File must be a file of type: {values}.',
  'validation.custom.id_selfie.image': 'File must be an image.',
  'validation.custom.id_selfie.mimes': 'File must be a file of type: {values}.',
  'validation.custom.image': 'File must be an image.',
  'validation.custom.image.mimes': 'File must be a file of type: {values}.',
  'validation.custom.password.confirmed': 'Passwords must match the password prompt.',
  'validation.custom.password.correct_password': 'The password is incorrect',
  'validation.custom.password.min':
    'Passwords must contain at least 8 characters, capital letters, lowercase letters, numbers, no space and must match the duplicate password box..',
  'validation.custom.password.regex':
    'Passwords must contain at least 8 characters, capital letters, lowercase letters, numbers, no space and must match the duplicate password box..',
  'validation.custom.new_password.min':
    'Passwords must contain at least 8 characters, capital letters, lowercase letters, numbers, no space and must match the duplicate password box..',
  'validation.custom.new_password.regex':
    'Passwords must contain at least 8 characters, capital letters, lowercase letters, numbers, no space and must match the duplicate password box..',
  'validation.custom.new_password.new_password_confirm':
    'Passwords must contain at least 8 characters, capital letters, lowercase letters, numbers, no space and must match the duplicate password box..',
  'validation.custom.old_password.old_password_confirm': 'The old password field is required.',
  'validation.custom.referral_id': 'Referral ID does not exist',
  'validation.custom.price.min': 'Price must be at least {min}',
  'validation.custom.price.precision': 'Price must have the precision of {precision}',
  'validation.custom.quantity.min': 'Amount must be at least {min}',
  'validation.custom.quantity.precision': 'Amount must have the precision of {precision}',
  'validation.custom.total.min': 'Minimum transaction amount is at least {min}',
  'validation.custom.total.precision': 'Total must have the precision of {precision}',
  'validation.date': 'The {attribute} is not a valid date.',
  'validation.date_format': 'The {attribute} does not match the format {format}.',
  'validation.different': 'The {attribute} and {other} must be different.',
  'validation.digits': 'The {attribute} must be {digits} digits.',
  'validation.digits_between': 'The {attribute} must be between {min} and {max} digits.',
  'validation.dimensions': 'The {attribute} has invalid image dimensions.',
  'validation.distinct': 'The {attribute} field has a duplicate value.',
  'validation.email': 'The {attribute} field must be a valid email.',
  'validation.exists': 'The selected {attribute} is invalid.',
  'validation.file': 'The {attribute} must be a file.',
  'validation.filled': 'The {attribute} field must have a value.',
  'validation.image': 'The {attribute} must be an image.',
  'validation.in': 'The selected {attribute} is invalid.',
  'validation.in_array': 'The {attribute} field does not exist in {other}.',
  'validation.integer': 'The {attribute} must be an integer.',
  'validation.ip': 'The {attribute} must be a valid IP address.',
  'validation.ipv4': 'The {attribute} must be a valid IPv4 address.',
  'validation.ipv6': 'The {attribute} must be a valid IPv6 address.',
  'validation.is_withdrawal_address': 'The {attribute} is invalid.',
  'validation.json': 'The {attribute} must be a valid JSON string.',
  'validation.max.array': 'The {attribute} may not have more than {max} items.',
  'validation.max.file': 'The {attribute} may not be greater than {max} kilobytes.',
  'validation.max.numeric': 'The {attribute} may not be greater than {max}.',
  'validation.max.string': 'The {attribute} may not be greater than {max} characters.',
  'validation.mimes': 'The {attribute} must be a file of type: {values}.',
  'validation.mimetypes': 'The {attribute} must be a file of type: {values}.',
  'validation.min.array': 'The {attribute} must have at least {min} items.',
  'validation.min.file': 'The {attribute} must be at least {min} kilobytes.',
  'validation.min.numeric': 'The {attribute} must be at least {min}.',
  'validation.min.string': 'The {attribute} must be at least {min} characters.',
  'validation.min_value': '{attribute} must be at least {min}.',
  'validation.not_in': 'The selected {attribute} is invalid.',
  'validation.numeric': 'The {attribute} must be a number.',
  'validation.otp': 'Authentication failed.',
  'validation.otp_not_used': 'Please wait for next verification code to generate.',
  'validation.otp_or_google_auth_required': 'You need to enter Google Authentication Code',
  'validation.password_white_space':
    'Passwords must contain at least 8 characters, capital letters, lowercase letters, numbers, no space and must match the duplicate password box.',
  'validation.attribute_white_space':
    'Passwords must contain at least 8 characters, capital letters, lowercase letters, numbers, no space and must match the duplicate password box.',
  'validation.precision': '{attribute} must have the precision of {precision}',
  'validation.present': 'The {attribute} field must be present.',
  'validation.regex': 'The {attribute} format is invalid.',
  'validation.required': 'The {attribute} field is required.',
  'validation.required_if': 'The {attribute} field is required when {other} is {value}.',
  'validation.required_unless': 'The {attribute} field is required unless {other} is in {values}.',
  'validation.required_with': 'The {attribute} field is required when {values} is present.',
  'validation.required_with_all': 'The {attribute} field is required when {values} is present.',
  'validation.required_without': 'The {attribute} field is required when {values} is not present.',
  'validation.required_without_all':
    'The {attribute} field is required when none of {values} are present.',
  'validation.same': 'The {attribute} and {other} must match.',
  'validation.size.array': 'The {attribute} must contain {size} items.',
  'validation.size.file': 'The {attribute} must be {size} kilobytes.',
  'validation.size.numeric': 'The {attribute} must be {size}.',
  'validation.size.string': 'The {attribute} must be {size} characters.',
  'validation.string': 'The {attribute} must be a string.',
  'validation.timezone': 'The {attribute} must be a valid zone.',
  'validation.unique': 'The {attribute} has already been taken.',
  'validation.unique_email': 'The email has already been taken.',
  'validation.user_id_coin_wallet': 'The address has already been existed.',
  'validation.user_id_coin_wallet_2': 'The address has already been existed.',
  'validation.unique_referrer_code': 'The referral code is invalid.',
  'validation.unique_withdrawal_address_in_user':
    'The withdrawal address has already been existed.',
  'validation.uploaded': 'The {attribute} failed to upload.',
  'validation.url': 'The {attribute} format is invalid.',
  'validation.auth.email_taken': 'The email has already been taken.',
  'validation.auth.phone_no_format': 'Phone number is invalid.',
  'validation.verified_email': 'Please verify your email to active account.',
  'validation.verify_otp_or_google_auth':
    'You need to enter Google Authentication Code field exactly!',
  'validation.verify_otp_recovery_code': 'The {attribute} is incorrect.',
  'validation.verify_otp_recovery_code_with_auth': 'The {attribute} is incorrect.',
  'validation.authen_code_required': 'The Google Authentication Code field is required.',
  'validation.authen_code_length': 'The Google Authentication Code must be 6 digits.',
  'validation.otp_incorrect': 'The Google Authentication Code is incorrect.',
  'validation.otp.gg_code_incorrect': 'The Google Authentication Code is incorrect.',
  'validation.otp.gg_code_not_used': 'Please wait for next verification code to generate.',
  'validation.identity.success': 'Update Successful.',
  'validation.identity.update_success': 'Update successful.',
  'withdraw_guide.currency': 'Currency',
  'withdraw_guide.deposit_fee': 'Deposit fee',
  'withdraw_guide.email': 'Email verification',
  'withdraw_guide.fee': 'Fee',
  'withdraw_guide.fee_withdrawal': 'Withdrawal fee',
  'withdraw_guide.free': 'Free',
  'withdraw_guide.gotp': 'Google OTP',
  'withdraw_guide.gauthen': 'Google authentication',
  'withdraw_guide.partner': 'Partner (Contact us)',
  'withdraw_guide.contract_admin': 'Contact admin',
  'withdraw_guide.level': 'Step break',
  'withdraw_guide.level_name': 'Level ',
  'withdraw_guide.level_title': 'Current certification level is',
  'withdraw_guide.limit_tip1':
    'The withdrawal limit is different depending on the individual authentication level.',
  'withdraw_guide.limit_tip2':
    'Depending on the market situation and the regulations of the relevant bank (bank), the above withdrawal limit may be changed without prior notice.',
  'withdraw_guide.limit_tip3':
    'If we suspect fraudulent transactions, withdrawals may be restricted.',
  'withdraw_guide.limit_tip4':
    'The withdrawal fee will be charged at a fixed amount, regardless of the amount of the currency.',
  'withdraw_guide.limit_tip5':
    'In the case of a crypto-currency withdrawal, the actual fee may vary or be higher or lower than the displayed fee depending on the status of the block chain network.',
  'withdraw_guide.limit_title1': '1. Limit of withdrawal by authentication step',
  'withdraw_guide.limit_title2': '2. Deposit and withdrawal fee and minimum withdrawal amount',
  'withdraw_guide.minimum_withdrawal': 'Minimum withdrawal amount',
  'withdraw_guide.one_day': '1 day',
  'withdraw_guide.one_time': '1 time',
  'withdraw_guide.kyc': 'KYC verification',
  'withdraw_guide.verf_doc': 'Verified document',
  'withdraw_guide.security_method': 'Authentication method',
  'withdraw_guide.support_withdrawal': 'Deposit and withdrawal instructions',
  'withdraw_guide.usd': 'usd',
  'trade_type.buy': 'Buy',
  'trade_type.sell': 'Sell',
  'common.message.network_error': 'Cannot connect to server.',
  'common.status.success': 'Success',
  'common.status.pending': 'Pending',
  'common.status.cancel': 'Canceled',
  'common.status.expired': 'Expired',
  'common.status.new': 'New',
  'common.status.error': 'Error',
  'common.status.error_on_update': 'Error on updating data',
  'common.status.untriggered': 'Untriggered',
  'common.search': 'Search',
  'common.action.confirm': 'Confirm',
  'common.action.reject': 'reject',
  'common.action.approve': 'approve',
  'common.action.execute_im': 'Execute immediately',
  'common.kyc.pending': 'Pending',
  'common.kyc.verified': 'Verified',
  'common.empty_data': 'Data empty.',
  'menu.orders.orders': 'Orders',
  'menu.orders.open_order': 'Open Order',
  'menu.orders.order_history': 'Order History',
  'menu.orders.trade_history': 'Trade History',
  'menu.notices': 'Notices',
  'menu.email_marketing': 'Email Marketing',
  'menu.home': 'Home',
  'usd_transaction.time': 'Time',
  'usd_transaction.user': 'Email',
  'usd_transaction.account': 'Account',
  'usd_transaction.bank_name': 'Bank',
  'usd_transaction.bank_branch': 'Branch',
  'usd_transaction.status': 'Status',
  'usd_transaction.amount': 'Amount',
  'kyc.list': 'List KYC',
  'kyc.input_search': 'Search',
  'kyc.created_at': 'Time',
  'kyc.email': 'Email',
  'kyc.name': 'Full Name',
  'kyc.country': 'Country',
  'kyc.id_number': 'National ID',
  'kyc.id_number_wna': 'KITAS/KITAP Number (Niora)',
  'kyc.status': 'Status',
  'kyc.gender': 'Gender',
  'kyc.id_front': 'Identity Card Front Side Cover',
  'kyc.id_front_wna': 'KITAS/KITAP Card Frond Side Cover',
  'kyc.id_back': 'Identity Card Back Side Data page',
  'kyc.id_back_wna': 'ITAS/KITAP Card Back Side Data page',
  'kyc.id_selfie': 'Selfie With Photo ID And Note',
  'kyc.btn_detail': 'Detail',
  'kyc.btn_verify': 'Verify',
  'kyc.btn_reject': 'Reject',
  'kyc.notice_verify_confirm': 'Do you want to confirm this KYC?',
  'kyc.notice_reject_confirm': 'Do you want to reject this KYC?',
  'kyc.detail_kyc': 'Detail KYC',
  'kyc.msg_verified': "KYC is approved by the administrator. You can't change the information.",
  'kyc.description':
    "You must use your real identity using this verification process. All personal information will be securely protected. <a href='#'>National ID must be used.</a>",
  'kyc.notice_under16': 'Notice:',
  'kyc.notice_under17': 'Users must be at least 17 years old to finish the KYC process',
  'kyc.notice_indonesian':
    '<strong>Notice:</strong><ul><li>Your documents will be submitted to Digisign for automatic verification</li><li>Your phone number must be Indonesian number. If you are using another country’s phone number, please contact us for further support</li></ul>',
  'kyc.reason': 'Reason:',
  'kyc.note_rejected':
    'Your documents have been rejected by Digisign due to some incorrect information. Please kindly update and submit again',
  'kyc.note_pending':
    'We have sent you an email for Digisign account activation step. Please kindly check your email and follow the instruction',
  'kyc.note_resend_email': 'Resend Email',
  'kyc.notice': 'Notice:',
  'user.id': 'ID',
  'user.user': 'Email',
  'user.user_information': 'User information',
  'user.date': 'Date',
  'user.email': 'Email',
  'user.fee_level': 'Fee Level',
  'user.security_level': 'Security Level',
  'user.max_security_level': 'Max Security Level',
  'user.referrer_code': 'Referral Code',
  'user.referrer_mail': 'Referrer Mail',
  'user.type': 'Type',
  'user.status': 'Status',
  'user.normal': 'normal',
  'user.bot': 'bot',
  'user.inactive': 'inactive',
  'user.active': 'active',
  'user.save': 'Save',
  'user.edit': 'Edit',
  'user.device': 'Device',
  'user.recent_activity': 'Recent Activity',
  'device_management.title_device_management': 'Device Management',
  'user.succes': 'Updated successfully!',
  'user.user_setting': 'User Setting',
  'notice.edit_title_page': 'Update Notices',
  'notice.new_title_page': 'Create Notices',
  'notice.title': 'Title',
  'notice.date': 'Date',
  'notice.search': 'Search',
  'notice.reset': 'Reset',
  'notice.new': 'New',
  'notice.support': 'Link Support',
  'notice.start': 'Started',
  'notice.end': 'Ended',
  'notice.edit': 'Edit',
  'notice.delete': 'Delete',
  'notice.start_at': 'Start at',
  'notice.end_at': 'End at',
  'notice.banner': 'Banner',
  'notice.submit': 'Submit',
  'notice.cancel': 'Cancel',
  'notice.confirm': 'Confirm',
  'notice.search_by_title': 'Search by title.....',
  'notice.choose_file': 'Choose File',
  'notice.delete_data': 'Do you want to delete this notice?',
  'notice.submit_data': 'Do you want to submit this notice?',
  'notice.errors.required': 'This field is required.',
  'notice.errors.maxsize': 'Size image must be less than 10MB.',
  'notice.errors.date': 'End date must be more than start date',
  'notice.noticeEdit': 'Edit notice',
  'orders.open_order.open_order': 'Open Order',
  'orders.open_order.date': 'Date',
  'orders.open_order.pair': 'Pair',
  'orders.open_order.search_by_email': 'Search by email',
  'orders.open_order.search': 'Search',
  'orders.open_order.reset': 'Reset',
  'orders.open_order.time': 'Time',
  'orders.open_order.trade_id': 'Trade Id',
  'orders.open_order.order_id': 'Order Id',
  'orders.open_order.email': 'Email',
  'orders.open_order.price': 'Price',
  'orders.open_order.filled': 'Filled',
  'orders.open_order.fee': 'Fee',
  'orders.open_order.total': 'Total',
  'orders.open_order.type': 'Type',
  'orders.open_order.exec_type': 'Exec Type',
  'orders.open_order.action': 'Action',
  'orders.open_order.amount': 'Amount',
  'orders.open_order.trigger_condition': 'Trigger condition',
  'orders.open_order.status': 'Status',
  'orders.open_order.average': 'Average',
  'orders.open_order.side': 'Side',
  'orders.open_order.buy': 'Buy',
  'orders.open_order.sell': 'Sell',
  'orders.open_order.all': 'All',
  'orders.open_order.trade_history': 'Trade History',
  'orders.open_order.index': 'No.',
  'orders.open_order.view_detail': 'View Detail',
  'orders.open_order.market': 'Market',
  'orders.order_history.order_history': 'Order History',
  'orders.order_history.date': 'Date',
  'orders.order_history.pair': 'Pair',
  'orders.order_history.search_by_email': 'Search by email',
  'orders.order_history.search': 'Search',
  'orders.order_history.reset': 'Reset',
  'orders.order_history.time': 'Time',
  'orders.order_history.trade_id': 'Trade Id',
  'orders.order_history.order_id': 'Order Id',
  'orders.order_history.email': 'Email',
  'orders.order_history.price': 'Price',
  'orders.order_history.filled': 'Filled',
  'orders.order_history.fee': 'Fee',
  'orders.order_history.total': 'Total',
  'orders.order_history.type': 'Type',
  'orders.order_history.action': 'Action',
  'orders.order_history.amount': 'Amount',
  'orders.order_history.trigger_condition': 'Trigger condition',
  'orders.order_history.status': 'Status',
  'orders.order_history.average': 'Average',
  'orders.order_history.side': 'Side',
  'orders.order_history.buy': 'Buy',
  'orders.order_history.sell': 'Sell',
  'orders.order_history.all': 'All',
  'orders.order_history.hide_all_canceled': 'Hide All Canceled',
  'orders.order_history.trade_history': 'Trade History',
  'orders.order_history.index': 'No.',
  'orders.order_history.view_detail': 'Detail',
  'orders.order_history.market': 'Market',
  'orders.trade_history.trade_history': 'Trade History',
  'orders.trade_history.date': 'Date',
  'orders.trade_history.pair': 'Pair',
  'orders.trade_history.search_by_email': 'Search by email',
  'orders.trade_history.search': 'Search',
  'orders.trade_history.reset': 'Reset',
  'orders.trade_history.time': 'Time',
  'orders.trade_history.trade_id': 'Trade Id',
  'orders.trade_history.original_buy_order_id': 'Original Buy Order Id',
  'orders.trade_history.buyer_email': 'Buyer Email',
  'orders.trade_history.original_sell_order_id': 'Original Sell Order Id',
  'orders.trade_history.seller_email': 'Seller email',
  'orders.trade_history.price': 'Price',
  'orders.trade_history.filled': 'Filled',
  'orders.trade_history.buy_fee': 'Buy Fee',
  'orders.trade_history.sell_fee': 'Sell Fee',
  'orders.trade_history.total': 'Total',
  'orders.trade_history.market': 'Market',
  'orders.trade_history.all': 'All',
  'email.new_title_page': 'Email Marketing Create',
  'email.new': 'Create Email Marketing',
  'email.title': 'Title',
  'email.content': 'Content',
  'email.submit_data': 'Do you really want ?',
  'email.delete_data': 'Do you want delete ?',
  'email.send_all': 'Send all User',
  'email.list_user': 'Send list User',
  'withdraw_fee_setting.title': 'Withdraw fee setting',
  'withdraw_fee_setting.daily_limit': 'Daily Limit',
  'withdraw_setting.change_confirm': 'Do you want to change withdraw fee?',
  'withdraw_fee_setting.disable_confirm': 'Do you want to disable withdraw?',
  'withdraw_fee_setting.disable_all_confirm': 'Do you want to disable all withdraw?',
  'withdraw_fee_setting.enable_confirm': 'Do you want to enable withdraw?',
  'withdraw_fee_setting.enable_all_confirm': 'Do you want to enable withdraw?',
  'withdraw_fee_setting.disable_deposit_confirm': 'Do you want to disable deposit?',
  'withdraw_fee_setting.disable_all_deposit_confirm': 'Do you want to disable all deposit?',
  'withdraw_fee_setting.enable_deposit_confirm': 'Do you want to enable deposit?',
  'withdraw_fee_setting.enable_all_deposit_confirm': 'Do you want to enable all deposit?',
  'withdraw_fee_setting.update_success_msg': 'Updated successfully!',
  'withdraw_fee_setting.disable_success_msg': 'Disabled successfully!',
  'withdraw_fee_setting.disable_all_success_msg': 'Disabled all successfully!',
  'withdraw_fee_setting.enable_success_msg': 'Enabled successfully!',
  'withdraw_fee_setting.enable_all_success_msg': 'Enabled all successfully!',
  'withdraw_fee_setting.withdraw_setting': 'Withdrawal Setting',
  'salespoint.amal': '4IRE',
  'salespoint.balances': 'Balances',
  'salespoint.bonus': 'Bonus',
  'salespoint.buy': 'Buy',
  'salespoint.buy_fail': 'Your order failed!',
  'salespoint.buy_history': 'Buy History',
  'salespoint.buy_history.amount': 'Amount',
  'salespoint.buy_history.amal': '4IRE',
  'salespoint.buy_history.bonus': 'Bonus Token',
  'salespoint.buy_history.currency': 'Currency',
  'salespoint.buy_history.time': 'Time',
  'salespoint.buy_history.cash_back': 'Referral Fee',
  'salespoint.buy_history.email': 'Email',
  'salespoint.enter_amount': 'Enter your amount',
  'salespoint.export_complete_buy_history': 'Export Complete 4IRE History',
  'salespoint.export_complete_buy_history_tooltip': 'Only Export Complete Buy History on 6 months',
  'salespoint.login_or_register':
    '<a href="{login}">Login</a> or <a href="{register}">Register</a> to trade',
  'salespoint.total_remaining_supply': 'Total 4IRE remaining supply',
  'salespoint.buy_success': 'Your order has been completed!',
  'des.des_spot_leaderboard':
    'Leaderboard trading volume ranking only show data in date range from {start} to {end}',
  'title.Spot_Exchange_Leaderboard': 'Spot Exchange Leaderboard',
  'leaderboard.top_25_traders_by_vol': 'Top 25 Traders by Volume',
  'table.trading_volume': 'Trading Volume',
  'table.email': 'Email',
  'menu.leader_board': 'Leaderboard',
  'title.settlement': 'Settlement',
  'title.then': 'then',
  'title.or': 'or',
  'trading_fee_setting.fee_maker': 'Fee Maker',
  'trading_fee_setting.fee_taker': 'Fee Taker',
  'trading_fee_setting.coin_hodling': '{COIN_HOLDING} Holding',
  'trading_fee_setting.msg_coin_holding': 'Setting coin holding must be increased by each level.',
  'trading_fee_setting.id': 'ID',
  'trading_fee_setting.trading_fee_setting': 'FEE',
  'trading_fee_setting.trading_feeSetting': 'Trading Fee Setting',
  'trading_fee_setting.trading_withdrawFeeSetting': 'Withdrawal Fee Setting',
  'trading_fee_setting.update_confirm': 'Do you want to change trading fee?',
  'trading_fee_setting.update_success_msg': 'Updated successfully!',
  'button.done': 'Done',
  'button.reset': 'Reset',
  'user_setting.coin': 'Coin',
  'user_setting.blockchain_address': 'Blockchain Address',
  'user_setting.balance': 'Balance',
  'user_setting.available_balance': 'Wallet Balance',
  'user_setting.confirm_off_otp': 'Do you want to disable OTP?',
  'user_setting.confirm_on_otp': 'Do you want to enable OTP?',
  'user_setting.update_otp_msg': 'Update setting OTP successfully!',
  'user_setting.confirm_disable_account': 'Do you want to disable account?',
  'user_setting.disable_account_success_msg': 'Disable account successfully?',
  'user_setting.confirm_enable_account': 'Do you want to enable account?',
  'user_setting.enable_account_success_msg': 'Enable account successfully?',
  'login.admin': 'Login Admin',
  'login.email': 'E-Mail Address',
  'login.email2': 'Email',
  'user.referrer': 'referrer',
  'user.otp': 'OTP',
  'email.errors.required': 'You need sent all Users or list users',
  'email.email_title': 'This is title',
  'email.email_content': 'This is content',
  'email.fail': 'Fail',
  'exchange_balance.available_balance': 'Available balances',
  'exchange_balance.exchange_balance': 'Exchange balance',
  'exchange_fee.level': 'Level',
  'withdraw_setting.disable_all': 'Disable all',
  'withdraw_setting.enable_all': 'Enable all',
  'withdraw_setting.enable': 'Enable',
  'withdraw_setting.disable': 'Disable',
  'withdraw_fee_setting.id': 'ID',
  'withdraw_fee_setting.currency': 'Currency',
  'withdraw_fee_setting.limit': 'Limit',
  'withdraw_fee_setting.fee': 'Fee',
  'withdraw_fee_setting.fee_open_close': 'Fee Open/Close',
  'withdraw_fee_setting.is_visible': 'Is Visible',
  'withdraw_fee_setting.minium_withdrawal': 'Minium Withdrawal',
  'withdraw_setting.id': 'ID',
  'withdraw_setting.enable_confirm': 'Do you want to enable withdraw?',
  'withdraw_setting.enable_all_confirm': 'Do you want to enable withdraw?',
  'withdraw_setting.disable_deposit_confirm': 'Do you want to disable deposit?',
  'withdraw_setting.disable_all_deposit_confirm': 'Do you want to disable all deposit?',
  'withdraw_setting.enable_deposit_confirm': 'Do you want to enable deposit?',
  'withdraw_setting.enable_all_deposit_confirm': 'Do you want to enable all deposit?',
  'withdraw_setting.name': 'Name',
  'withdraw_setting.deposit': 'Deposit',
  'withdraw_setting.withdraw': 'Withdraw',
  'withdraw_setting.update_success_msg': 'Updated successfully!',
  'withdraw_setting.disable_success_msg': 'Disabled successfully!',
  'withdraw_setting.disable_all_success_msg': 'Disabled all successfully!',
  'withdraw_setting.enable_success_msg': 'Enabled successfully!',
  'withdraw_setting.enable_all_success_msg': 'Enabled all successfully!',
  'withdraw_setting.withdraw_setting': 'Withdraw setting',
  'withdraw_setting.disable_confirm': 'Do you want to disable withdraw?',
  'withdraw_setting.disable_all_confirm': 'Do you want to disable all withdraw?',
  'user.login_history': 'History',
  menu_bottom: {
    withdrawals_guide: 'Withdrawals Guide',
    terms_of_service: 'Terms of service',
    api_document: 'API document',
    leverage_reference: 'Leverage Reference',
    contact_us: 'Contact Us',
    faq: 'FAQ',
    faqs: 'FAQs',
    white_paper: 'White Paper',
    guidelines: 'Guidelines',
    recruitment_plan: 'Recruitment Plan',
    support: 'Support',
    cooperative_partner: 'Cooperative Partner',
    invitation_reward: 'Invitation Reward',
    help_center: 'Help Center',
    company: 'Company',
    install_app: 'Install App',
  },
  'title.settlement_history_table': 'Settlement History Table',
  'title.des.this_list_contains': 'This list contains all historical settlements.',
  'search.placeholder.click_values_below_to_fillter': 'Click values below to fillter',
  'button.clear': 'Clear',
  'button.save_as_CSV': 'Save as CSV',
  'table.timestamp': 'Timestamp',
  'table.symbol': 'Symbol',
  'table.instrument_symbol': 'Instrument Symbol',
  'table.exec_type': 'Exec Type',
  'table.exec_type.buy': 'Buy',
  'table.exec_type.sell': 'Sell',
  'table.side.buy': 'Buy',
  'table.side.sell': 'Sell',
  'table.text': 'Text',
  'table.note': 'Note',
  'table.order_id': 'Order ID',
  'table.settlement_type': 'Settlement Type',
  'table.settled_price': 'Settled Price',
  'table.option_strike_price': 'Option Strike Price',
  'title.daily_insurance_fund_balance': 'Daily Insurance Fund Balance',
  'title.des.daily_insurance_fund_balance':
    'NVX uses an Insurance Fund to avoid Auto-Deleveraging in traders’ positions. The fund is used to aggress unfilled liquidation orders before they are taken over by the auto-deleveraging system.<br/> The Insurance Fund grows from liquidations that were able to be executed in the market at a price better than the bankruptcy price of that particular position.<br/>You can view the current and historical balances of the Insurance Fund below.',
  'table.wallet_balance': 'Wallet Balance',
  'title.des.settlement_history_table': 'This list contains all historical settlements.',
  'title.Leaderboard.title': 'Leaderboard',
  'table.profit': 'Profit',
  'table.volume': 'Volume',
  'leaderboard.top_25_traders_by_notional': 'Top 25 Traders by Notional',
  'leaderboard.top_25_traders_by_roe': 'Top 25 Traders by ROE',
  'leaderboard.top_25_teams_by_roe_entry': 'Top 25 Teams by ROE (Entry)',
  'leaderboard.top_25_traders_by_volume': 'Top 25 Traders by Volume',
  'title.Leaderboard.des_title':
    'By default, we show your account in the leaderboard, but the name is not identifiable. Changes here may take up to a half hour to take effect.',
  'leaderboard.show_real_username': 'Do you want show fake username?',
  'leaderboard.show_fake_username': 'Do you want show real username?',
  'leaderboard.off_to_show_fake_username': 'Off to show your real username in the leaderboard.',
  'leaderboard.your_fake_name_is': 'Your fake name is',
  'leaderboard.on_to_show_real_username': 'On to show your fake username in the leaderboard.',
  'table.rofit': 'Rofit',
  'table.rank': 'Rank',
  'table.is_real_name': 'Is Real Name',
  'table.interest_base': 'Interest Base',
  'table.interest_quote': 'Interest Quote',
  'table.premium': 'Premium',
  'title.overview': 'Overview',
  'title.interest_rate_component': 'Interest Rate Component',
  'title.premium_discount_component': 'Premium / Discount Component',
  'title.more_information': 'More Information',
  'title.bitcoin_series_xbt_guide': 'Bitcoin Series (BTC) Guide',
  'title.view_btc_guide': 'View the BTC series guide',
  'title.symbol_index_historical_values': '{symbol} Index Historical Values',
  'title.contract_specification': 'Contract Specification',
  'title.symbol_index_detail': '{symbol} Index Details',
  'title.description': 'Description',
  'title.longs': 'longs',
  'title.shorts': 'shorts',
  'title.price_index': 'Price Index',
  'title.eth.price_index.desc':
    'Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature.<br/>Discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.',
  'title.marking_and_settlement': 'Marking and Settlement',
  'title.full_contract_details': 'Full Contract Details',
  'common.current_interval': 'Current Interval',
  'common.commissions_history': 'Commissions History',
  'common.commissions_setting': 'Commissions Setting',
  'common.withdraw_history': 'Withdraw History',
  'menu.commissions': 'Commissions',
  'common.high_watermark': 'High Watermark',
  'common.the_base_currency_is_symbol': 'The base currency is {symbol}',
  'common.the_quote_currency_is_symbol': 'The quote currency is {symbol}',
  'common.perfomance_fee': 'Performance Fee',
  'common.management_fee': 'Management Fee',
  'common.flat_fee': 'Flat Fee',
  'common.curious_formula': 'I = (Q - B) / T<br/>F = P + Clamp(I - P, -0.05%, 0.05%)',
  'common.curious_result':
    'I = (0.06% - 0.03%) / 3<br/>F = 1.4240% + Clamp(0.0100% - 1.4240%, -0.05%, 0.05%)',
  'common.view_contract_series_guide': 'View the {root_symbol} series guide',
  'common.ticker_root': 'Ticker Root',
  'common.expiry': 'Expiry',
  trezor_address_for_deposit: 'Trezor address for deposit',
  trezor_device: 'Trezor devices',
  'trezor_device.created_at': 'Created at',
  'trezor_device.device_id': 'Device ID',
  'trezor_device.device_name': 'Device Name',
  'trezor_device.disable': 'Disable',
  'trezor_device.enable': 'Enable',
  'trezor_device.model': 'Model',
  'trezor_device.register_new_device': 'Register new device',
  'trezor_device.title': 'Trezor devices',
  trezor_device_information: 'Trezor Device Information',
  trezor_label: 'TREZOR label',
  trezor_path_for_deposit: 'Trezor path for deposit',
  trezor_wallet: 'Trezor Wallet',
  'trezor_wallet.address': 'Address',
  'trezor_wallet.balance': 'Balance',
  'trezor_wallet.currency': 'Currency',
  'trezor_wallet.details': 'Details',
  'trezor_wallet.device': 'Device',
  'trezor_wallet.device_name': 'Device Name',
  'trezor_wallet.path': 'Path',
  'trezor_wallet.summary': 'Summary',
  'trezor_wallet.title': 'Trezor Wallet',
  'trezor_wallet.total_wallet': 'TOTAL WALLET',
  'trezor.landing.title':
    'Your transaction becomes social support and we especially will focus on this.',
  'trezor.landing.desc':
    'As a SDGs support company, we use a part of the profits for social support. By using NVX you can help establishing schools or save hunger.',
  'trezor.landing.title1': 'Advanced API',
  'trezor.landing.desc1':
    'Our trading engine was built using the same technology used by investment banks and hedge funds.',
  datatable_empty: 'There is no data available in the table.',
  datatable_search_empty: 'There are no results that match your search.',
  'wallet.create_receive_address.currency': 'Currency',
  'wallet.create_receive_address.currency_list': 'Currency List',
  'wallet.create_receive_address.generate_address': 'Create Address',
  'wallet.create_receive_address.the_number_of_remaining': 'The Number of Remaining',
  'wallet.create_receive_address.title': 'Create receive address',
  'wallet.create_receive_address.total_number': 'Total Number',
  receive_address_creation: 'receive address creation',
  'receive_address_creation.author': 'Author',
  'receive_address_creation.input_contents_to_be_created': 'Input contents to be created',
  'receive_address_creation.number_created': 'Number created',
  'receive_address_creation.please_do_not_use_trezor_for_transmission':
    'Please do not use TREZOR for transmission',
  'receive_address_creation.start_creating': 'Start Creating',
  'receive_address_creation.title': 'receive address creation',
  'receive_address_creation.trezor_label': 'TREZOR label',
  user_wallet: 'User wallet',
  'user_wallet.address': 'Address',
  'user_wallet.available_balance': 'Wallet Balance',
  'user_wallet.balance': 'Balance',
  'user_wallet.currency': 'Currency',
  'user_wallet.details': 'Details',
  'user_wallet.device_name': 'Device Name',
  'user_wallet.path': 'Path',
  'user_wallet.summary': 'Summary',
  'user_wallet.title': 'User wallet',
  'user_wallet.total_wallet': 'TOTAL WALLET',
  'user_wallet.user_name': 'User Name',
  'coin_information.btn_back': 'Back',
  'coin_information.btn_choose_file': 'Choose file',
  'coin_information.btn_next': 'Next',
  'coin_information.contact_address': 'Contract Address',
  'coin_information.decimals': 'Decimals',
  'coin_information.explorer': 'Explorer',
  'coin_information.heading_title': 'Register new ERC20',
  'coin_information.icon': 'Icon',
  'coin_information.image_note': 'The image must be a file of type: png',
  'coin_information.required_confirmations': 'Required confirmations',
  'coin_information.tab_title': 'Token Information',
  'coin_information.token': 'Token',
  'coin_information.token_name': 'Token name',
  'coin_information.type': 'Type',
  'validation.is_contract_address': 'The contract address is invalid.',
  'trading_information.btn_back': 'Back',
  'trading_information.btn_delete': 'Delete',
  'trading_information.btn_edit': 'Edit',
  'trading_information.btn_next': 'Next',
  'trading_information.btn_register_trading_pair': 'Register trading pair',
  'trading_information.delete_msg': 'Success',
  'trading_information.fee': 'Fee',
  'trading_information.heading_title': 'Register new ERC20',
  'trading_information.market_price': 'Market Price',
  'trading_information.minimum_amount': 'Minimum Amount',
  'trading_information.minimum_total': 'Minimum Total',
  'trading_information.pair': 'Pair',
  'trading_information.trading_information': 'Trading Information',
  'withdrawal_information.back': 'Back',
  'withdrawal_information.currency': 'Currency',
  'withdrawal_information.day': 'day',
  'withdrawal_information.days': 'days',
  'withdrawal_information.fee': 'Fee',
  'withdrawal_information.heading_title': 'Register new ERC20',
  'withdrawal_information.limit': 'Limit',
  'withdrawal_information.minimum_withdrawal': 'Minimum withdrawal',
  'withdrawal_information.next': 'Next',
  'withdrawal_information.tab_title': 'Withdrawal Information',
  'withdrawal_information.time_reset': 'Time reset',
  'review_register_erc_2_0.*_check_register_erc20_information':
    '* Check register erc20 information',
  'review_register_erc_2_0.amount_limit': 'Amount Limit',
  'review_register_erc_2_0.btn_back': 'Back',
  'review_register_erc_2_0.btn_register': 'register',
  'review_register_erc_2_0.contract_address': 'Contract Address',
  'review_register_erc_2_0.currency': 'Currency',
  'review_register_erc_2_0.days': 'days',
  'review_register_erc_2_0.decimals': 'Decimals',
  'review_register_erc_2_0.error_msg': 'Have errors during setup. Please register again!',
  'review_register_erc_2_0.explorer': 'Explorer',
  'review_register_erc_2_0.fee': 'Fee',
  'review_register_erc_2_0.icon': 'Icon',
  'review_register_erc_2_0.limit': 'Limit',
  'review_register_erc_2_0.market_price': 'Market Price',
  'review_register_erc_2_0.minimum_amount': 'Minimum Amount',
  'review_register_erc_2_0.minimum_total': 'Minimum Total',
  'review_register_erc_2_0.minimum_withdrawal': 'Minimum Withdrawal',
  'review_register_erc_2_0.pair': 'Pair',
  'review_register_erc_2_0.precision': 'Precision',
  'review_register_erc_2_0.register_new_erc20': 'Register new ERC20',
  'review_register_erc_2_0.required_confirmations': 'Required confirmations',
  'review_register_erc_2_0.time_reset': 'Time Reset',
  'review_register_erc_2_0.token': 'Token',
  'review_register_erc_2_0.token_information': 'Token Information',
  'review_register_erc_2_0.token_name': 'Token name',
  'review_register_erc_2_0.total_limit': 'Total Limit',
  'review_register_erc_2_0.trading_fee': 'Trading Fee',
  'review_register_erc_2_0.trading_limit': 'Trading limit',
  'review_register_erc_2_0.trading_pair': 'Trading Pair',
  'review_register_erc_2_0.type': 'Type',
  'review_register_erc_2_0.withdrawal_information': 'Withdrawal Information',
  'review_register_erc_2_0.success_msg': 'Register successfully!',
  'erc20_loading_modal.create_account_message': 'Create Accounts ....',
  'erc20_loading_modal.create_migration_message': 'Create Migration ....',
  'erc20_loading_modal.done_message': 'Done ....',
  'erc20_loading_modal.loading_message': 'Loading....',
  'erc20_loading_modal.set_market_price_message': 'Set Market Prices ....',
  'erc20_loading_modal.update_masterdata_message': 'Update Masterdata ....',
  eth_wallet_information: 'ETH WALLET INFORMATION',
  'funds.transaction_history.no_deposit_data': 'You have no data',
  'funds.transaction_history.no_withdraw_data': 'You have no data',
  'barstyle_chart.save_chart': 'Save Chart',
  'barstyle_chart.load_chart': 'Load Chart',
  'barstyle_chart.bars': 'Bars',
  'barstyle_chart.candles': 'Candles',
  'barstyle_chart.hollow_candles': 'Hollow Candles',
  'barstyle_chart.heikin_ashi': 'Heikin Ashi',
  'barstyle_chart.line': 'Line',
  'barstyle_chart.area': 'Area',
  'approved_order.btn_search': 'Search...',
  'modal_trading_pair_information.btn_submit': 'Submit',
  _validation: {
    precision: 'The {attribute} must have the precision of {precision}.',
    accepted: 'The {attribute} must be accepted.',
    active_url: 'The {attribute} is not a valid URL.',
    after: 'The {attribute} must be a date after {date}.',
    after_or_equal: 'The {attribute} must be a date after or equal to {date}.',
    alpha: 'The {attribute} may only contain letters.',
    alpha_dash: 'The {attribute} may only contain letters, numbers, dashes and underscores.',
    alpha_num: 'The {attribute} may only contain letters and numbers.',
    array: 'The {attribute} must be an array.',
    before: 'The {attribute} must be a date before {date}.',
    before_or_equal: 'The {attribute} must be a date before or equal to {date}.',
    between: {
      numeric: 'The {attribute} must be between {min} and {max}.',
      file: 'The {attribute} must be between {min} and {max} kilobytes.',
      string: 'The {attribute} must be between {min} and {max} characters.',
      array: 'The {attribute} must have between {min} and {max} items.',
    },
    boolean: 'The {attribute} field must be true or false.',
    confirmed: 'The {attribute} confirmation does not match.',
    date: 'The {attribute} is not a valid date.',
    date_format: 'The {attribute} does not match the format {format}.',
    different: 'The {attribute} and {other} must be different.',
    digits: 'The {attribute} must be {digits} digits.',
    digits_between: 'The {attribute} must be between {min} and {max} digits.',
    dimensions: 'The {attribute} has invalid image dimensions.',
    distinct: 'The {attribute} field has a duplicate value.',
    email: 'The {attribute} field must be a valid email.',
    exists: 'The selected {attribute} is invalid.',
    file: 'The {attribute} must be a file.',
    filled: 'The {attribute} field must have a value.',
    gt: {
      numeric: 'The {attribute} must be greater than {value}.',
      file: 'The {attribute} must be greater than {value} kilobytes.',
      string: 'The {attribute} must be greater than {value} characters.',
      array: 'The {attribute} must have more than {value} items.',
    },
    gte: {
      numeric: 'The {attribute} must be greater than or equal {value}.',
      file: 'The {attribute} must be greater than or equal {value} kilobytes.',
      string: 'The {attribute} must be greater than or equal {value} characters.',
      array: 'The {attribute} must have {value} items or more.',
    },
    image: 'The {attribute} must be an image.',
    in: 'The selected {attribute} is invalid.',
    in_array: 'The {attribute} field does not exist in {other}.',
    integer: 'The {attribute} must be an integer.',
    ip: 'The {attribute} must be a valid IP address.',
    ipv4: 'The {attribute} must be a valid IPv4 address.',
    ipv6: 'The {attribute} must be a valid IPv6 address.',
    json: 'The {attribute} must be a valid JSON string.',
    lt: {
      numeric: 'The {attribute} must be less than {value}.',
      file: 'The {attribute} must be less than {value} kilobytes.',
      string: 'The {attribute} must be less than {value} characters.',
      array: 'The {attribute} must have less than {value} items.',
    },
    lte: {
      numeric: 'The {attribute} must be less than or equal {value}.',
      file: 'The {attribute} must be less than or equal {value} kilobytes.',
      string: 'The {attribute} must be less than or equal {value} characters.',
      array: 'The {attribute} must not have more than {value} items.',
    },
    max: {
      numeric: 'The {attribute} may not be greater than {max}.',
      file: 'The {attribute} may not be greater than {max} kilobytes.',
      string: 'The {attribute} may not be greater than {max} characters.',
      array: 'The {attribute} may not have more than {max} items.',
    },
    mimes: 'The {attribute} must be a file of type: {values}.',
    mimetypes: 'The {attribute} must be a file of type: {values}.',
    min: {
      numeric: 'The {attribute} must be at least {min}.',
      file: 'The {attribute} must be at least {min} kilobytes.',
      string: 'The {attribute} must be at least {min} characters.',
      array: 'The {attribute} must have at least {min} items.',
    },
    base_price: {
      min: 'Stop price must be at least {min}',
      precision: 'Stop price must have the precision of {precision}',
    },
    quantity: {
      min: 'Amount must be at least {min}',
      precision: 'Amount must have the precision of {precision}',
    },
    total: {
      min: 'Minimum transaction amount is at least {min}',
      precision: 'Total must have the precision of {precision}',
    },
    price: {
      min: 'Price must be at least {min}',
      precision: 'Price must have the precision of {precision}',
    },
    google_authenticator_code: {
      length: 'The google authentication code length must be 6',
    },
    tel: {
      numeric: 'Please enter a number',
    },
    zip_code: {
      numeric: 'Please enter a number',
    },
  },
  emails: {
    kyc_notification: {
      subject: '【{app_name}】KYC verification - {date}',
      name: 'Dear Mr./Ms. {name}',
      text1: 'Thank you for using our services.',
      text2: 'It is bitCastle support.',
      text3: 'Thank you for waiting.',
      text4:
        'Since the verification of identity confirmation has done, so please log in to user page from here and check the result of the examination.',
      text5:
        'If the message implying that you need to submit an identification document is not listed at the top of my page, verification of your identity has been successfully completed.',
      text6:
        'If the message is on the top of my page, it is not approved due to inappropriate submission or image error, so please chech the identity confirmation document once again and resubmit it.',
      text7:
        'We can not tell you why the submitted documents were inadequate, but reasons for becoming inadequate mainly will be below, so please confirm whether the photo you submitted once is not applicable to the following.',
      text8: 'Personal identification document is not specified',
      text9: 'Your face on the identification document and ID selfie photo is different',
      text10:
        'Documents submitted to ID SERPHY, Exchange name, date, the person himself is not shown together',
      text11: 'Not facing the front',
      text12: '"Whole face" is not shown on the selfie photo (the entire head is not shown)',
      text13: 'China and the USA residence',
      text14: 'The image is dark and the face is not clear',
      text15: 'Image upload failed',
      text16: "I'm sorry for troubling. We appreciate your cooperation.",
    },
    approved_notification: {
      subject: '【{app_name}】Approved Withdraw - {date}',
      name: 'Hello {name}',
      text1: 'The following withdrawal request was approved.',
      text2: 'Currency',
      text3: 'Quantity',
      text4: 'Destination address',
      text5: 'Requested date',
      text6: 'We will move to withdrawal processing from this.',
      text7: 'Thank you for your continued support of bitCastle.',
      text8: '',
      text9: '',
      text10: '',
      text11: '',
      text12: '',
    },
  },
  excel: {
    account_registrant: {
      no: 'No',
      file_name: 'Account registrant',
      already: 'Already',
      not_yet: 'Not yet',
      mail_address: 'Email',
      mail_auth: 'Authentication',
      registered_information: 'Registered Information',
      registered_date: 'Registered Date',
      last_login: 'Last Login',
    },
    registered_person: {
      no: 'No',
      file_name: 'Registered Person',
      mail_address: 'Email',
      name: 'Name',
      tel: 'Telephone',
      country: 'Country',
      upload_at: 'Upload Date',
      last_login: 'Last Login',
    },
    id_registrant: {
      no: 'No',
      file_name: 'KYC registrant',
      country_name: 'Country',
      name: 'Name',
      tel: 'Telephone',
      mail_address: 'Email',
      created_at: 'Created Date',
      updated_at: 'Upload Date',
      last_login: 'Last Login',
    },
    payment_history: {
      file_name: 'Payment History',
      id: 'Transaction ID',
      user_name: 'User Name',
      currency: 'Currency',
      amount: 'Amount',
      created_at: 'Created Date',
    },
  },
  _auth: {
    failed: 'You entered a wrong User ID or password.',
    throttle: 'Too many login attempts. Please try again in {seconds} seconds.',
    ip_verify: 'Please check your email to allow this IP Address to access your account.',
  },
  _pagination: {
    previous: '« Previous',
    next: 'Next »',
  },
  _passwords: {
    password: 'Passwords must be at least six characters and match the confirmation.',
    reset: 'Your password has been reset!',
    sent: 'We have e-mailed your password reset link!',
    token: 'This password reset token is invalid.',
    user: "We can't find a user with that e-mail address.",
  },
  roe_num_ranking_one: '1st',
  roe_num_ranking_two: '2nd',
  roe_num_ranking_three: '3rd',
  roe_num_ranking_four: '4th〜10th',
  roe_num_ranking_rate: '{btc} each',
  roe_person_info: '{num} | {num} or more | {num} BTC',
  roe_activity_tit: 'NVX leverage trading ROE competition',
  roe_activity_subtit:
    'NVX is about to host a ROE contest,everyone can participate as long as you do leverage trading!<br />During this period, top ROE (return on equity) users of the competition will be shown on the trading dashboard in real time.',
  roe_activity_competition_content: 'ROE competition content：',
  roe_activity_competition_content_desc:
    'Users who trade with an open contract and have a higher ROE will get prize after the competition. Users can enter the competition by themselves. The prize amount varies depending on the total number of participant.',
  roe_activity_compute2: 'Margin = Entry Price ÷ Leverage',
  roe_act_time_range: 'From {fromTime} to {toTime} / UT（UTC）{allDay} weeks',
  roe_activity_quil: 'Requirements of competition：',
  roe_activity_quil_money: 'Hold margin above {money} BTC',
  roe_acti_table: 'Number of participants and total prize amount：',
  roe_acti_table_person: 'The number of participants',
  roe_acti_table_money: 'Total prize amount',
  roe_acti_rule: 'Prize distribution rules：',
  roe_acti_rule_ranking: 'Ranking',
  roe_acti_rule_rate: 'Bonus ratio',
  roe_acti_rule_reward: 'Reward',
  roe_act_money_time:
    'Prize distribution time:<br />After the competition, the number of participants and fair or unfair will be confirmed within {day} days. Then distribution within {day_m} days to the ranked winner.',
  roe_act_money_time_table:
    'Example：When the number of participants is {person} and the total prize become {btcNum} BTC',
  roe_act_desc_tit: 'Ｃompetition supplementary note：',
  roe_act_desc_list1:
    'After the competition, if you withdraw margin or do not trade during the competition, you might be disqualified.',
  roe_act_desc_list2:
    'After the competition, the number of participants and ranking will be determined and announced within {day} days.',
  roe_act_desc_list3:
    'We reserve the right to disqualify any trading deemed to be a wash sale trading,illegally registered account, self-trading or market manipulation trading.',
  roe_act_desc_list4:
    'We reserve the right to cancel or modify the competition or competition rules.',
  roe_act_desc_cancel: 'Disqualification conditions: ',
  roe_act_desc_cancel_list1:
    ' If you hold a position during the competition, but the margin is less than {btc} BTC before the competition: the qualification will be cancelled. (still can re-enter if you are qualified)',
  roe_act_desc_cancel_list2:
    ' If you hold a position crosses the start date of the competition, it will be counted after the settlement. If the margin drops below {btc} BTC at the time of settlement, the qualification will be cancelled.',
  roe_act_desc_cancel_list3:
    '  If you hold a position crosses the start date of the competition, the qualification will be cancelled.',
  roe_act_desc_cancel_list4:
    ' If there is no trading during the competition, the qualification will be cancelled.',
  roe_act_about_money_tit: 'About Bonus margin :',
  roe_act_about_money_list: [
    'The bonus is 100% of the fund and will goes into margin balance when you applying for participation. (no limit)',
    'The bonus can be used as a margin together with the margin balance.',
    ' Not transferable / withdrawal',
    'If the fund was be transferred or less than the margin balance, the bonus will disappear.',
    'Transaction fees and fund handling fees can be deducted from the margin balance.',
    'The loss of your trading is deducted from the margin',
    'If there is only bonus, it cannot be traded. (you can add margin balance to trade and the bonus can also be used as a deposit)',
    'Execution loss cutting within the margin balance.',
    'Bonus disappears at the end of the game. ',
    'If you hold a position that using the bonus at the end of the game, the bonus will disappear when the position is cancelled. (if you hold the position with the bonus at the next entry, although you can participate, the bonus will not be issued)',
  ],
  roe_act_warn:
    'Warning：Due to digital assets trading involves high risks such as rapid price fluctuations, please make reasonable decisions and invest cautiously within your risk tolerance.',
  roe_competition_conten: [
    {
      tit: 'ROE competition content',
      desc: 'Users who trade with an open contract and have a higher ROE will get prize after the competition. Users can enter the competition by themselves. The prize amount varies depending on the total number of participant.<br />The calculation of ROE contest doesn’t effect on the amount of funds.<br />Example: Users with a margin of 0.05 BTC and users with a margin of 5 BTC are calculated ROE scores fairly.',
    },
    {
      tit: 'Calculation method of ROE',
      desc: 'ROE (whole period) = [(ROE (day 1)+1)*(ROE (day 2)+1)*(ROE (day 3)+1)*... (last until the end date)]-1<br />* Please refer to the daily ROE calculation method below',
    },
    {
      tit: 'ROE calculation method of the day (one day)',
      desc: 'ROE (ROE of the day) = margin balance (end of the day) ÷ margin balance (start of the day) – 1\n            <br />\n            * A day means Japanese time 12:00 to 11:59 of next day.<br />  * For the calculation method of margin balance at the end, please refer to following',
    },
    {
      tit: 'Margin balance calculation method (at the end of the day)',
      desc: 'Margin balance (at the end of the day) = margin balance (at the beginning of the day) + <br />realized profit and loss (at the time of trading on the day) + unrealized profit and loss <br />(at the end of the day)-unrealized profit and loss (at the beginning of the day)',
    },
    {
      tit: ' ROE competition entry period',
      desc: '8/2 01:00 ～ 8/12 08:59（UTC）<br />\n            * You cannot enter the contest out of the entry period. Not allowed to participate on the half way.\n            ',
    },
    {
      tit: ' ROE competition period',
      desc: '8/12 09:00 ～ 8/30 08:59（UTC）',
    },
    {
      tit: 'Requirements of competition',
      desc: 'Hold margin above 0.02 BTC',
    },
  ],
  index_cooperative_partner: 'Cooperative partner',
  index_cooperative_plan: 'Recruitment plan',
  index_buyback_fund: 'Repurchase fund',
  index_insurance: 'Hacker Insurance Fund',
  cooperation_invite: 'Invitation reward',
  profit_ranking_contest_time: {
    banner: 'Coming Soon!!',
    activity: 'You’re not able to entry now.',
    contest: 'There are currently no competition.',
  },
  coin_information_btn_link: 'Coin information',
  profit_ranking_contest_tit: 'Leverage Trading ROE Competition',
  profit_ranking_contest_btnTxt: 'Details ',
  deposit_bonus_tit: 'Deposit <br />Bonus Campaign',
  deposit_bonus_subtit: 'Just deposit during the period <br/>you’ll get bonus',
  deposit_bonus_time: '20 March ~ 26 March',
  'not_found.title': '404 Page Not Found',
  'not_found.text':
    "We can't find the page you're looking for. You can either return to the previous page, visit our homepage or contact our support team.",
  'not_found.landing': 'Back to Landing',
  'footer.copyright': 'All Rights Reserved.',
  'des.des_margin_leaderboard':
    '- The following shows some of the most profitable traders on NVX.<br/>- These lists are anonymous. Each name always represents the same user, but is not personally identifiable.<br/>- Leaderboard trading volume ranking only show data in date range from {start} to {end}',
  'leaderboard.top_25_traders_by_roe_entry': 'Top 25 Traders by ROE (Entry)',
  attributes: {
    email: 'email',
    user_id: 'user ID',
    password: 'password',
    repeat_password: 'repeat password',
    new_password: 'new password',
    confirm_new_password: 'confirm new password',
    agree: 'agree',
    google_authenticator_code: 'google authenticator code',
    recovery_code: 'recovery code',
    confirm_email: 'confirm email',
    blockchain_address: 'blockchain address',
    transaction_explorer: 'transaction explorer',
    minium_quantity: 'minimum quantity',
    minium_amount: 'minimum amount',
    minium_total: 'minimum total',
    total_limit: 'total limit',
    amount_limit: 'amount limit',
    minium_withdrawal: 'minimum withdrawal',
    zip_code: 'zipcode',
    state_region: 'state/region',
    building_room: 'building room',
    coin_name: 'name',
    token_name: 'token name',
    precision: 'precision',
    contract_address: 'contract address',
    coin: 'coin',
    token: 'token',
    icon: 'icon',
    time_reset: 'time reset',
    market_price: 'market price',
    minimum_amount: 'minimum amount',
    minimum_total: 'minimum total',
    minimum_withdrawal: 'minimum withdrawal',
    limit: 'limit',
    fee: 'fee',
    pair: 'pair',
    name: 'name',
    send_confirmer: 'confirmer',
    tx_hash: 'transaction hash',
  },
  roe_act_desc_list: [
    'If there are less than 20 people during the contest, the contest (individual contest) will be suspended, but the team contest will start.',
    'If 20 people are gathered and the competition is held, but because there are no trading or due to withdrawals the number of participants is less than 20. Even during the contest period, the contest will not be held, also no rewards will be distributed.',
    'If you have a position during the entry period and holding position entered the contest, ROE will count from the time after you completely close the position during the contest.',
    'After the competition, if you withdraw margin or do not trade during the competition, you might be disqualified',
    'After the competition, the number of participants and ranking will be determined and announced within 20 days.',
    'We reserve the right to disqualify any trading deemed to be a wash sale trading,illegally registered account, self-trading or market manipulation trading.',
    'We reserve the right to cancel or modify the competition or competition rules.',
  ],
  joinUs_tit: 'Join Us',
  joinUs_subtit: 'TOGETHER WE CREATE, BUILD AND MAKE A DIFFERENCE',
  joinUs_duty: 'Responsibilities and Duties',
  joinUs_sen: 'Qualifications',
  contract_time: 'The 2020 / 03 / 27 leverage transaction of NVX is finally released.',
  contract_explain: 'Here we explain the leveraged transaction.',
  contract_release: 'A contract to be released',
  contract_btc: 'BTC / USD indefinite contract',
  contract_eth: 'Eth / USD indefinite',
  contract_month_6: 'BTC/USD 6 months Futur',
  contract_release_time: 'Release date',
  contract_release_time_txt: '05:00 (UTC) 27th March 2020',
  contract_release_trait_tit: 'Features of NVX leverage deals',
  contract_release_trait_tit_desc: 'NVX leverage deals are unique to NVX.',
  contract_times: 'Maximum leverage up to 100 times.',
  contract_services: 'You can use the platform token $4IRE to pay half a fee.',
  contract_api: 'It supports system trade using API.',
  contract_transition:
    'You can receive a portion of the profit of the exchange by holding more than 10000 amals.',
  contract_explain_tit: 'Explanation of leveraged transactions',
  contract_explain_subtit:
    'The NVX leveraged trading platform is a platform that allows you to trade leveraged goods that are sold as counterfeit coins.',
  contract_explain_li_1:
    'NVX currently handles only bit coins as evidence. All profit and loss calculated by bit coin construction. If you trade a contract with an alto coin, you will also calculate the profit and loss in a bit coin. NVX does not handle legal currency.',
  contract_explain_li_2: 'NVX can leverage up to 100 times.',
  contract_contract_tit: 'NVX agreement',
  contract_contract_subtit_2:
    "By selling these, you can 'contract' long or short. Also, NVX can be said to be a profitable trading platform for the purpose of selling without the fact that it can do a short seller without holding a contract.",
  contract_transition_tit: 'Margin transaction',
  contract_transition_subtit: 'NVX offers up to 100 times leverage in some contracts.',
  contract_transition_li_1:
    'For example, you can buy up to 100 BTC contracts to 1btc proof gold. In addition, high leverage can increase profits, but at the same time the loss may increase.',
  contract_transition_li_2:
    'NVX incorporates the automatic resolution of leverage. This will reduce the leveraged position of interest in a certain period of time when Marquette is unable to execute a clearing order in Marquette order.',
  contract_methods_tit: 'How to start leveraged',
  contract_methods_subtit:
    'NVX allows you to transfer BTC from your main account to leverage account.',
  contract_methods_li_1:
    ' Access the balance of NVX and click BTC to move the BTC from the main account to the leverage balance.',
  contract_methods_li_2: 'Access leveraged exchange and conduct leveraged transactions.',
  recruit: [
    {
      tit: 'Overseas Branding Director',
      duty: [
        "Responsible for the company's overseas market strategic planning implementation, formulate the company's overall market work plan, and propose specific directions and implementation plans for market promotion, branding, public relations, activities, etc.",
        'Build a promotion system and develop market resources, responsible for developing and maintaining resource partners, media and marketing channels',
        "Formulate the company's global public relations strategy and public relations response",
        'Responsible for business development and marketing activities innovation',
        "Track market dynamics and competing products, summarize and analyze the market expansion effect, and provide decision-making basis to the company's senior management.",
      ],
      need: [
        'More than 3-5 years of branding related work experience in the Internet or international financial industry. Experiences in Japan, South Korea, Europe and the United States, blockchain industry, and digital assets are preferred',
        'Bachelor degree or above, English level 6 and above, overseas study or work experience is preferred. Ability to communicate fluently with English native speakers.',
      ],
    },
    {
      tit: 'Risk and Internal Controls Manager',
      duty: [
        'Leading business wide engagement and support for ongoing internal controls. Responsible for risk management in the operation process',
        "Analyze the user's trading behavior, account status, identify risks, and design effective risk management measures",
        'Track, monitor, manage key data indicators, analyze and optimize existing control solutions, and enhance user experience',
        'Evaluate new projects, new users, user transactions and transfers, analyze and identify potential risks and design and implement risk management',
        'Development and delivery of coaching and training materials for each department and regularly report risk information',
        'Further development of Enterprise Risk management process. Complete other related work.',
      ],
      need: [
        'Full-time undergraduate degree or above, major in finance, economics, law, computer, etc.',
        '1-3 years of work experience, financial institution risk management, blockchain industry, digital assets field experience is preferred',
        'Familiar with relevant laws, regulations, and regulatory policies, familiar with digital assets features, operational methods, and risk management tools',
        'Have strong logical thinking ability and analytical ability, good at independent thinking and thoughtful thinking',
        'Responsible, good at communication and teamwork, full of work enthusiasm, and strong project promotion ability.',
      ],
    },
    {
      tit: 'Operations Specialist (English, Korean, Japanese, Russian, Vietnamese)',
      duty: [
        "Develop community-level goals and plans, and be responsible for building and managing users' overseas communities (including but not limited to Facebook, BitcoinTalk, Twitter, Telegram, Medium, etc.) to serve and achieve users management and operations purpose",
        'Collect user needs and promote product, technology, and operational improvements',
        'Solve problems for users.',
      ],
      need: [
        'Bachelor degree or above; Overseas study or work experience is preferred',
        '1-3 years of experience in operating the Internet community; having more than 2 years of work experience in mainstream financial media or blockchain, or digital assets vertical media is preferred',
        'Familiar with overseas social media platforms such as Facebook, twitter, telegram, and medium',
        'Have a certain understanding of the blockchain industry',
        'Expertise in English and Chinese is required. Expertise in Korean, Japanese or Russian is required for corresponding operation specialist positions.',
      ],
    },
    {
      tit: 'Platform Operations Specialist',
      duty: [
        'Responsible for the analysis of the daily data of the platform',
        'Responsible for the tokens/coins listing work and the docking of the project holders and the business department',
        'Assists in completing activities related to various cross-functional departments (for instance: customer service, marketing departments) in support of goals within the department',
        'Develops, recommends and implements process improvements to improve efficiencies and services provided Serves as the liaison between departments when it becomes necessary',
        'Presents proposed solutions in a clear and concise manner and Investigate problems of an unusual nature in the area of responsibility',
        'Additional Position Responsibilities and related duties as assigned by manager.',
      ],
      need: [
        "A Bachelor's Degree in a related field required; Overseas study or work experience is preferred",
        '1-3 years working experience in operations required; More than 2 years of work experience in mainstream financial media, blockchain, or digital assets vertical media is preferred',
        'Strong organizational skills and learning and execution ability.',
      ],
    },
  ],
  tranding_bonus_tit: ' TRADING BONUS',
  tranding_bonus_time: '29 Feb 2020 at 16:00(UTC+00) ~ 4 Mar 2020 at 16:00(UTC+00)',
  tranding_bonus_btnText: 'Total 300,0004IRE',
  btc_tranding_bonus_tit: 'BTC TRADING BONUS',
  btc_tranding_bonus_time: '7th March 2020 at 00:00(UTC+00) ~ 9th Mar 2020 at 23:59(UTC+00)',
  btc_tranding_bonus_btnText: 'Total 10 BTC',
  symbol_detail_left: [
    {
      title_txt: 'Bitcoin (BTC) ',
      id: 'btc',
    },
    {
      title_txt: 'Ethereum (ETH) ',
      id: 'eth',
    },
    {
      title_txt: 'Ripple (XRP) ',
      id: 'xrp',
    },
    {
      title_txt: 'Bitcoin Cash (BCH) ',
      id: 'bch',
    },
    {
      title_txt: 'LiteCoin (LTC) ',
      id: 'ltc',
    },
    {
      title_txt: 'Binance-Peg Cardano (ADA)',
      id: 'ada',
    },
    {
      title_txt: 'Binance Coin (BNB)',
      id: 'bnb',
    },
    {
      title_txt: 'Binance-Peg BUSD (BUSD)',
      id: 'busd',
    },
    {
      title_txt: 'Tether (USDT)',
      id: 'usdt',
    },
  ],
  symbol_detail_article: {
    btc: {
      article_tit: 'Bitcoin (BTC) ',
      article_detail:
        'Bitcoin is a digital asset and a payment system invented by Satoshi Nakamoto who published a related paper in 2008 and released it as open-source software in 2009. The system is featured as peer-to-peer; users can transact directly without an intermediary.',
      article_link: [
        'https://bitcoin.org/en/',
        'https://www.blockchain.com/explorer',
        'https://bitcoin.org/bitcoin.pdf',
      ],
    },
    eth: {
      article_tit: 'Ethereum (ETH) ',
      article_detail:
        'Ethereum is a decentralized platform that runs smart contracts: applications that run exactly as programmed without any possibility of downtime, censorship, fraud or third party interference.',
      article_link: [
        'https://ethereum.org/',
        'https://etherscan.io/',
        'https://github.com/ethereum/wiki/wiki/%5BJapanese%5D-Ethereum-TOC',
      ],
    },
    xrp: {
      article_tit: 'Ripple (XRP) ',
      article_detail:
        'RippleNet delivers a single, frictionless experience for global payments. Rather than a constellation of disparate technologies, unstandardized communications, and centralized networks, RippleNet is a single, global network of banks that send and receive payments via Ripple’s distributed financial technology -- providing real-time messaging, clearing and settlement of transactions.',
      article_link: ['https://ripple.com/', 'https://livenet.xrpl.org/', ''],
    },
    bch: {
      article_tit: 'Bitcoin Cash (BCH) ',
      article_detail:
        'Bitcoin Cash is a full node implementation of the Bitcoin Cash protocol, which aims to provide a solid, stable software and help lead Bitcoin Cash protocol development with an open and collaborative process.',
      article_link: ['https://www.bitcoinabc.org/', 'https://blockchair.com/bitcoin-cash', ''],
    },
    ltc: {
      article_tit: 'LiteCoin (LTC) ',
      article_detail:
        'Litecoin is a peer-to-peer Internet currency that enables instant, near-zero cost payments to anyone in the world. Litecoin is an open source, global payment network that is fully decentralized without any central authorities.',
      article_link: ['https://litecoin.com/en/', 'https://blockchair.com/litecoin', ''],
    },
    ada: {
      article_tit: 'Binance-Peg Cardano (ADA)',
      article_detail:
        'ADA is the native digital assets of the Cardano blockchain. Alongside this, Binance-Peg ADA stands as an ADA token that thrives within the Binance Smart Chain (BEP20). Here at NVX exchange, our users can engage in seamless trading of the Binance-Peg ADA, an asset that showcases enhanced potency compared to the original ADA.<br/> Binance Smart Chain is a high-performance blockchain with an emphasis on smart contract programmability.',
      article_link: ['https://www.bnbchain.org/en/smartChain', 'https://bscscan.com/', ''],
    },
    bnb: {
      article_tit: 'Binance Coin (BNB) ',
      article_detail:
        "BNB is the digital assets coin that powers the BNB Chain ecosystem. As one of the world's most popular utility tokens, not only can you trade BNB like any other digital asset, you can also use BNB in a wide range of applications and use cases.<br/> Binance Smart Chain is a high-performance blockchain with an emphasis on smart contract programmability. ",
      article_link: ['https://www.bnbchain.org/en/smartChain', 'https://bscscan.com/', ''],
    },
    busd: {
      article_tit: 'Binance-Peg BUSD (BUSD)',
      article_detail:
        'BUSD is a 1:1 USD-backed stablecoin approved by the New York State Department of Financial Services (NYDFS) and issued in partnership with Paxos.<br/> Binance Smart Chain is a high-performance blockchain with an emphasis on smart contract programmability. ',
      article_link: ['https://www.bnbchain.org/en/smartChain', 'https://bscscan.com/', ''],
    },
    usdt: {
      article_tit: 'Tether (USDT)',
      article_detail:
        'USDT (Tether) runs on the Ethereum network as an ERC-20 token. Ethereum is a decentralized platform that runs smart contracts: applications that run exactly as programmed without any possibility of downtime, censorship, fraud or third party interference.',
      article_link: ['https://ethereum.org/', 'https://etherscan.io/', ''],
    },
  },
  symbol_detail_introduction: 'Introduction',
  symbol_detail_org: 'Official Website: ',
  symbol_detail_block: 'Blockchain Explorer: ',
  symbol_detail_book: 'White Paper: ',
  btc_trading_rebate_tit: 'BTC Trading Rebate',
  btc_trading_rebate_time: 'Go to Trade',
  slide_expand: 'Expand',
  slide_collapse: 'Collapse',
  high_rebate_banner: {
    rebate_tit: 'NVX Referral Program',
    rebate_subtit: 'Invite your friends To earm transaction fee refund',
    rebate_step: [
      {
        num: 1,
        step_desc: 'Register NVX and get your referral link and code',
      },
      {
        num: 2,
        step_desc: 'Register demo via your referral link or code',
      },
      {
        num: 3,
        step_desc: "You'll earm max 40% <br /> from 5 level",
      },
    ],
    rebate_btn: 'Sign up to invite friends immediately',
  },
  high_rebate_detail: {
    rebate_tit: 'Your Voice about <span>NVX<span>',
    rebate_subtit:
      'Join the most rewarding referral program in the world. Earn commission on 5 tiers.',
    rebate_rate: 'Max<span>40%</span>',
    rebate_joinin: 'Register Now!',
    rebate_register_step: [
      {
        step_img: '/images/rebate/number-one.png',
        step_num: 'Step 1 : Register ',
        step_desc:
          'Apply now to join the referral program. Get access to our admin who will guide you through the process in community. ',
      },
      {
        step_img: '/images/rebate/number-two.png',
        step_num: 'Step 2: Promote',
        step_desc:
          'Tell your community about NVX. They must register through your referral code. Monitor your campaign success with community insights.',
      },
      {
        step_img: '/images/rebate/number-three.png',
        step_num: 'Step 3: Get Paid',
        step_desc:
          'When your referred users trade, you get paid in BTC, USDT and etc straight to your wallet. You can withdraw your crypto at any time.',
      },
    ],
    rebate_five_tit: 'Five Tier Commission Structure',
    rebate_five_subdesc: 'Tell your friends to tell their friends so you can all be friends.',
    rebate_five_desc:
      'As a referral, you will receive a percentage of commission paid by your referrals down to 5 tiers. When users register through your referral code, they have register and become refer with your campaign. The referral payments will continue indefinitely as long as you and your referred user(s) own and operate their NVX account(s).',
    rebate_five_become: 'Become a community user',
    rebate_five_tips: 'Read more about <span>NVX referral program</span>',
    rebale_article_link: 'https://demo-exchange.zendesk.com/hc/en-us/articles/360039635991',
    rebate_explain: [
      {
        tit: 'Five Tier Commission',
        desc: 'Get paid 10% + total 10% commision on 5 tiers.<br /> (You’ll get paid 20% + total 20% commision on 5 tiers when you refer 100 people.)',
      },
      {
        tit: 'Partner Support',
        desc: 'To be a partner you’ll get more chance to get paid dividends.',
      },
      {
        tit: 'Referral Data Management ',
        desc: 'Get access to your live referral partner dashboard with insights about your referred users.',
      },
      {
        tit: 'Daily Payouts',
        desc: 'Commissions are paid to your account in BTC, USDT etc...settled on the same day your referred users trade.',
      },
    ],
    rebate_unique_tit: 'WHY you should trade on NVX?',
    rebate_unique: [
      {
        tit: 'Easy-to-use interface',
        subtit: 'Even new traders can trade with ease on NVX.',
      },
      {
        tit: 'Bitcoin as an Underlying Asset',
        subtit: 'Trade crypto using your Bitcoin, without taking delivery of the assets.',
      },
      {
        tit: 'Deep Liquidity',
        subtit: 'Deep order book and more liquidity.',
      },
      {
        tit: 'Security',
        subtit:
          'Client asset is always segregated, protected, and stored in multi-signature cold wallets – never stored in hot wallets.',
      },
      {
        tit: '100x Leverage on Crypto',
        subtit: 'Leverage trading with up to 100x leverage on cryptos.',
      },
      {
        tit: 'Multi-language Support',
        subtit:
          "NVX offers live chat support to all users. Most queries can be resolved through our help guides, but sometimes it's important to talk to a real human. We are there when you need us.",
      },
      {
        tit: 'Low Fees',
      },
      {
        tit: 'Partner Label Program',
        subtit: '200+ Partners are waiting for you to join!!',
      },
    ],
  },
  coopeartion_d: {
    banner: {
      tit: 'Become a partner of',
      sub_title: 'Sotatek Exchange',
      subtit: 'Work with your friends and grow together with NVX!',
      login: 'Apply here',
      manage_login: 'Apply here ',
      partner: 'Manage your account',
      manage_partner: 'Three minute account registration',
      detail: 'Learn more about NVX',
      main: 'Subject',
      main_step: 'NVX > Subject',
      plan: 'NVX has a unique system called "Partner Label Program". As long as you are active in your field, you can become our partner in this system,make yourself and NVX grow together.By becoming a partner, users will become an important member of the NVX ecosystem, and NVX will distribute 4IRE tokens as dividends',
    },
    step: [
      {
        step_img: '/images/step-1-partner.svg',
        step_num: 'Step 1',
        step_desc:
          'Apply for the Partner Label Program.<br />If you pass our review, the invitation link of partner community will be sent, so please join it.',
      },
      {
        step_img: '/images/step-2-partner.svg',
        step_num: 'Step 2',
        step_desc:
          'Become our partners.<br />\n        Become a partner and actively exert influence in areas where you are good at.',
      },
      {
        step_img: '/images/step-3-partner.svg',
        step_num: 'Step 3',
        step_desc:
          'Participate in partner-only programs.<br />\n        NVX regularly carries out  tasks for partners.<br />\n        The top prize winners who have achieved the task will receive 4IRE tokens or NVX limited merchandise.',
      },
    ],
    reward: {
      tit: 'Partner compensation',
      step: [
        'Permanent dividend 4IRE provided by NVX<br />After becoming a partner, you can receive daily rewards at BTC by holding total 10,000 4IRE or more that obtained during the partner period or 4IRE owned by yourself. However, the 4IRE granted by the partner ’s period will also disappear at the end of the partner ’s period. <br />(The 4IRE you get will not disappear.)<br />Part of the transaction fees of the users you invited. (commission up to 40%)',
        'Up to 40% of commission can be received from up to 5 levels of users you invited.Returned commissions (such as BTC, USDT, 4IRE, and other tokens) can be freely traded and withdrawn without being lockup. (Up to 20% of the commission can be refunded before the specified conditions are reached. If the condition are met, it will be increased to 40%.)<br />Complete partner-only tasks',
        'NVX regularly prepares tasks for partners. This allows partners to obtain permanent dividends 4IRE, USDT or NVX exclusive products.',
      ],
    },
    func: {
      tit: 'Available functions for partner',
      step: [
        {
          step_num: 'Real-time payment system',
          step_desc:
            'The service charge paid by invited users will be refunded to your wallet in real time.',
        },
        {
          step_num: 'Commission management system',
          step_desc:
            'We have a complete system to manage the number of invited users and the commission charged. In addition, you can also download these data as CSV files.',
        },
        {
          step_num: 'SNS release promotion',
          step_desc: 'Partners can post their referral codes to Twitter, Facebook, etc.',
        },
      ],
    },
    partner: {
      tit: 'Looking for partners',
      maintit:
        '<span>Currently, <span class="blue-text">NVX</span> is looking for the following partners.</span>',
      step: ['KOL', 'member', 'media'],
    },
  },
  cooperation_banner_tit: 'Become a partner of NVX',
  cooperation_banner_subtit: 'Work with your friends and grow together with NVX!',
  cooperation_banner_btntxt: 'NOW',
  roe_personal_team_slide: {
    subtit:
      "Let's participate Individual / Team trading contest!<br />This contest will compare your / your team total ROE!!\n    ",
    personal_btn: 'Individual',
    team_btn: 'Team batttle',
    bonus: 'Total prize <br />200BTC',
  },
  roe_team: {
    header_txt: {
      personal: {
        tit: 'NVX leverage trading ROE competition\n        ',
        subtit:
          'NVX is about to host a ROE contest,everyone can participate as long as you do leverage trading!\n        During this period, top ROE (return on equity) users of the competition will be shown on the trading dashboard in real time.',
      },
      team: {
        tit: 'NVX corps leveraged trading ROE contest',
        subtit:
          "NVX will hold a corps ROE contest in leveraged trading! <br />\n        In the contest, we will count the team's average ROE (return on equity) after the contest.",
      },
    },
    outline_list: [
      {
        tit: ' ROE Contest Outline (corps style )',
        subtit:
          "To make trades with open positions,the top teams with the highest ROE can receive prize in the end of contest . All users can become team leaders.\n      For teams that have not reached the minimum number of participants will be disqualified. The prize money will be different according to the number of participating teams.\n      In addition to ROE ranking contest, there are prizes for the entire team's trading volume.<br />\n      The calculation of ROE contest doesn’t effect on the amount of funds.<br />\n      Example: Users with a margin of 0.05 BTC and users with a margin of 5 BTC are calculated ROE scores fairly.",
      },
      {
        tit: 'Calculation method of ROE',
        subtit:
          'ROE (whole period) = [(ROE (day 1)+1)*(ROE (day 2)+1)*(ROE (day 3)+1)*... (last until the end date)]-1<br />\n      * Please refer to the daily ROE calculation method below',
      },
      {
        tit: 'ROE calculation method of the day (one day)',
        subtit:
          'ROE (ROE of the day) = margin balance (start of the day) ÷ margin balance(end of the day) – 1<br />\n      * A day means Japanese time 12:00 to 11:59 of next day.<br />\n      * For the calculation method of margin balance at the end, please refer to following',
      },
      {
        tit: 'Margin balance calculation method (at the end of the day)',
        subtit:
          'Margin balance (at the end of the day) = margin balance (at the beginning of the day) + <br />realized profit and loss (at the time of trading on the day) + unrealized profit and loss <br/>(at the end of the day)-unrealized profit and loss (at the beginning of the day)',
      },
      {
        tit: ' ROE contest registration period (team battle)',
        subtit: '3 July 09:00 ～ 11 July 23:59 （UTC）',
      },
      {
        tit: ' ROE contest period (team battle)',
        subtit: '12 July 00:00 ～ 1 August 23:59 （UTC）',
      },
      {
        tit: 'Qualification',
        subtit: 'At least 3 members Max 20 members in one team.',
      },
    ],
    team_bonus: {
      tit: ' Minimum trading volume during the competition',
      money: '1team {btc} BTC<br />Each team member has more than 0.05 BTC margin.',
    },
    team_txt: {
      teamAndAllMoney: 'Number of participating teams and total prize',
      team: 'Participating teams',
      all_money: 'Total Prize',
      teamtxt: 'teams',
      trading_txt: 'Trading volume contest',
      roe_txt: 'ROE contest',
    },
    bonus_rules: 'Prize distribution rules',
    bonus_rate: 'Prize Ratio',
    bonus_dis_demo: [
      {
        tit: 'Example of prize distribution (ROE)',
        subtit:
          '\n        Example: If there are 10 participating teams and the total prize amount is 2 BTC,\n    1st place&nbsp;&nbsp;1.4 BTC, 2nd place&nbsp;&nbsp; 0.2 BTC, 3rd place&nbsp;&nbsp; 0.1 BTC, 4 ~ 10th place&nbsp;&nbsp; 0.042 BTC<br /> \n    Example: If there are 1,000 participating teams and the total prize amount is 100 BTC,\n    1st place&nbsp;&nbsp; 70 BTC, 2nd place&nbsp;&nbsp; 10 BTC, 3rd place&nbsp;&nbsp; 10 BTC, 4 ~ 10th place&nbsp;&nbsp; 2.1 BTC \n    ',
      },
      {
        tit: 'Example of prize distribution (trading volume)',
        subtit:
          'Example: If there are 10 participating teams and the total prize amount is 1 BTC,  \n      1st place&nbsp;&nbsp; 0.7BTC, 2nd place&nbsp;&nbsp; 0.1 BTC, 3rd place&nbsp;&nbsp; 0.05 BTC, 4 ~ 10th place&nbsp;&nbsp; 0.021 BTC<br />\n      Example: If there are 1,000 participating teams and the total prize amount is 100BTC,\n      1st place&nbsp;&nbsp; 70 BTC, 2nd place&nbsp;&nbsp; 10 BTC, 3rd place&nbsp;&nbsp; 5 BTC, 4 ~ 10th place&nbsp;&nbsp; 2.1 BTC',
      },
    ],
    bonus_dis_demo_time: {
      tit: ' Bonus distribution time',
      subtit:
        'After the contest, we will confirm the correctness and determining the contest ranking within 20 days, then distribute the prize within 10 days.',
    },
    complement: {
      tit: 'Supplementary matters of contest',
      list: [
        'During the contest, if you withdraw margin or do not make any trade, you will be disqualified.',
        'After the contest, the number of participants and ranking will be finalized and announced within 30 days.',
        ' We have the right to disqualify users who are regarded as , illegally registered, and market manipulation.',
        'We reserve the right to cancel or modify the contest or contest rules.',
        ' If you hold a position enter the contest and participate in the contest without closing the position, the ROE and trading volume will be calculated after the position is 100% closed .',
        'The prize money will be evenly distributed to the members of winning team.',
        ' Need to get approval from the team leader to join the team.',
        ' You can only join one team in a contest.',
        ' When you enter a team, you have to show your user ID to the team leader.',
        ' The leaderboard will show the number of teams in real time and the finalized number of teams after the contest.',
        'If the number of teams is less than 10, the contest will not be established and will not be played during this period.',
        'If the commission income during the contest is lower than the total prize, 70% of the net commission income will be the upper limit of the total prize.',
      ],
    },
    cancel: {
      tit: 'Cancellation Policy',
      list: [
        'If you hold a position to enter the contest and before the contest your margin is less than 0.05 BTC: your participation will be cancelled. (Can re-register the contest again)',
        'If you do not trade at all during the contest, you will be disqualified.',
        'Teams that have not reached the minimum trading volume during the  contest,the team will be disqualified.',
      ],
    },
  },
  license: {
    legal: 'Legal and Security Documents',
    license_tit: 'Outline',
    license_footer: 'Licenses',
    license_subtit:
      'NVX, a digital assets exchange based in Indonesia and operating under the company PT. Aset Kripto International, has completed its registration process and received a license from the Commodity Futures Trading Regulatory Agency (BAPPEBTI). By obtaining the license, the exchange has fulfilled all regulatory requirements related to its transaction system, risk control management, resource allocation, and operational infrastructure.<br/>The CEO of NVX has expressed a commitment to collaborating with BAPPEBTI to accelerate the growth of the digital economy and promote financial inclusion through the application of blockchain technology. BAPPEBTI oversees all crypto/digital assets trading activities in Indonesia and has established regulations requiring compliance from all digital assets exchanges. Legally registered exchanges are now authorized to offer digital assets trading services in the country.',
    certificate_tit: 'Licenses',
    fincen: 'FinGEN',
  },
  validation_field_required: 'This field should not be empty',
  'validation.auth.email_required': 'The email field is required.',
  'validation.auth.number_required': 'The mobile number is required.',
  'validation.auth.password_required': 'The password field is required.',
  'validation.auth.captcha_required': 'Please input captcha.',
  'validation.auth.terms_required': 'The agree terms field is required.',
  'validation.auth.email_format': 'The email field must be a valid email.',
  'validation.auth.email_inactive': 'Please verify your email to active account.',
  'validation.auth.email_inactive_admin': 'Your account is inactive. Please contact to admin.',
  'validation.auth.email_existed': 'The email has already been taken.',
  'validation.auth.password_format':
    'Passwords must contain at least 8 characters, capital letters, lowercase letters, numbers, no space and must match the duplicate password box.',
  'validation.auth.password_max': 'The password field may not be greater than 72 characters.',
  'validation.auth.password_have_space':
    'Passwords must contain at least 8 characters, capital letters, lowercase letters, numbers, no space and must match the duplicate password box.',
  'validation.auth.password_confirmed_not_match':
    'The confirm password confirmation does not match.',
  'validation.auth.invalid_referral': 'The referral ID is invalid.',
  'validation.auth.captcha_expired': 'Verification expired. Check the checkbox again',
  'validation.auth.account_incorrect': 'The user credentials were incorrect.',
  'validation.auth.login_new_device':
    'It appears that you may be signing in from a device we are unfamiliar with or one that you have not used for a long period of time. For your security, a confirmation email has been sent to your email address.',
  'validation.auth.ggauth_required': 'The Google Authentication Code field is required.',
  'validation.auth.ggauth_incorrect': 'The Google Authentication Code is incorrect.',
  'validation.auth.ggauth_length': 'The Google Authentication Code must be 6 digits.',
  'validation.auth.fp_email_not_found': "We can't find a user with that e-mail address.",
  'validation.auth.password_confirm_required': 'The confirm password field is required.',
  'validation.auth.new_password_required': 'The new password field is required.',
  'validation.auth.password_new_max':
    'The new password field may not be greater than 72 characters.',
  'validation.auth.password_new_required': 'The new password field is required.',
  'validation.auth.otp_required': 'The Google Authentication Code field is required.',
  'validation.auth.password_old_required': 'The old password field is required.',
  'validation.auth.password_same': 'The new password must differ from your previous password.',
  'validation.auth.gg_auth_required': 'The Google Authentication Code field is required.',
  'validation.auth.ggauth_max': 'The Google Authentication Code must be 6 digits.',
  'validation.auth.otp_not_used': 'Please wait for next verification code to generate.',
  'validation.auth.password_incorrect': 'The password is incorrect.',
  'validation.auth.gg_code_required': 'The Google Authentication Code field is required.',
  'validation.auth.gg_code_incorrect': 'The Google Authentication Code is incorrect.',
  'validation.auth.gg_code_length': 'The Google Authentication Code must be 6 digits.',
  'validation.auth.gg_code_not_used': 'Please wait for next verification code to generate.',
  'validation.auth.phone_taken': 'This phone number has already been taken.',
  'validation.auth.phone_existed': 'This phone number has already been taken.',
  'validation.withdrawal.otp_incorrect': 'The Google Authentication Code is incorrect.',
  'validation.withdrawal.otp_not_used': 'Please wait for next verification code to generate.',
  'validation.withdrawal.otp_required': 'The Google Authentication Code field is required.',
  'validation.withdrawal.otp_length': 'The Google Authentication Code must be 6 digits.',
  'validation.withdrawal.otp_max': 'The Google Authentication Code must be 6 digits.',
  'google_auth.activate': 'Activate OTP successfully.',
  'google_auth.deactivate': 'Stop using OTP success!',
  'validation.address.coin_required': 'The coin field is required.',
  'validation.address.name_required': 'The label field is required.',
  'validation.address.name_length': 'The label must not exceed 20 characters.',
  'validation.address.address_required': 'The address field is required.',
  'validation.address.withdraw_address_invalid': 'The withdraw address is not valid.',
  'validation.address.address_existed': 'The address has already been existed.',
  'validation.address.white_list_off': 'Please turn on withdrawal whitelist function first',
  'validation.kyc.full_name_required': 'This field should not be empty',
  'validation.kyc.id_number_required': 'This field should not be empty',
  'validation.kyc.id_front_required': 'This field should not be empty',
  'validation.kyc.id_back_required': 'This field should not be empty',
  'validation.kyc.id_selfie_required': 'This field should not be empty',
  'validation.kyc.address_required': 'This field should not be empty',
  'validation.kyc.sub_district_required': 'This field should not be empty',
  'validation.kyc.ward_required': 'This field should not be empty',
  'validation.kyc.city_required': 'This field should not be empty',
  'validation.kyc.date_of_birth_required': 'This field should not be empty',
  'validation.kyc.date_of_birth_format': 'The date of birth field format is mistake.',
  'validation.kyc.province_required': 'This field should not be empty',
  'validation.kyc.postal_code_required': 'This field should not be empty',
  'validation.kyc.id_number_existed': 'The National ID has already been taken.',
  'validation.kyc.id_number_format': 'This field does not contain special characters.',
  'validation.kyc.id_front_format': 'File must be an image.',
  'validation.kyc.id_back_format': 'File must be an image.',
  'validation.kyc.id_selfie_format': 'File must be an image.',
  'validation.kyc.id_front_mimes': 'File must be a file of type: .jpg, .jpeg, .png, .heic, .heif.',
  'validation.kyc.id_back_mimes': 'File must be a file of type: .jpg, .jpeg, .png, .heic, .heif.',
  'validation.kyc.id_selfie_mimes': 'File must be a file of type: .jpg, .jpeg, .png, .heic, .heif.',
  'validation.kyc.id_front_max': 'This file should be maximum 4MB. Please choose another one',
  'validation.kyc.id_back_max': 'This file should be maximum 4MB. Please choose another one',
  'validation.kyc.id_selfie_max': 'This file should be maximum 4MB. Please choose another one',
  'validation.kyc.status_pending': 'Wait for the administrator to confirm.',
  'validation.kyc.video_selfie_mimetype': 'Video type not supported.',
  'validation.credit_card.card_invalid': 'Credit card information is invalid. Please try again',
  'validation.credit_card.expired_item_invalid':
    'Credit card information is invalid. Please try again',
  'validation.credit_card.card_cvc_invalid': 'Credit card information is invalid. Please try again',
  'validation.credit_card.max_card': 'Can not add new because maximum number of Credit cards is 5',
  'validation.credit_card.expiry_date_invalid': 'Invalid expiry date',
  'card.exists': 'Credit card exists. Please try again',
  'bank.account.exists': 'Bank account existed',
  sms_otp_required: 'The recovery code field is required.',
  sms_otp_incorrect: 'The recovery code is incorrect.',
  otp_recovery_code_incorrect: 'The recovery code is incorrect.',
  not_complete_kyc: 'Please complete the KYC process to enable this function',
  otp_sms_required: 'The OTP SMS field is required.',
  instant_convert: {
    title: 'Instant Convert',
    from: 'From',
    placeholder_from: 'Please input amount > ',
    to: 'To',
    max: 'Max',
    available_amount: 'Available Amount',
    current_having_amount: 'Current Having Amount',
    conversion_rate: 'Conversion Rate',
    trading_fee: 'Trading Fee',
    refresh: 'Refresh',
    refreshing: 'Refreshing',
    convert: 'Convert',
    note1:
      'The Estimated Received Amount will only be available in a few seconds before new Converted Rate is applied as of the Market price changed.',
    note2:
      'The Estimated Trade Amount will only be available in a few seconds before new Converted Rate is applied as of the Market price changed.',
    not_input_amount: 'Please input an amount to convert!',
    not_amount: 'Please input a higher amount than 0 to convert!',
    change_rate: 'Tax rate has been updated. Please try again!',
    amount_lower1: 'Please input higher amount than',
    amount_lower2: 'Please input higher amount than',
    amount_higher: 'Please input lower amount than',
    balance_not_enough: 'Insufficient available balance!',
    slippage_changed:
      'This pair is not available for Instant Convert at the moment. Please come back later!',
    pair_disabled:
      'This pair is not available for Instant Convert at the moment. Please come back later!',
    price_notfound:
      'This pair is not available for Instant Convert at the moment. Please come back later!',
    price_invalid:
      'This pair is not available for Instant Convert at the moment. Please come back later!',
    convert_success: 'Convert Successfully!',
    tax: 'Tax',
    history: {
      page_title: 'Convert History',
      from: 'From',
      to: 'To',
      received_token: 'Received Token',
      all: 'All',
      search: 'Search',
      reset: 'Reset',
      export: 'Export Convert History',
      export_tooltip: 'Only Export Convert History on 6 months',
      date: 'Date',
      traded_token: 'Traded Token',
      traded_amount: 'Traded Amount',
      conversion_rate: 'Conversion Rate',
      received_amount: 'Received Amount',
      fee: 'Fee',
      tax: 'Tax',
      no_data: 'You have no data',
    },
  },
  home_page: {
    text1: 'Secure Way',
    text2: 'To Trade!',
    text3: 'Trading made simple and secure',
    text4: 'Full scale Web3 Experience',
    text5:
      'From day trader to data scientist - NVX lets you create, test and monetize any kind of trading algorithm.',
    text6: 'Full scale Web3 Experience',
    text7: 'Your own custody',
    text8: 'All assets are keept 1:1 with what we manage with lorem ipsum',
    text9: 'Outgrid Wallet',
    text10: 'Web3 integrated',
    text11: 'All assets are keept 1:1 with what we manage with lorem ipsum',
    text12: 'Gamestarter | GS Studio',
    text13: 'NVX AppStore',
    text14: 'Integrated',
    text15: 'with',
    text16: 'And more apps cooming up soon! Only the best experience for',
    text17: 'Users',
    text18: 'News',
    text19: 'Keep NVX close to hand',
    text20: 'NVX Trading made simple and secure',
    text21: 'Soon on',
    text22: 'View more trading pairs',
    chart: 'Chart',
    trade: 'Trade',
  },
  menu_contract: {
    historical_data: 'Historical Data',
    insurance_fund: 'Insurance Fund',
    settlement_history: 'Settlement History',
    leaderboard: 'Leaderboard',
    contract_guides: 'Contract Guides',
    btc_contract: 'BTC (Bitcoin)',
    eth_contract: 'ETH (Ethereum)',
    eth_contract_guide: 'Ethereum Series (ETH) Guide',
    ada_contract: 'ADA (Cardano)',
    bch_contract: 'BCH (Bitcoin Cash)',
    ltc_contract: 'LTC (Litecoin)',
    trx_contract: 'TRX (TRON)',
    xrp_contract: 'XRP (Ripple)',
    contracts: 'Contracts',
    indices: 'Indices',
  },
  'time_in_force.good_till_cancel': 'Good Till Cancel',
  'time_in_force.immediate_or_cancel': 'Immediate Or Cancel',
  'time_in_force.fill_or_kill': 'Fill Or Kill',
  'common.liquidation': 'Liquidation',
  'common.camera_not_found':
    'Can not open the camera because your device does not have a camera. Please try again on another device',
  'common.camera_not_readable':
    'Can not open the camera because you are using it for another application. Please close the other application and try again',
  'common.camera_not_permisson':
    'Can not open the camera because you denied the permission. Please approve the permission and try again',
  'verify_code.sms_invalid_or_expired': 'Please enter a valid code',
  'verify_code.phone_verified': '',
  'verify_code.code_not_found': '',
  'verify_code.phone_no_required': '',
  'verify_code.phone_not_found': '',
  'verify_code.sms_sent_to_phone': '',
  'validation.auth.phone_inactive': 'Please active your phone number',
  'otp.title': 'Verification Code',
  'otp.subtitle':
    'Please enter the verification code, we have sent an OTP to {phone_no} via WhatsApp messenger.',
  'otp.dont-receive': 'Didn’t receive an OTP?',
  'otp.resend': 'Resend OTP',
  'otp.continue': 'Continue',
  'exception.otp_sms.not_map': 'Your code is invalid. Please try again',
  'exception.otp_sms.expired': 'Your code is expired. Please try again',
  'exception.otp_sms.required': 'The OTP SMS field is required.',
  slippery_note: {
    title: 'Slippery note',
    price: 'Price',
    travel_percent: 'Travel Percentage',
    turn_on: 'Turn on',
    turn_off: 'Turn off',
    field_required: 'This field should not be empty',
    price_invalid: 'Price should be greater than 0',
    percent_invalid: 'Percentage should be greater than 0',
    cancel: 'Cancel',
    save: 'Save',
    update_failed: 'Updated Slippery Note failed',
    update_success: 'Updated Slippery Note successfully',
    slippery_reset: 'Your updates will be reset.</br> Are you sure to cancel?',
    clear_all: 'Clear all',
    no_notify: 'No notification',
    has_hit: 'has hit',
    last_price_has_hit: 'last price has hit',
    below: 'below',
    above: 'above',
    trigger_time: 'Trigger time:',
    fi: 'This field should not be empty',
  },
  about_us: {
    about_us: 'About Us',
    about_us_content_1:
      'NVX is an Indonesian regulated platform that allows users to buy, sell, and trade digital assets. We operate online and allow users to exchange digital assets for other assets, such as fiat currencies or other digital currencies. One of our key features is liquidity, which refers to how easily users can buy and sell the digital assets on the platform. NVX is a liquid exchange where there is a large number of buyers and sellers, and the prices of the digital assets are always kept relatively stable. This makes it easy for everyone to buy and sell digital assets quickly and at a fair price.',
    about_us_content_2:
      "Another important feature to NVX is our security. Since digital assets exchanges deal with large amounts of money, we are often targeted by hackers and other cybercriminals. As a result, it's important for exchanges to have robust security measures in place to protect user funds and personal information.",
    about_us_content_3:
      "Digital assets exchanges are also subject to regulation, which can vary depending on the country in which the exchange is based. In some cases, exchanges may be required to register with regulatory bodies and comply with certain rules and regulations. This helps to protect users and ensure that the exchange is operating in a fair and transparent manner. NVX implements security measures such as intrusion detection systems while regularly auditing and testing the exchange's security measures to identify and fix vulnerabilities. NVX also implements plans for responding to security incidents, including measures such as shutting down the exchange temporarily to address the issue while all the assets in NVX is insured against losses due to security breaches or other incidents",
    our_vision: 'Our Vision',
    our_vision_content:
      'Our vision is to become the leading global platform for digital assets trading, providing users with access to the widest range of digital assets and the most advanced trading tools and features. We strive to continuously improve and expand our services, pushing the boundaries of what is possible in the world of digital assets and driving the adoption of this revolutionary technology. By empowering individuals to take control of their own financial futures, we aim to create a more inclusive and equitable financial system for all',
    our_mission: 'Our Mission',
    our_mission_content:
      'Our mission is to provide a secure, user-friendly platform for buying, selling, and trading digital assets. We strive to make the world of digital assets accessible to everyone, regardless of their technical expertise or financial background. Through our commitment to innovation and transparency, we aim to foster trust and confidence among our users and contribute to the growth and development of the digital assets ecosystem.',
    features: 'Features',
    features_content:
      "NVX offers a wide range of digital assets for trading, as well as advanced features and tools to help users manage their investments. This includes charting and analysis tools, real-time market data, and secure storage for digital assets. As a leader in the digital assets exchange space, NVX platform is at the forefront of the industry. With a focus on security, usability, and a wide range of trading options, your exchange is a top choice for anyone looking to buy, sell, or trade digital assets. By providing a trusted and comprehensive platform for accessing the world of digital assets, NVX is helping to make this exciting new technology more accessible and understandable toa wider audience. Whether you're a beginner looking to get started with digital assets or an experienced trader looking for advanced features, your exchange has something to offer.",
  },
  help_center_question_1: 'Q. What are digital assets?',
  help_center_question_2: 'Q. What is NVX?',
  help_center_question_3: 'Q. What determines a good digital assets exchange?',
  help_center_question_4: 'Q. How does NVX work?',
  help_center_question_5: 'Q. What digital assets are supported on the exchange?',
  help_center_question_6: 'Q. Are there any fees for using the exchange?',
  help_center_question_7: 'Q. Is the NVX secure?',
  help_center_question_8: 'Q. How do I withdraw funds from the exchange?',
  help_center_question_9: 'Q. Can I trade digital assets for fiat currencies on the exchange?',
  help_center_question_10: 'Q. How does a fiat currency exchange work?',
  help_center_question_11: 'Q. What is liquidity in the context of NVX?',
  help_center_question_12: 'Q. Is NVXs regulated?',
  help_center_question_13: 'Q. How can I choose a reputable digital assets exchange?',
  help_center_question_14: 'Q. What are some common features of a digital assets exchange?',
  help_center_question_15: 'Q. Are there any risks associated with using NVX?',
  help_center_question_16: 'Q. Are there different types of digital assets exchanges?',
  help_center_question_17: 'Q. Can I use NVX to convert my digital assets into fiat currencies?',
  help_center_question_18: 'Q. Are there any fees associated with using NVX?',
  help_center_question_19:
    'Q. Are there any other important factors to consider when using a digital assets exchange?',
  help_center_question_20: 'Q. Is it safe to store my digital assets on NVX?',
  help_center_question_21: 'Q. Can I use NVX to buy digital assets with a credit card?',
  help_center_question_22:
    'Q. Are there any restrictions on who can use a digital assets exchange?',
  help_center_question_23: 'Q. Is it possible to earn money by trading on NVX?',
  help_center_question_24: 'Q. Can I use NVX to transfer funds to another person?',
  help_center_question_25: 'Q. Can I use NVX to pay for goods and services?',
  help_center_question_26:
    'Q. Are there any limitations on the amount of digital assets I can buy or sell on NVX?',
  help_center_question_27: 'Q. Can I use NVX to trade in multiple digital assets?',
  help_center_question_28: 'Q. Can I use NVX to trade 24/7?',
  help_center_question_29: 'Q. Can I use NVX to short sell digital assets?',
  help_center_question_30: 'Q. Can I use a digital assets exchange to set up automatic trades?',
  help_center_question_31: 'Q. Can I use a digital assets exchange to set up stop-loss orders?',
  help_center_question_32:
    'Q. Can I use a digital assets exchange to access my digital assets wallet?',
  help_center_question_33: 'Q. Can I use NVX to access market data and analysis tools?',
  help_center_question_34: 'Q. Can I use NVX to access customer support?',
  help_center_question_35: 'Q. Can I use NVX to access educational resources?',
  help_center_question_36: 'Q. Can I use NVX to transfer my digital assets to another exchange?',
  help_center_question_37:
    'Q. Can I use NVX to trade futures contracts or other derivative products?',
  help_center_question_38: 'Q. Can I use NVX to trade on multiple devices?',
  help_center_question_39: 'Q. Can I use NVX to receive interest on my digital assets?',
  help_center_answer_1:
    'A. Digital assets is a digital or virtual currency that uses cryptography for security and is decentralized, meaning it is not controlled by a central authority such as a government or financial institution. Digital assets are created and managed using advanced technologies such as blockchain, which provides a secure and transparent way of recording transactions on a decentralized network. Some examples of digital assets include Bitcoin, Ethereum, and Litecoin. These currencies can be used as a medium of exchange for goods and services, and are often traded on online exchanges for fiat currencies or other digital assets.',
  help_center_answer_2:
    'A. NVX is a digital assets exchange platform that allows users to buy, sell, and trade digital assets. These exchanges typically operate online and allow users to exchange digital assets for other assets, such as fiat currencies or other digital currencies.',
  help_center_answer_3:
    "A. There are several factors that can determine whether a digital assets exchange is good or not. Some of these factors include the fees charged by the exchange, the availability of a wide range of digital assets, the user-friendliness of the platform, and the security measures in place to protect users' funds. Additionally, the customer support and overall reputation of the exchange can also be important factors to consider. It is important for users to do their own research and compare different exchanges to find the one that best meets their needs and preferences.",
  help_center_answer_4:
    "A. NVX operates in a similar way to a traditional stock exchange. Users can create an account on the exchange and deposit funds into their account using a variety of payment methods. Once they have funds available in their account, they can place buy or sell orders for the supported digital assets. When a buy or sell order is matched with another user's order, the exchange will facilitate the trade and transfer the funds or digital assets between the users' accounts.",
  help_center_answer_5:
    'A. NVX has a huge list of supported digital assets, you may see which ones that you are interested in to trade by clicking trade now.',
  help_center_answer_6:
    'A. NVX charges a minimal fee for their services. Depending, on the exchange and the type of trade being made some services charge a flat fee for all trades, while others charge apercentage of the trade amount. Rest assured that NVX has the mission to keep all charges minimum and fair.',
  help_center_answer_7:
    'A. Security is our top priority as they handle large amounts of money and sensitive user information. NVX have appropriate safeguards in place to protect user funds and information. This can include measures like secure servers, encryption, and two-factor authentication.',
  help_center_answer_8:
    'A. To withdraw funds from the exchange, you will need to have a wallet set up to receive the funds. The specific steps for withdrawing funds will vary depending on the exchange, but generally, you will need to specify the amount of funds you want to withdraw, the wallet address to send the funds to, and any required security information. Once you have entered this information and confirmed the withdrawal, the exchange will process the transaction and transfer the funds to your wallet.',
  help_center_answer_9:
    'A. NVX allows users to trade digital assets for fiat currencies like the Indonesian Rupiah. This can be a convenient way to convert your digital assets into cash that can be used in the real world.',
  help_center_answer_10:
    'A. NVX allows users to deposit funds into their account using a variety of methods, such as bank transfers or credit card payments. Once the funds are deposited, users can then use the platform to buy and sell various digital assets. The exchange acts as a middleman, facilitating the trade and taking a small fee for its services.',
  help_center_answer_11:
    'A. Liquidity refers to how easily users can buy and sell the digital assets on a given exchange. A liquid exchange is one where there is a large number of buyers and sellers, and the prices of the digital assets are relatively stable. This makes it easy for users to buy and sell digital assets quickly and at a fair price.',
  help_center_answer_12:
    'A. Yes, NVX is regulated by the Indonesian government. NVX registered with regulatory bodies and comply the Indonesian rules and regulations. This helps to protect users and ensure that the exchange is operating in a fair and transparent manner.',
  help_center_answer_13:
    "A. There are several factors to consider when choosing a reputable digital assets exchange. These include the exchange's reputation, the fees it charges, the range of digital assets it offers, and the security measures it has in place. NVX keeps everything in check to ensure fair trade is always in place.",
  help_center_answer_14:
    'A. NVX includes the ability to deposit and withdraw funds, the ability to buy and sell various digital assets, and the ability to track and manage your transactions that includes charting tools, market analysis, and customer support.',
  help_center_answer_15:
    "A. There are a few risks to be aware of. These include the risk of cyberattacks and hacking, the risk of losing access to your funds, and the risk of regulatory changes that may impact the exchange's operations but rest assured that NVX is insured against its losses due to security breaches or other incidents.",
  help_center_answer_16:
    'A. Yes, there are several different types of digital assets exchanges. These include centralized exchanges, decentralized exchanges, and peer-to-peer exchanges. Each type of exchange has its own unique features and benefits. NVX is a regulated centralized exchange registered with the Indonesian government.',
  help_center_answer_17:
    'A. Yes, NVX offers the ability to convert your digital assets into traditional fiat currencies, such as IDR. This can be useful if you want to access the funds in your digital assets account for everyday use or to transfer the funds to a bank account.',
  help_center_answer_18:
    'A. Yes, we charge a minimal fee for our services. These fees may a flat fee for all trades, while others charge a percentage of the trade amount. Rest assured that NVX has the mission to keep all charges minimum and fair.',
  help_center_answer_19:
    "A. In addition to the factors mentioned above, there are a few other things to consider when using a digital assets exchange. These include the exchange's user interface and user experience, the customer support it offers, and the range of payment methods it accepts. It's also a good idea to check for our promotions or discounts NVX may offer.",
  help_center_answer_20:
    "A. Although it's generally considered safer to store your digital assets in a personal wallet rather than on NVX, it may be more convenient to keep them on the exchange. Rest assured that NVX is insured against its losses due to security breaches or other incidents. It's important to carefully weigh the pros and cons and choose the option that's best for your individual needs.",
  help_center_answer_21:
    'A. As of now NVX does not allow buying digital assets using a credit card yet because the regulation does not allow it yet.',
  help_center_answer_22:
    'A. Yes, NVX may have restrictions on who can use their platform. Please sign up and complete the KYC process to check your eligibility to see if there are any restrictions that may apply to you.',
  help_center_answer_23:
    "A. Trading on a digital assets exchange can be a way to earn money, but it's important to remember that it also involves risk. The prices of digital assets can fluctuate wildly, and it's possible to lose money as well as make money. It's a good idea to carefully research and educate yourself before starting to trade on an exchange. You should also be prepared to accept the risks and only invest money that you can afford to lose.",
  help_center_answer_24:
    'A. Yes, NVX offers the ability to transfer funds to another person using our platform.This can be a convenient option if the recipient also has an account on the same exchange. However,there may be fees and limit involved.',
  help_center_answer_25:
    'A. It depends on the merchant and the payment method used. Some merchants may accept digital assets as payment, in which case you could use a digital assets exchange to buy the necessary digital assets and then pay the merchant directly. However, not all merchants accept digital assets. In Indonesia, paying for goods and services using digital assets is strictly prohibited.',
  help_center_answer_26:
    "A. This can vary depending on the exchange and the specific digital assets being traded. Some exchanges may have minimum or maximum limits on the amount of digital assets that can be bought or sold in a single transaction. These limits may be in place to prevent fraud or to ensure that the exchange has enough liquidity to facilitate the trade. It's important to check the specific exchange and digital assets you're interested in to see if there are any limitations that may apply.",
  help_center_answer_27:
    "A. Yes, NVX offers the ability to trade in multiple digital assets. This can be convenient if you're interested in diversifying your portfolio or want to take advantage of arbitrage opportunities between different digital assets.",
  help_center_answer_28: 'A. Yes, NVX offers 24/7 trading.',
  help_center_answer_29:
    'A. No, at the moment NVX does not offer the ability to short sell digital seets. The feature is still in development.',
  help_center_answer_30:
    "A. Yes, NVX offers the ability to set up automatic trades, which can be useful if you want to take advantage of certain market conditions or want to implement a long-term investment strategy. However, not all exchanges offer this feature, so it's important to check the specific exchange you're considering to see what options are available.",
  help_center_answer_31:
    "A. Yes, NVX offers the ability to set up stop-loss orders, which allow users to automatically sell their digital assets if the market price reaches a certain level. This can be a useful risk management tool, as it allows users to limit their potential losses if the market moves against them. However, it's important to carefully research and understand how stop-loss orders work before using this feature, as it may not always be effective in protecting against losses.",
  help_center_answer_32:
    "A. Yes, NVX offers the ability to access a digital assets wallet through our platform. This can be a convenient option if you want to manage your digital assets holdings in one place. It's important to carefully research and compare the different options before choosing where to store your digital assets.",
  help_center_answer_33:
    'A. Yes, many NVX offers access to market data and analysis tools that can be useful for tracking the performance of different digital assets and making informed trading decisions. These tools may include charts, market indicators, and news feeds.',
  help_center_answer_34:
    "A. Yes, most NVX offers several forms of customer support, typically through email, phone, or a support ticket system. This can be useful if you have questions or encounter any issues while using the exchange. It's a good idea to check our website or contact customer support to find out what support options are available and how responsive they are.",
  help_center_answer_35:
    "A. Yes, NVX offers access to educational resources, such as tutorials, guides, and webinars, to help users learn more about digital assets and how to trade on the exchange. This can be useful if you're new to the world of digital assets and want to learn more before getting started. It's a good idea to check our website to see if it offers any educational resources and how comprehensive they are.",
  help_center_answer_36:
    "A. Yes, NVX offers the ability to transfer your digital assets to another exchange. This can be useful if you want to take advantage of different trading opportunities or if you want to move your digital assets to a different exchange for any reason. It's important to carefully research and compare the different options before transferring your digital assets to another exchange.",
  help_center_answer_37:
    'A. NVX plans to offer the ability to trade futures contracts or other derivative products, as long as these products are under the regulations of digital assets in Indonesia.',
  help_center_answer_38:
    "A. Yes, NVX offers mobile apps or responsive web design that allows users to access the platform on multiple devices, such as a smartphone, tablet, or laptop. This can be convenient if you want to trade on the go or if you use multiple devices to manage your investments. It's a good idea to check the our website or contact customer support to find out what devices are supported and how user-friendly the platform is on each device.",
  help_center_answer_39:
    "A. Yes, NVX may offer the ability to earn interest on your digital assets by lending them to other users or participating in staking or other proof-of-stake protocols. It's important to carefully research and compare the different options before choosing an exchange for earning interest on your digital assets.",
  home_page: {
    title: 'Buy and Trade <br /> Digital Assets <br /> Like Never Before',
    subtitle: 'Trading made simple and secure',
    get_started: 'Get Started',
    download_app: 'Download <br />the App',
    possibilities: {
      txt_1: 'Unlock new <br />possibilities with',
      txt_2: 'Revolutionize your trading experience with',
      txt_3: 'Your trusted partner for secure and transparent digital assets trading',
    },
    about_us: {
      title: 'About Us',
      content:
        'Dedicated Customer Support: Receive prompt and reliable assistance from a knowledgeable customer support team available 24/7.<br /> <br /> Advanced Trading Tools: Utilize a comprehensive suite of trading tools designed to enhance your crypto trading strategies. <br /> <br /> Secure Cold Storage: Safeguard your digital assets with offline cold storage solutions, ensuring an extra layer of protection against potential cyber threats.',
    },
    acc_management: {
      title: 'Comprehensive Account <br /> Management',
      title_1: 'IDR currency support',
      desc_1:
        'Easily convert your fiat currency into digital assets with a secure 3FA authenticator.',
      title_2: 'Competitive fee',
      desc_2:
        'Benefit from competitive fee structures, ensuring cost-effective trading to maximise your profit.',
      title_3: 'Instant swap service',
      desc_3:
        'Access instant swap service for large volume trades, providing privacy, personalised support and competitive pricing.',
      learn_more: 'Learn More',
    },
    our_partners: {
      title: 'Our Partners',
    },
    news: {
      title: 'News and articles',
      desc: 'Access wealth of educational resources including webminars and market insights to enhance your crypto trading knowledge and skills',
    },
    advanced_tools: {
      title: 'Advanced Charting Tools',
      desc: 'Analyze price trends, patterns, and indicators with advanced charting tools, empowering you to make data-driven trading decisions.',
    },
  },
  to_address: 'To Address',
  withdraw_fiat: {
    title: 'Withdrawal IDR',
    note: 'The Bank account information that has been verified will be used as your main bank details.',
    bank_name: 'Bank name',
    account_name: 'Account name',
    account_number: 'Account number',
    inputted_amount: 'Inputted amount',
    amount: 'Amount',
    fee: 'Fee',
    tax: 'Tax',
    you_will_receive: 'You will get',
    notes: {
      please_note: 'Please note',
      note_1: 'Ensure that Virtual Account Transaction failed!(VA) is entered correctly',
      note_2:
        'Ensure that name of Virtual Account (VA) of PT Aset Kripto International and bank is the same',
      note_3: 'PT Aset Kripto International is not liable for any wrong information input',
      note_4: 'Payment of Virtual Account (VA) can only be processed through mobile banking.',
      note_5: 'Payment of Virtual Account (VA) cannot be proccessed through ATM deposit',
    },
  },
  chainalysis_note:
    'NVX provides comprehensive digital assets investigation and transaction monitoring solutions. We offer blockchain data and analysis to government agencies, exchanges, and financial institutions in over 70 countries. Our compliance and investigation tools, educational resources, and support help customers understand blockchain activities. The data platform also powers investigation, compliance, and risk management tools that have contributed to resolving high-profile cyber criminal cases and providing safe digital assets access to consumers.',
  withdraw_fiat_modal: {
    title: 'Additional information required for withdrawal above IDR {travelRuleAmount} equivalent',
    form: {
      title: 'Originator Information',
      full_name: 'Full Name',
      address: 'Address',
      excution_date: 'Execution Date',
      source_of_funds: 'Source of Funds',
      source_of_funds_placeholder: 'Please specify',
      upload_supporting_document: 'Upload supporting document',
      field_required: 'This field should not be empty',
      submit: 'Submit',
      invalid_document_type: 'The document type must be image or .pdf',
      document_size_over_limit: 'The document size must be less than 10MB',
    },
  },
  withdraw_crypto_modal: {
    request: 'Request submitted successfully!',
    sub_request: 'Please check your email for the confirmation',
    title_1: 'Below information required for withdrawal under {amount} IDR equivalent',
    title_2: 'Additional information required for withdrawal above {amount} IDR equivalent',
    form: {
      originator_info: 'Originator Information',
      recipient: 'Recipient',
      full_name: 'Full Name',
      full_name_placeholder: 'Please input the full name',
      address: 'Address',
      address_placeholder: 'Please input the address',
      identity_number: 'Identity Number',
      wallet_address: 'Wallet Address',
      source_of_funds: 'Source of Funds',
      source_of_funds_placeholder: 'Please specify',
      upload_supporting_document: 'Upload supporting document',
      field_required: 'This field should not be empty',
      submit: 'Submit',
      invalid_document_type: 'The document type must be image or .pdf',
      document_size_over_limit: 'The document size must be less than 10MB',
    },
  },
  source_of_funds: {
    choose: 'Choose a source',
    salary: 'Salary',
    personal_saving: 'Personal saving accounts',
    employment_income: 'Employment income',
    property_sale: 'Property sale',
    inheritances: 'Inheritances and gifts',
    legal_settlements: 'Legal settlements',
    others: 'Others',
  },
  deposit_fiat: {
    title: 'Deposit IDR',
    destination_bank: 'Destination Bank',
    account_name: 'Account name',
    va_number: 'VA Number',
    desc: 'The Bank account information that has been verified will be used as your main bank details.',
    notes: {
      please_note: 'Please note',
      note_1: 'Ensure that Virtual Account (VA) is entered correctly',
      note_2:
        'Ensure that name of Virtual Account (VA) of PT Aset Kripto International and bank is the same',
      note_3: 'Transfer Method is Online Transfer (cannot use BI FAST/SKN)',
      note_4: 'PT Aset Kripto International is not liable for any wrong information input',
      note_5: 'Payment of Virtual Account (VA) can only be processed through mobile banking.',
      note_6: 'Payment of Virtual Account (VA) cannot be proccessed through ATM deposit',
    },
    credit_card: 'Credit Card',
    bank_transfer: 'Bank Transfer',
    see_instruction: 'How to Pay',
    pay_within: 'Pay within',
    expired: 'Expired',
    history_table: {
      time: 'Time',
      date: 'Date',
      amount: 'Amount',
      status: 'Status',
      payment_method: 'Payment Method',
      action: 'Action',
      bank: 'Bank',
      account: 'Account',
    },
  },
  deposit_fiat_modal: {
    title_1: 'Confirm',
    title_2: 'Choose payment method',
    total: 'Total',
    pay_within: 'Pay within',
    choose_payment_method: 'Choose payment method',
    credit_card: 'Credit Card',
    bank_transfer: 'Bank Transfer',
    expires_on: 'Expires on',
    expired: 'Expired',
    expiry: 'Expiry',
    add_new_card: 'Add new card',
    card_number: 'Card number',
    cancel: 'Cancel',
    add: 'Add',
    confirm: 'Confirm',
    credit_card_payment_confirmation: 'Credit card payment confirmation',
    available_transfer: 'Available for Internet banking/Mobile banking',
    transfer_to: 'Transfer to',
    complete_payment: 'Complete payment from {code} to the virtual account number below.',
    bill_key: 'Bill Key',
    bill_code: 'Biller Code',
    virtual_account_number: 'Virtual account number',
    account_name: 'Account name',
    how_to_pay: 'How to pay',
    got_it: 'Got It',
    add_new_card_success: 'Added new card successfully',
    remove_card_success: 'Removed credit card successfully',
    confirm_remove_card: 'Are you sure to remove this Credit card?',
  },
  register: {
    policy:
      "By clicking Sign Up, you agree to our <a href='/terms'>Terms</a> and <a href='/privacy-policy'>Privacy Policy</a>. You may receive SMS notifications from us and can opt out at any time.",
    send_otp_success: 'Send OTP success',
    send_otp_failed: 'Send OTP fail',
    invalid_phone_no_1: '{phone} is not phone number',
    invalid_phone_no_2: 'Phone number length must be between 10 and 30 digits',
  },
  bank_account_management: {
    title: 'Bank Accounts Management',
    bank_required: 'You must choose a bank',
    add_bank: 'Add Bank',
    add_new_bank: 'Add new bank account',
    maximum_bank_note:
      "Bank account number is up to {limit}. Please go to <a href='/account'>Bank accounts management</a> to remove or add bank accounts",
    choose_bank: 'Choose Bank',
    enter_bank_account: 'Enter the bank account number',
    enter_bank_name: 'Enter the bank account owner name',
    fill_bank_auto: 'Filled automatically based on your KYC identity',
    bank: 'Bank',
    account_number: 'Account Number',
    owner_name: 'Owner Name',
    action: 'Action',
    add: 'Add',
    cancel: 'Cancel',
    add_bank_success: 'Added new bank account successfully',
    add_bank_faild: 'Add new bank failed!',
    max_bank_account:
      'Maximum number of bank accounts is {limit}. Please remove an account to add the new one',
    remove_bank_success: 'Removed bank account successfully',
    remove_bank_faild: 'Removed bank account failed!',
    confirm_remove_bank: 'Are you sure to remove this Bank account?',
  },
  'funds.deposit_fiat.copy_account_number': 'Click the account number to copy',
  coming_soon: {
    title: 'Coming Soon',
    text_1: '🚀 Get Ready for an Exciting Launch! 🚀',
    text_2: "Welcome to NVX's Exclusive Sneak Peek!",
    text_3:
      "We're thrilled to announce that something amazing is about to hit the crypto world, and you're among the first to know! Our team has been hard at work crafting an extraordinary digital assets exchange platform that will revolutionize the way you trade and invest in digital assets.",
    text_4: "🔥 What's Coming? 🔥",
    text_5:
      '🌟 A Seamless Trading Experience: Get ready for lightning-fast transactions and a user-friendly interface that will make trading a breeze.',
    text_6:
      '🌐 Global Accessibility: Trade from anywhere in the world, connecting with a diverse community of crypto enthusiasts.',
    text_7:
      "💡 Cutting-Edge Security: Your assets are our top priority. We've implemented state-of-the-art security measures to ensure your peace of mind.",
    text_8:
      "But here's the best part – you can be a part of this groundbreaking journey from the very beginning!",
    text_9: '💌 Be the First to Know! 💌',
    text_10:
      "To make sure you're among the first to experience our crypto exchange and take advantage of exclusive early-bird benefits, simply drop us an email at hello@nvx.co.id. By joining our pre-launch list, you'll receive:",
    text_11:
      '- 🎁 Priority Access: Get a head start on exploring the platform and its features before anyone else.',
    text_12:
      '- 📈 Insider Updates: Stay in the loop with sneak peeks, progress reports, and exciting announcements.',
    text_13:
      '- 🤝 Exclusive Offers: Enjoy special promotions and discounts as a token of our appreciation for your early support.',
    text_14:
      "<p> Don't miss out on this opportunity to be at the forefront of the crypto revolution. Be one of the pioneers to witness the future of trading and investment! </p> <p> Stay tuned for more updates by following us on social media, and be sure to share the news with fellow crypto enthusiasts. The countdown to an extraordinary trading experience starts now. </p> <p>See you on the other side of innovation!</p> <p>Warm regards,<br />The NVX Team</p>",
  },
  intruction_modal: {
    title: 'How to pay',
    content:
      '<div>From <strong>Permata app</strong></div><ol> <li>Open mobile <strong>Permata</strong> app, choose <strong>Pembayaran Tagihan</strong></li> <li>Choose <strong>Virtual Account</strong></li> <li>In the <strong>Nomor Virtual Account</strong>, input <strong>VA number</strong></li> <li>Click <strong>Next</strong>, in the Nominal input transfer amount</li> <li>Click <strong>OK</strong>, do the <strong>Payment Confirmation</strong></li> </ol> <div>From <strong>Other banks</strong></div> <ol> <li> Open your mobile banking app, choose <strong>Transfer Antar Rekening Bank</strong> </li> <li>Choose <strong>Permata</strong> as your target recipient bank account</li> <li>In the <strong>Nomor Rekening</strong>, input <strong>VA number</strong></li> <li>Click <strong>Next</strong>, in the <strong>Nominal</strong> input the transfer amount</li> <li>Click <strong>OK</strong>, do the <strong>Payment Confirmation</strong></li> </ol>',
    permata: {
      title: 'From <strong>Permata app</strong>',
      content:
        "<ol class='list-intruction'> <li>Open mobile <strong>Permata</strong> app, choose <strong>Pembayaran Tagihan</strong></li> <li>Choose <strong>Virtual Account</strong></li> <li>In the <strong>Nomor Virtual Account</strong>, input <strong>VA number</strong></li> <li>Click <strong>Next</strong>, in the Nominal input transfer amount</li> <li>Click <strong>OK</strong>, do the <strong>Payment Confirmation</strong></li> </ol>",
    },
    other: {
      title: 'From <strong>Other banks</strong>',
      content:
        "<ol class='list-intruction'> <li> Open your mobile banking app, choose <strong>Transfer Antar Rekening Bank</strong> </li> <li>Choose <strong>Permata</strong> as your target recipient bank account</li> <li>In the <strong>Nomor Rekening</strong>, input <strong>VA number</strong></li> <li>Transfer Method is <strong>Online Transfer (cannot use BI FAST/SKN)</strong></li> <li>Click <strong>Next</strong>, in the <strong>Nominal</strong> input the transfer amount</li> <li>Click <strong>OK</strong>, do the <strong>Payment Confirmation</strong></li> </ol>",
    },
    xendit: {
      title: 'From <strong>Xendit</strong> Checkout Page',
      content:
      "<ol class='list-intruction'> <li><strong>Click on 'Pay Now':</strong> Begin the payment process by clicking the 'Pay Now' link. You can access the link directly from this page or by clicking the link sent via Whatsapp and Email.</li> <li><strong>Redirect to Xendit Checkout Page:</strong> You'll be redirected to the Xendit checkout interface. </li> <li><strong>Select Your Payment Method:</strong> Choose your preferred payment method from the available options. </li> <li><strong>Follow the Instructions:</strong> Complete the payment by following the on-screen instructions specific to the payment method chosen. </li></ol>",
    },
  },
  withdraw_guide: {
    kyc: 'KYC',
    kyc_level: 'KYC Level',
    trade: 'Trade',
    yes: 'Yes',
    no: 'No',
    unlimited: 'Unlimited',
    unlimited_monthly: 'Unlimited Monthly',
    fiat_deposit: 'IDR Deposit',
    fiat_deposit_limit: 'IDR Deposit Limits',
    fiat_withdrawal: 'IDR Withdrawal',
    fiat_withdrawal_limit: 'IDR Withdrawal Limits',
    crypto_deposit: 'Crypto Deposit',
    crypto_deposit_limit: 'Crypto Deposit Limits',
    crypto_withdraw: 'Crypto Withdrawal',
    crypto_withdraw_limit: 'Crypto Withdrawal Limits',
    fiat_withdrawal_lv3_limit: 'IDR 250,000,000 per 24 hours',
    crypto_withdrawal_lv3_limit: 'IDR 250,000,000 equivalent per 24 hours',
    fiat_withdrawal_lv4_limit: 'Additional information required above IDR 250,000,000 per 24 hours',
    crypto_withdrawal_lv4_limit:
      'Additional information required above IDR 250,000,000 per 24 hours',
  },
  // kyc digisign error
  digisign_error: {
    expired:
      'Your Digisign activation link has expired. Please click the button “Submit” again for the next step',
    name_invalid: 'Incorrect name Format',
    id_invalid: 'Incorrect NIK Format',
    id_not_found: 'NIK/ID not found',
    name_dob_not_match: 'Name and Date of birth do not match',
    dob_not_match: 'Date of birth does not match',
    name_not_match: 'Name does not match on Verification Institution',
    name_selfife_not_match: 'Name and Selfie photo do not match',
    name_dob_not_match: 'Name and Date of birth do not match',
    no_face_selfie: 'No face found on Selfie',
    no_face_ktp: 'No face found on KTP',
    face_verify_failed: 'Face verification failed. Verification failed in government agencies',
    ktp_invalid:
      'User verification failed. Make sure the light is enough and photo KTP/ID matches the selfie photo',
    phone_number_invalid: 'Phone format must be 62, 08 or +62',
    data_invalid:
      'The user has tried to be registered more than 3 times with invalid data. Please check the data first.',
    system_timeout: 'System timeout, please try again 10 minutes later',
    unkown_error: 'Unknown error!',
    ktp_taken:
      'The NIK has been registered with the email <strong>{email}</strong>, please log in or register with the email that matches the NIK or use another NIK.',
  },
};
