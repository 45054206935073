<template>
  <footer>
    <div class="wrapper">
      <div class="info">
        <router-link to="/" class="logo">
          <img src="/images/nvx/nvx-banner-2.png" alt="logo" />
        </router-link>
        <div class="description-fake">
          <h3 class="text1">PT. ASET KRIPTO INTERNASIONAL</h3>
          <p class="text2">
            Equity Tower FL 42 Unit G, SCBD Jl. Jend. Sudirman Kav 52-53
            Jakarta, Indonesia 12190
          </p>
        </div>
        <div>
          <img src="/images/new-iso.png" alt="iso" class="iso-logo" />
        </div>
      </div>
      <div class="links">
        <div class="nav-links">
          <ul
            class="nav-items"
            :class="item.key"
            v-for="item in navLinks"
            :key="item.key"
          >
            <li class="text1">
              {{ $t(item.title) }}
            </li>
            <li v-for="link in item.children" :key="link.href">
              <span
                v-if="link.href === 'openPopupContactUs'"
                @click="openPopupContactUs"
                class="text2"
              >
                {{ $t(link.name) }}
              </span>
              <span
                v-else-if="link.href === 'redirectApiDocument'"
                @click="redirectApiDocument"
                class="text2"
              >
                {{ $t(link.name) }}
              </span>
              <router-link v-else class="text2" :to="link.href">
                {{ $t(link.name) }}
              </router-link>
            </li>
          </ul>
          <ul
            class="nav-items column4"
            v-if="!isPwa() && (goToAndroidApp || goToAppStore)"
          >
            <li class="text1">{{ $t('menu_bottom.install_app') }}</li>
            <li class="text-right mt-auto" v-show="goToAndroidApp">
              <a :href="goToAndroidApp" target="_blank">
                <img src="@/assets/images/googleplay.svg" alt="googleplay" />
              </a>
            </li>
            <li class="text-right" v-show="goToAppStore">
              <a :href="goToAppStore" target="_blank">
                <img src="@/assets/images/appstore.svg" alt="appstore" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div v-if="siteSetting" class="copyright">
      <div class="copyright-container">
        <div class="text2">
          {{ valueOf('copyright') }}
        </div>
        <div class="socials" v-if="socialNetworks.length > 0">
          <a
            v-for="item in socialNetworks"
            :key="item.id"
            :title="item.name"
            :href="item.link"
            target="_blank"
          >
            <img :src="`/images/socials/${item.icon_class}.svg`" alt="icon" />
          </a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import Const from '@/common/Const'
import { mapState } from 'vuex'
import rf from '@/request/RequestFactory'
export default {
  name: 'PageFooter',
  data() {
    return {
      APP_NAME: process.env.VUE_APP_SHORT_NAME || 'NVX',
      navLinks: [
        {
          key: 'column1',
          title: 'common.trading_markets',
          children: [
            {
              name: 'common.spot_exchange',
              href:
                '/spot-exchange/basic' +
                `?coin=${Const.DEFAULT_COIN}` +
                `&currency=${Const.DEFAULT_CURRENCY}`,
            },
          ],
        },
        {
          key: 'column2',
          title: 'menu_bottom.guidelines',
          children: [
            {
              name: 'menu_bottom.withdrawals_guide',
              href: '/withdraw-guide',
            },
            {
              name: 'menu_bottom.help_center',
              href: '/help-center',
            },
            {
              name: 'menu_bottom.api_document',
              href: 'redirectApiDocument',
            },
            {
              name: 'coin_information_btn_link',
              href: '/coin-information',
            },
          ],
        },
        {
          key: 'column3',
          title: 'menu_bottom.company',
          children: [
            {
              name: 'menu_bottom.terms_of_service',
              href: '/terms',
            },
            {
              name: 'menu.privacy',
              href: '/privacy-policy',
            },
            {
              name: 'menu.information_security',
              href: '/information-security-policy',
            },
            {
              name: 'license.license_footer',
              href: '/licenses',
            },
            {
              name: 'about.about_us',
              href: '/about-us',
            },
            {
              name: 'menu_bottom.contact_us',
              href: 'openPopupContactUs',
            },
          ],
        },
      ],
      siteSetting: [],
      socialNetworks: [],
    }
  },
  computed: {
    goToAndroidApp() {
      return this.siteSetting.find((item) => item.key === 'android_app_url')
        ?.value
    },
    goToAppStore() {
      return this.siteSetting.find((item) => item.key === 'ios_app_url')?.value
    },
  },
  created() {
    rf.getRequest('MasterdataRequest')
      .getAll(true)
      .then((res) => {
        ;(this.siteSetting = res.settings),
          (this.socialNetworks = res.social_networks.filter(
            (item) => item.is_active === 1
          ))
      })
  },
  methods: {
    valueOf(key) {
      return this.siteSetting.find((item) => item.key === key)?.value || ''
    },
    openPopupContactUs() {
      window.FreshworksWidget('open')
    },
    redirectApiDocument() {
      window.location.href = process.env.VUE_APP_API_URL + '/api/documentation'
    },
    isPwa() {
      return ['fullscreen', 'standalone', 'minimal-ui'].some(
        (displayMode) =>
          window.matchMedia('(display-mode: ' + displayMode + ')').matches
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.text1 {
  color: $text-main1;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  margin: 0;
  white-space: nowrap;

  @include tablet-d {
    font-size: 16px;
    line-height: 24px;
  }
}

.text2 {
  color: rgba($color: $text-main1, $alpha: 0.87);
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 22px;
  margin-bottom: 0;
  margin-top: 16px;

  @include tablet-d {
    font-size: 12px;
    line-height: 16px;
  }
}

footer {
  background: $bg-main1;
  padding: 60px 24px 24px;

  @include tablet-d {
    width: 100vw;
    overflow-x: hidden;
  }

  .wrapper {
    max-width: 1120px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 316px auto;
    gap: 80px;
    padding-bottom: 40px;

    .info {
      .logo {
        display: inline-block;

        img {
          width: 124px;
        }
      }

      .description-fake {
        margin-top: 24px;
      }

      .social {
        margin-top: 24px;
        display: flex;
        flex-flow: wrap;
        gap: 20px;

        a {
          color: $color-white;
        }
      }

      .description {
        font-family: $helvetica;
        margin-top: 40px;
        font-size: 12px;
        line-height: 22px;
        color: #494949;

        @include tablet {
          display: none;
        }

        @include tablet-d {
          display: none;
        }
      }
    }

    .nav-links {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 24px;

      .nav-items {
        margin-bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: 12px;

        li a {
          img {
            width: 162px;
            height: 48px;
          }
        }

        span {
          cursor: pointer;
        }

        .mt-auto {
          margin-top: auto;
        }
      }

      @include tablet-d {
        margin-top: 45px;
        grid-template-columns: 1fr;
        gap: 25px;

        img {
          width: 135px;
        }
      }
    }
  }

  .copyright {
    border-top: 1px solid rgba($color: #fff, $alpha: 0.1);

    .copyright-container {
      max-width: 1120px;
      margin: 0 auto;
      padding-top: 22px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 16px;

      .socials {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        gap: 16px;

        a {
          img {
            width: 30px;
            height: 30px;
          }
        }
      }

      .text2 {
        margin-top: 0;
      }
    }
  }

  @include tablet-d {
    padding: 40px 24px 16px;

    .wrapper {
      padding-bottom: 25px;
      grid-template-columns: auto;
      gap: 25px;

      .info {
        border-bottom: 1px solid rgba($color: #fff, $alpha: 0.1);
        padding-bottom: 25px;

        .logo img {
          width: 76px;
        }
      }

      .nav-links {
        margin-top: 0;
        grid-template-columns: auto auto;

        .nav-items.column2,
        .nav-items.column4 {
          text-align: right;
        }
      }
    }

    .copyright {
      .copyright-container {
        padding-top: 16px;
        flex-direction: column;
      }
    }
  }
}

.iso-logo {
  margin-top: 20px;
  width: 120px;
  @include tablet-d {
    width: 80px;
  }
}
</style>
