<template>
    <div class="policy-page">
      <div class="title">{{ $t("menu.information_security") }}</div>
      <div class="content-wrap">
        <div class="content-general">
          <p v-html="$t('policy.information_security.company_commitment')"></p>
        </div>
        <div class="policy-content" v-for="i in 5" :key="i">
          <div class="title-header">
            <p>{{ i }}.</p>
          </div>
          <div class="content-ac">
            <div class="policy__sub-content" v-if="i === 3">
              <p v-html="$t('policy.information_security.content_' + i)"></p>
              <div class="sub-points">
                <p>a. {{ $t('policy.information_security.content_3a') }}</p>
                <p>b. {{ $t('policy.information_security.content_3b') }}</p>
              </div>
            </div>
            <p v-else>
              {{ $t("policy.information_security.content_" + i) }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        APP_URL: process.env.VUE_APP_URL,
        APP_HOST: process.env.VUE_APP_URL,
      };
    },
    mounted() {
      document.title = this.$t("menu.information_security") + ` – ${this.tabTitle}`;
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .policy-page {
    max-width: 1280px;
    margin: 0 auto;
    background-color: #f2f2f2;
    padding: 24px 0;
    font-family: $helvetica;
  
    .title {
      font-size: 24px;
      font-weight: 700;
      line-height: 40px;
      color: $text-main;
      margin-bottom: 4px;
    }
  
    .content-wrap {
      .content-general {
        margin-bottom: 24px;
  
        p {
          font-size: 16px;
          font-weight: 300;
          line-height: 24px;
          color: $text-secondary;
          margin: unset;
        }
      }
  
      .policy-content {
        display: flex; // Flex container untuk menyelaraskan elemen
        align-items: flex-start; // Nomor sejajar dengan konten
        gap: 8px; // Jarak antara nomor dan konten
        margin-bottom: 24px;
  
        .title-header {
          flex-shrink: 0; // Ukuran tetap untuk nomor
          width: 40px; // Lebar tetap untuk penomoran
          text-align: center; // Pusatkan nomor
          font-size: 16px;
          font-weight: 700;
          line-height: 24px;
          margin-bottom: 0;
          color: $text-main;
        }
  
        .content-ac {
          flex: 1; // Isi ruang yang tersisa
          p,
          div {
            font-size: 16px;
            line-height: 24px;
            color: $text-secondary;
            font-weight: 300;
            margin-bottom: 24px;
          }
  
          div {
            margin-bottom: 0px;
          }
  
          .policy__sub-content {
            margin-bottom: 24px;
  
            p {
              margin: unset;
            }
  
            .sub-points {
              margin-left: 20px;
              margin-top: 10px;
  
              p {
                margin-bottom: 5px;
              }
            }
          }
        }
      }
    }
  }
  
  @media (min-width: #{$desktop-width}) {
    .policy-page {
      max-width: 1280px + 16px * 2;
      padding: 24px 16px;
    }
  }
  
  @include tablet-d() {
    .policy-page {
      padding: 24px 16px;
    }
  }
  
  @include mobile() {
    .policy-page {
      padding: 21px 24px;
  
      .title {
        font-size: 18px;
        margin-bottom: 5px;
      }
  
      .content-wrap {
        .content-general {
          margin-bottom: 5px;
  
          p {
            font-size: 14px;
          }
        }
  
        .policy-content {
          flex-direction: column; // Nomor di atas konten untuk layar kecil
          gap: 4px;
  
          .title-header {
            width: auto; // Lebar dinamis
            text-align: left;
          }
  
          .content-ac {
            div,
            p {
              margin-bottom: 0px;
              font-size: 14px;
            }
  
            div {
              margin-bottom: 0px;
            }
          }
        }
      }
    }
  }
  </style>
  